import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IConsumer, ITriggerPerUser } from '../../data/categories/category.interface'
import { IApiConsumerArray, IApiConsumerEntity } from '../../data/actions.interface'
import { getConsumer, getTriggersForConsumer } from '../../data/categories/category.actions'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { formatDate } from '../../utils/format'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import {  sortTableOnDate, sortTableOnNestedValue, sortTableOnStringDoubleNestedValue, sortTableOnStringNestedValue } from '../../utils/sort'

interface IUserDetail extends IApiConsumer {
    id: string
    back?: string
    deleteRoute: string
    consumer: IApiConsumerEntity<IConsumer>
    triggers: IApiConsumerArray<ITriggerPerUser>
}

const triggerColumns: Array<ITableColumn<ITriggerPerUser>> = [
    {
        key: 'variableName',
        render: row => row.variable.name,
        title: 'Variable',
        sort: sortTableOnStringNestedValue('variable', 'name'),
    },
    {
        key: 'targetAudience',
        render: row => row.variable.category.name || '-',
        title: 'Target audience',
        sort: sortTableOnStringDoubleNestedValue('variable', 'category','name'),
    },
    {
        key: 'variableWeight',
        render: row => row.variable.weight,
        title: 'Weight',
        sort: sortTableOnNestedValue('variable', 'weight'),
    },
    {
        key: 'triggeredAt',
        render: row => formatDate(row.triggeredAt),
        title: 'Triggered at',
        sort: sortTableOnDate('triggeredAt'),
    },
]

class ConsumerDetail extends React.Component<IUserDetail> {
    componentDidMount() {
        this.props.trigger('view_user')
    }

    render() {
        const { back, deleteRoute, consumer, triggers } = this.props

        return (
            <React.Fragment>
                <APageHeader
                    back={back}
                    buttons={[
                        <AButton outline color={'error'} to={deleteRoute}>
                            Delete user
                        </AButton>,
                    ]}
                >
                    User details for: {consumer.response.uuid}
                </APageHeader>

                <APageRow title={'Triggers'}>
                    <OTable rowKey={'id'} data={triggers.response} columns={triggerColumns} />
                </APageRow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    consumer: props => getConsumer(props.id),
    triggers: props => getTriggersForConsumer(props.id),
})(ConsumerDetail)


