import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { AHeading, APanel, AField, AButton, ARow, ACol, ALink } from '../../components/atoms'
import { apiConsumer } from '../../data/ApiProvider'
import MForm from '../../components/molecules/MForm'
import { login } from '../../data/auth/auth.actions'
import styled from '../../styledComponents'

export interface ILoginValues {
    email: string
    password: string
}

const header = {
    color: '#252525',
}

const LogoContainer = styled.div`
    text-align: center;
    width: 100%;
    height: 40px;
    minwidth: 200px;
    flex: none;
`

class Login extends React.Component<any, any> {
    handleSubmit = (values: ILoginValues) => {
        return this.props.dispatch(login(values.email, values.password)).then((res: any) => {
                this.props.trigger('login')
                return res
        })
    }
    render() {
        return (
            <APanel style={{ backgroundColor: 'transparent', border: 'none' }}>
                <LogoContainer>
                    <img
                        src={require('../../static/images/centreblock-logo-1x.png')}
                        alt="centreblock"
                    />
                </LogoContainer>
                <APanel style={{ backgroundColor: 'white', maxWidth: '424px', margin: 'auto' }}>
                    <ARow>
                        <ACol xs={12}>
                            <AHeading style={header} type="h4" align="center">
                                Login
                            </AHeading>
                        </ACol>
                    </ARow>
                    <ARow>
                        <ACol xs={12}>
                            <MForm
                                onSubmit={this.handleSubmit}
                                initialValues={{ email: '', password: '' }}
                            >
                                <AField
                                    name="email"
                                    type="email"
                                    placeholder="Username or e-mail address"
                                    label={
                                        <FormattedMessage id="label.email" defaultMessage="Email" />
                                    }
                                />
                                <AField
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    label={
                                        <FormattedMessage
                                            id="label.password"
                                            defaultMessage="Password"
                                        />
                                    }
                                />
                                <AButton
                                    style={{ height: '42px' }}
                                    type="submit"
                                    block
                                    color="primary"
                                >
                                    Login
                                </AButton>
                            </MForm>
                        </ACol>
                    </ARow>
                </APanel>
                <ARow>
                    <ACol xs={12} style={{ textAlign: 'center', marginTop: '12px' }}>
                        <ALink
                            style={{ color: '#B9B9B9', fontFamily: "'Segoe UI', sans-serif" }}
                            to="/forgot-password"
                        >
                            Forgot password?
                        </ALink>
                    </ACol>
                </ARow>
            </APanel>
        )
    }
}

export default apiConsumer({})(Login)
