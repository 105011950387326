import moment from 'moment'

import { SortDirection } from '../components/organisms/OTable'

export const sortOnDate = <T, K extends keyof T>(dateKey: K, increasing: boolean = true) => (a: T, b: T) => {
    let momentA = moment(a[dateKey])
    let momentB = moment(b[dateKey])
    if (!momentA.isValid()) {
        momentA = moment.unix(0)
    }
    if (!momentB.isValid()) {
        momentB = moment.unix(0)
    }
    return increasing ? momentA.diff(momentB) : momentB.diff(momentA)
}

export const sortOnValue = <T, K extends keyof T>(valueKey: K, increasing: boolean = true) => (a: T, b: T) => {
    const valueA = Number(a[valueKey]) || Number.MIN_SAFE_INTEGER
    const valueB = Number(b[valueKey]) || Number.MIN_SAFE_INTEGER
    return increasing ? valueA - valueB : valueB - valueA
}

export const sortTableOnValue = <T, K extends keyof T>(valueKey: K) => (
    sortDirection: SortDirection,
) => sortOnValue<T, K>(valueKey, sortDirection === 'ascending')

export const sortTableOnDate = <T, K extends keyof T>(valueKey: K) => (
    sortDirection: SortDirection,
) => sortOnDate<T, K>(valueKey, sortDirection === 'ascending')

export const sortTableOnDateDescending = <T, K extends keyof T>(valueKey: K) => (
    sortDirection: SortDirection,
) => sortOnDate<T, K>(valueKey, sortDirection === 'descending')

export const sortOnStringNestedValue = <T, K extends keyof T>(valueKey: K, subkey: string, increasing: boolean = true) => (
    a: T,
    b: T,
) => {
        const valueA = String(a[valueKey][subkey])
        const valueB = String(b[valueKey][subkey])
        return increasing ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
}

export const sortTableOnStringNestedValue = <T, K extends keyof T>(valueKey: K, subkey: string) => (
    sortDirection: SortDirection,
) => sortOnStringNestedValue<T, K>(valueKey, subkey, sortDirection === 'ascending')

export const sortOnStringValue = <T, K extends keyof T>(valueKey: K, increasing: boolean = true) => (
    a: T,
    b: T,
) => {

        const valueA = String(a[valueKey])
        const valueB = String(b[valueKey])
        return increasing ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)

}

export const sortTableOnStringValue = <T, K extends keyof T>(valueKey: K) => (
    sortDirection: SortDirection,
) => sortOnStringValue<T, K>(valueKey, sortDirection === 'ascending')

export const sortOnNestedValue = <T, K extends keyof T>(valueKey: K,  subkey: string, increasing: boolean = true) => (
    a: T,
    b: T,
) => {

    const valueA = Number(a[valueKey][subkey]) || Number.MIN_SAFE_INTEGER
    const valueB = Number(b[valueKey][subkey]) || Number.MIN_SAFE_INTEGER
    return increasing ? valueA - valueB : valueB - valueA
}

export const sortTableOnNestedValue = <T, K extends keyof T>(valueKey: K, subkey: string) => (
    sortDirection: SortDirection,
) => sortOnNestedValue<T, K>(valueKey, subkey, sortDirection === 'ascending')

export const sortOnStringDoubleNestedValue = <T, K extends keyof T>(valueKey: K,
                                                                    subkey: string,
                                                                    subkey1: string,
                                                                    increasing: boolean = true) => (a: T, b: T) => {
        const valueA = String(a[valueKey][subkey][subkey1])
        const valueB = String(b[valueKey][subkey][subkey1])
        return increasing ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
}

export const sortTableOnStringDoubleNestedValue = <T, K extends keyof T>(valueKey: K, subkey: string, subkey1: string) => (
    sortDirection: SortDirection,
) => sortOnStringDoubleNestedValue<T, K>(valueKey, subkey, subkey1, sortDirection === 'ascending')
