import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import VariableDetail from '../../containers/category/VariableDetail'
import CategoryDetail from '../../containers/category/CategoryDetail'

class PVariableDetail extends React.Component<
    RouteComponentProps<{ categoryId?: string; variableId: string }>
> {
    render() {
        const { categoryId, variableId } = this.props.match.params

        return (
            <React.Fragment>
                {categoryId && (
                    <CategoryDetail id={categoryId} back={`/customer/category/${categoryId}`} />
                )}
                <VariableDetail
                    id={variableId}
                    editRoute={`/customer${
                        categoryId ? `/category/${categoryId}` : ''
                    }/variable/${variableId}/edit`}
                    back={categoryId ? null : '/customer/dashboard'}
                />
            </React.Fragment>
        )
    }
}

export default PVariableDetail
