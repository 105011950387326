import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IWithTrigger, withTrigger } from '../../data/ApiProvider'

const except = ['/login', '/register', '/confirm', '/forgot-password']

const routeTriggers = {
    '/customer/dashboard': 'dashboard',
    '/settings': 'change_password',
    '/content/about': 'about',
    '/content/careers': 'careers',
    '/content/faq': 'faq',
}

class RouteChange extends React.Component<RouteComponentProps & IWithTrigger> {
    componentDidMount() {
        this.routeChanged()
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname === this.props.location.pathname) {
            return
        }

        this.routeChanged()
    }

    routeChanged() {
        if (
            this.props.location.pathname === '/' ||
            except.some(e => this.props.location.pathname.startsWith(e))
        ) {
            return
        }

        if (routeTriggers[this.props.location.pathname]) {
            this.props.trigger(routeTriggers[this.props.location.pathname])
        }

        localStorage.setItem('last-route', this.props.location.pathname)
    }

    render() {
        return null
    }
}

export default withTrigger(withRouter(RouteChange))
