import * as React from 'react'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getAuthUser } from '../../data/auth/auth.actions'
import MDescriptiveList from '../../components/molecules/MDescriptiveList'
import { formatDate } from '../../utils/format'

interface ICustomerInfo extends IApiConsumer {
    user: IApiConsumerEntity<any>
}

const CustomerInfo: React.FC<ICustomerInfo> = ({ user }) => {
    if (user.status !== 'success') {
        return <p>Loading..</p>
    }

    // TODO: Check this log, and create the component
    console.log(user.response)
    return (
        <MDescriptiveList
            title={user.response.customer.name}
            items={[
                {
                    name: 'License',
                    value: user.response.customer.status,
                },
                {
                    name: 'Active since',
                    value: formatDate(user.response.customer.createAt),
                },
            ]}
            button="customer/reporting"
        />
    )
}

export default apiConsumer({
    user: _ => getAuthUser(),
})(CustomerInfo)
