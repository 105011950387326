import * as React from 'react'
import CombinedVariableOverview from '../../containers/category/CombinedVariableOverview'

interface IPVariableOverview {}

class PVariableOverview extends React.Component<IPVariableOverview> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <CombinedVariableOverview
                    title="Variables"
                    categoryId={null}
                    onCreate={'/customer/variable/create'}
                />
            </React.Fragment>
        )
    }
}

export default PVariableOverview
