import * as React from 'react'
import classNames from 'classnames'

import styled from '../../styledComponents'
import Link from './ALink'

type ButtonSize = 'small' | 'regular' | 'large'
export type ButtonColor =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'error'
    | 'passive-dark'
    | 'passive-light'

export interface IButtonProps {
    active?: boolean
    block?: boolean
    className?: string
    outline?: boolean
    size?: ButtonSize
    ghost?: boolean
    hidden?: boolean
    color?: ButtonColor
    onClick?: () => void
    type?: string
    disabled?: boolean
    value?: string
    name?: string
    to?: string
    children: React.ReactNode
    style?: React.CSSProperties
    scrollBtn?: boolean
}

const Button = styled.button`
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    text-align: center;
    outline: none;
    border: none;
    padding: 0 1.5rem;
    height: 2rem;
    display: block;
    line-height: 2rem;
    color: ${props => props.theme.colorTextHighlighted};
    transition: all 0.3s ease;

    &.btn-hidden {
        visibility: hidden;
    }

    &.btn-disabled {
        cursor: not-allowed;
    }
    &.btn-outline {
        background: none;
        border: 1px solid;
    }

    &.btn-primary {
        &.btn-outline {
            border-color: ${props => props.theme.colorInactivePrimary};
            color: ${props => props.theme.colorInactivePrimary};
            &:hover {
                border-color: ${props => props.theme.colorActivePrimary};
                color: ${props => props.theme.colorTextHighlighted};
            }
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorInactivePrimary};
            color: ${props => props.theme.colorTextInverted};
        }
        &:hover {
            background-color: ${props => props.theme.colorActivePrimary};
        }
    }
    &.btn-secondary {
        &.btn-outline {
            border-color: ${props => props.theme.colorInactiveSecondary};
            color: ${props => props.theme.colorInactiveSecondary};
            &:hover {
                border-color: ${props => props.theme.colorActiveSecondary};
                color: ${props => props.theme.colorTextHighlighted};
            }
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorInactiveSecondary};
        }
        &:hover {
            background-color: ${props => props.theme.colorActiveSecondary};
        }
    }
    &.btn-success {
        &.btn-outline {
            border-color: ${props => props.theme.colorInactiveSuccess};
            color: ${props => props.theme.colorInactiveSuccess};
            &:hover {
                border-color: ${props => props.theme.colorActiveSuccess};
                color: ${props => props.theme.colorTextHighlighted};
            }
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorInactiveSuccess};
        }
        &:hover {
            background-color: ${props => props.theme.colorActiveSuccess};
        }
    }
    &.btn-warning {
        &.btn-outline {
            border-color: ${props => props.theme.colorInactiveWarning};
            color: ${props => props.theme.colorInactiveWarning};
            &:hover {
                border-color: ${props => props.theme.colorActiveWarning};
                color: ${props => props.theme.colorTextHighlighted};
            }
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorInactiveWarning};
        }
        &:hover {
            background-color: ${props => props.theme.colorActiveWarning};
        }
    }
    &.btn-error {
        &.btn-outline {
            border-color: ${props => props.theme.colorInactiveError};
            color: ${props => props.theme.colorInactiveError};
            &:hover {
                border-color: ${props => props.theme.colorActiveError};
                color: ${props => props.theme.colorTextInverted};
            }
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorInactiveError};
        }
        &:hover {
            background-color: ${props => props.theme.colorActiveError};
        }
    }
    &.btn-passive-light {
        &.btn-outline {
            border-color: ${props => props.theme.colorTextStandard};
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorTextStandard};
        }
        &:hover {
            background-color: ${props => props.theme.colorTextStandard};
        }
    }
    &.btn-passive-dark {
        &.btn-outline {
            border-color: ${props => props.theme.colorTextStandard};
        }
        &:not(.btn-outline) {
            background-color: ${props => props.theme.colorTextStandard};
        }
        &:hover {
            background-color: ${props => props.theme.colorTextHeader};
        }
    }

    &.btn-block {
        width: 100%;
    }
`

const ButtonLink = Button.withComponent(Link)

class AButton extends React.Component<IButtonProps, {}> {
    onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        const { onClick, disabled, scrollBtn, to } = this.props

        if (disabled) {
            e.preventDefault()
            return
        }
        if (scrollBtn && to) {
            const element = document.getElementById(to.replace('#', ''))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }
            return
        }
        if (onClick) {
            e.preventDefault()
            onClick()
        }
    }

    render() {
        const {
            active,
            block,
            className,
            color,
            outline,
            size,
            disabled,
            ghost,
            hidden,
            to,
            scrollBtn,
            type,
            ...attributes
        } = this.props

        const classes = classNames(
            className,
            { close },
            'btn',
            outline && 'btn-outline',
            ghost && 'btn-ghost',
            size && `btn-${size}`,
            block && 'btn-block',
            color && `btn-${color}`,
            disabled && 'btn-disabled',
            hidden && 'btn-hidden',
            { active, disabled },
        )
        if (to && !scrollBtn) {
            return <ButtonLink to={to} className={classes} {...attributes} />
        }

        return (
            <Button
                onClick={this.onClick}
                type={type || 'button'}
                className={classes}
                {...attributes}
            />
        )
    }
}

export default AButton
