import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import { RouteComponentProps } from 'react-router'
import ConsumerDelete from '../../containers/consumer/ConsumerDelete'
import { RouteConstants } from '../../containers/routes/route.constants'

class PConsumerDelete extends React.Component<RouteComponentProps<{ consumerId: string }>> {
    render() {
        const { consumerId } = this.props.match.params

        return (
            <React.Fragment>
                <APageHeader back={`/customer/consumer/${consumerId}`}>
                    Confirm deletion of user: {consumerId}
                </APageHeader>

                <ConsumerDelete
                    uuid={consumerId}
                    back={`/customer/consumer/${consumerId}`}
                    onSuccess={() => this.props.history.push(RouteConstants.CUSTOMER_DEFAULT)}
                />
            </React.Fragment>
        )
    }
}

export default PConsumerDelete
