import * as React from 'react'

import { RouteComponentProps } from 'react-router'
import APageHeader from '../../components/atoms/APageHeader'
import PowerbiReport from '../../containers/category/PowerbiReport'

class PReporting extends React.Component<RouteComponentProps<{}>> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <APageHeader>Reporting</APageHeader>
                <PowerbiReport />
            </React.Fragment>
        )
    }
}

export default PReporting
