import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CategoryDetail from '../../containers/category/CategoryDetail'
import CreateVariable from '../../containers/category/CreateVariable'
import APageHeader from '../../components/atoms/APageHeader'
import { AHeading } from '../../components/atoms'

class PCreateVariable extends React.Component<RouteComponentProps<{ categoryId: string }>> {
    /**
 * <APageHeader style={{backgroundColor:"#eee"}} back={!categoryId ? '/customer/dashboard' : null}>
        Create new variable
    </APageHeader>
 */

    render() {
        const { categoryId } = this.props.match.params

        return (
            <React.Fragment>
                {categoryId && (
                    <CategoryDetail id={categoryId} back={`/customer/category/${categoryId}`} />
                )}
                <br />
                <AHeading type="h3" style={{ textAlign: 'center' }}>
                    Create new variable
                </AHeading>

                <CreateVariable
                    id={categoryId}
                    onSuccess={() => this.props.history.push('/customer/dashboard')}
                />
            </React.Fragment>
        )
    }
}

export default PCreateVariable
