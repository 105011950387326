import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { ICustomer } from '../../data/admin/customer.interface'
import { createCustomerAdmin, getCustomerDetail } from '../../data/admin/customer.actions'
import APageHeader from '../../components/atoms/APageHeader'
import APageRow from '../../components/atoms/APageRow'
import MForm from '../../components/molecules/MForm'
import { ICreateCustomerAdminDto } from '../../data/admin/customer.dto'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import styled from '../../styledComponents'
import { ARow, ACol } from '../../components/atoms'

interface ICreateCustomerAdmin extends IApiConsumer {
    id: string
    customer: IApiConsumerEntity<ICustomer>
    onSuccess: (res: any) => void
}

const CreateCustomerAdminForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }
`

class CreateCustomerAdmin extends React.Component<ICreateCustomerAdmin> {
    handleSubmit = (values: ICreateCustomerAdminDto) => {
        return this.props
            .dispatch(createCustomerAdmin(this.props.id, values))
            .then(this.props.onSuccess)
    }

    render() {
        const { id, customer } = this.props

        return (
            <ARow>
                <ACol md={3} />
                <ACol md={6}>
                    <CreateCustomerAdminForm>
                        <APageHeader back={`/admin/customers/${id}`}>
                            Create admin for: {customer.response.name}
                        </APageHeader>
                        <APageRow>
                            <MForm key={`create-admin-${id}`} onSubmit={this.handleSubmit}>
                                <AField
                                    name="email"
                                    type="email"
                                    placeholder="Email address"
                                    label="Email address"
                                />
                                <AField
                                    name="firstName"
                                    type="text"
                                    placeholder="Firstname"
                                    label={
                                        <FormattedMessage
                                            id="customer.admin.first-name"
                                            defaultMessage="First name"
                                        />
                                    }
                                />
                                <AField
                                    name="lastName"
                                    type="text"
                                    placeholder="Lastname"
                                    label={
                                        <FormattedMessage
                                            id="customer.admin.last-name"
                                            defaultMessage="Last name"
                                        />
                                    }
                                />
                                <AField
                                    name="report"
                                    type="text"
                                    mandatory= {false}
                                    placeholder="Report to View"
                                    label={
                                        <FormattedMessage
                                            id="customer.admin.report-viiew"
                                            defaultMessage="Report View"
                                        />
                                    }
                                />
                                <AButton type="submit" block color="primary">
                                    Create new admin user
                                </AButton>
                            </MForm>
                        </APageRow>
                    </CreateCustomerAdminForm>
                </ACol>
            </ARow>
        )
    }
}

export default apiConsumer({
    customer: props => getCustomerDetail(props.id),
})(CreateCustomerAdmin)
