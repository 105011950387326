import * as React from 'react'
import classNames from 'classnames'
import styled from '../../styledComponents'

export type IconTypes =
    | 'close'
    | 'down'
    | 'download'
    | 'eye'
    | 'feedback'
    | 'left'
    | 'login'
    | 'logout'
    | 'magnify'
    | 'menu'
    | 'neutral'
    | 'plus'
    | 'right'
    | 'portfolio'
    | 'search'
    | 'settings'
    | 'tablesettings'
    | 'up'
    | 'upload'
    | 'user'
    | 'dashboard'
    | 'target-audience'
    | 'variables'
    | 'user2'
    | 'reports'
    | 'developer'
    | 'trigger'

export interface IIconProps {
    style?: React.CSSProperties
    type: IconTypes
    size?: 'xs' | 'md' | 'lg' | 'xl'
    className?: string
    onClick?: (event: React.MouseEvent<any>) => void
}

const Icon = styled.i`
    &.xs {
        font-size: 1rem;
        line-height: 1rem;
    }

    font-size: 1.5rem;

    &.lg {
        font-size: 2rem;
        line-height: 2rem;
    }

    &.xl {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    &.green {
        color: ${props => props.theme.colorActiveSuccess};
    }

    &.red {
        color: ${props => props.theme.colorActiveError};
    }

    &.neutral {
        color: ${props => props.theme.colorActivePrimary};
    }
`

const AIcon: React.FunctionComponent<IIconProps> = ({
    style,
    type,
    className,
    size = 'md',
    ...rest
}) => (
    <Icon
        className={classNames('isx-icon', className, `icon-icon_${type}`, size)}
        style={style}
        {...rest}
    />
)

export default AIcon
