import * as React from 'react'
import classNames from 'classnames'

import styled from '../../styledComponents'
import ATableCell from '../atoms/ATableCell'
import AColumnHeader from '../atoms/AColumnHeader'
import { ITableColumn, SortDirection } from '../organisms/OTable'

interface ITableHeaderProps<T extends any> {
    columns: Array<ITableColumn<T>>
    onSort: (key: string) => void
    sortKey: string | null
    sortDirection: SortDirection
}

const TRow = styled.tr`
    color: #b9b9b9;
    padding: 16px 22px;
`

class MTableHeader<T extends any> extends React.Component<ITableHeaderProps<T>, any> {
    render() {
        const { columns, onSort, sortKey, sortDirection, ...rest } = this.props

        const classes = classNames(['header-row'])

        const totalColumns = columns.reduce((sum, col) => sum + (col.weight || 1), 0)

        const getTableCell = (col: ITableColumn<T>) => (
            <ATableCell
                width={`${(100 / totalColumns) * (col.weight || 1)}%`}
                key={col.key}
                isHeader
            >
                <AColumnHeader
                    sortable={!!col.sort}
                    sortDirection={col.key === sortKey ? sortDirection : null}
                    toggleSort={() => onSort(col.key)}
                >
                    {col.title}
                </AColumnHeader>
            </ATableCell>
        )

        return (
            <React.Fragment>
                <TRow className={classes} {...rest}>
                    {columns.map(getTableCell)}
                </TRow>
            </React.Fragment>
        )
    }
}

export default MTableHeader
