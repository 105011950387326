import * as React from 'react'

import styled from '../../styledComponents'
import AButton from '../atoms/AButton'
import AHeading from '../atoms/AHeading'
import { ARow, ACol } from '../atoms'

export interface IDTItemProps {
    name: string | React.ReactNode | number
    value: string | React.ReactNode | number
}

export interface IDTListProps {
    items: Array<IDTItemProps>
    title: string
    button?: string
}

const rowStyle = {
    margin: '20px 0 10px',
    borderBottom: 'solid 1px #EEE',
    padding: '0 20px 15px',
    fontSize: '16px',
}

const headingStyle = {
    padding: '20px 35px',
    borderBottom: 'solid 1px #EEE',
}

const buttonStyle = {
    width: 'calc(100% - 70px)',
    margin: '25px auto',
}

const DList = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    background-color: #ffffff;
    height: auto;
    border-radius: 5px;
    padding-bottom: 1px;
    border: solid 1px #f4f4f4;
`

const MDescriptiveList = (props: IDTListProps) => (
    <DList>
        <AHeading style={headingStyle} type="h6">
            {props.title}
        </AHeading>
        {props.items.map((item, index) => (
            <React.Fragment key={index}>
                <ARow style={rowStyle}>
                    <ACol md={6} style={{ color: '#B9B9B9', fontWeight: '100' }}>
                        {item.name}
                    </ACol>
                    <ACol md={6} style={{ color: '#5A5A5A' }}>
                        {item.value}
                    </ACol>
                </ARow>
            </React.Fragment>
        ))}
        {props.button ? (
            <AButton to={props.button} outline color="primary" style={buttonStyle}>
                More Details
            </AButton>
        ) : (
            <br />
        )}
    </DList>
)

export default MDescriptiveList
