import * as React from 'react'

import styled from '../../styledComponents'
import ALink from '../atoms/ALink'
import AIcon from '../atoms/AIcon'
import MLoginButton from '../../containers/auth/LoginButton'
import { AContainer, ACol, ARow } from '../atoms/index'
import UserInfoTopBar from '../../containers/auth/UserInfoTopBar'

export interface IMenuItem {
    link: string
    active?: boolean
    message?: string | React.ReactNode
    isSeparator?: boolean
    disabled?: boolean
}

export interface ITopBarProps {
    menuItems: Array<IMenuItem>
}

const TopBar = styled(AContainer)`
    color: ${props => props.theme.colorTextInverted};
    background-color: #ffffff;
    height: 60px;
    position: fixed;
    z-index: 10;
    border-bottom: solid 1px #f4f4f4;
`

const UserDropdown = styled.ul`
    height: 60px;
    display: flex;
    color: #3e3e3e;
    min-width: 175px;

    padding: 15px 10px;
    border-left: solid 1px #f4f4f4;
    border-right: solid 1px #f4f4f4;

    & span {
        display: block;
    }

    & .user {
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
    }

    & .customer {
        font-size: 14px;
        padding-left: 10px;
    }

    & .icon {
        background: #f4f4f4;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        text-align: center;
        padding-top: 5px;
    }
`

const MenuList = styled.ul`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 0 2rem;
    list-style: none;
    display: inline;
`

const MenuListItem = styled.li`
    max-width: 9rem;
    min-width: 8rem;
    text-align: center;
    vertical-align: middle;
`

const LogoContainer = styled(ACol)`
    text-align: center;
    width: 300px;
    min-width: 200px;
    flex: none;

    @media (max-width: 1600px) {
        width: 250px;
    }

    @media (max-width: 1300px) {
        width: 210px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`

const OTopBar = ({ menuItems }: ITopBarProps) => (
    <TopBar fluid>
        <ARow className="justify-content-between align-items-center">
            <LogoContainer>
                <img
                    src={require('../../static/images/centreblock-logo-1x.png')}
                    alt="centreblock"
                />
            </LogoContainer>
            <ACol md={2} style={{ minWidth: 300 }}>
                <ARow>
                    <ACol md={8}>
                        <ALink to="/settings">
                            <UserDropdown>
                                <div className="icon">
                                    <AIcon type="user2"></AIcon>
                                </div>
                                <UserInfoTopBar />
                            </UserDropdown>
                        </ALink>
                    </ACol>
                    <ACol md={4}>
                        <MLoginButton />
                    </ACol>
                </ARow>
            </ACol>
        </ARow>
    </TopBar>
)

export default OTopBar

/*
<ACol md={8}>
            <MenuList>
                {menuItems.map((item, index) => item.isSeparator ?
                    <MenuListItem key={`separator-${index}`}>
                        |
                    </MenuListItem>
                    : <MenuListItem key={`${item.link}-${index}-link`}>
                        <ALink
                            to={item.link}
                            disabled={item.disabled}
                            activeField={item.active}
                            inverted
                        >
                            {item.message}
                        </ALink>
                    </MenuListItem>,
                )}
            </MenuList>
        </ACol>
        */
