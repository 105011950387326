import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import UpdateTag from '../../containers/category/UpdateTag'

class PTagUpdate extends React.Component<RouteComponentProps<{ variableId: string, id: string, name: string}>> {
    render() {
        const { variableId, id, name } = this.props.match.params
        return (
            <React.Fragment>
                <UpdateTag
                    variableId={variableId}
                    tagId={+id}
                    back={`/customer/variable/${variableId}/newEditVariable/${name}`}
                    onSuccess={() => this.props.history.push(`/customer/variable/${variableId}/newEditVariable/${name}`)}
                />
            </React.Fragment>
        )
    }
}

export default PTagUpdate
