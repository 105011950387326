import * as React from 'react'
import ReactMarkdown from 'react-markdown'

import { formatDateTime } from '../../utils/format'
import { IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import AButton from '../../components/atoms/AButton'
import { IPost } from '../../data/admin/customer.interface'
import styled from 'styled-components'
import { ARow } from '../../components/atoms'

interface IPostComponent extends IWithAuthStatus {
    post: IPost
    onDelete?: (id: number) => any
}

const MessageWell = styled.div`
    padding: 1rem;
    margin: 1rem;
    border-bottom: solid 1px #f4f4f4;
    font-family: 'montserrat', sans-serif;
    font-weight: 300;
`

const PostDate = styled.span`
    margin-top: 10px;
    color: #3e3e3e;
    font-weight: 200;
    font-size: 12px;
`

class PostComponent extends React.Component<IPostComponent> {
    handleDelete = () => {
        if (this.props.onDelete) {
            return this.props.onDelete(this.props.post.id)
        }
    }

    render() {
        const { post, auth } = this.props

        return (
            <MessageWell key={post.id}>
                <ARow>
                    <ReactMarkdown source={post.text} />
                </ARow>
                <ARow>
                    <PostDate>{formatDateTime(post.createdAt)}</PostDate>
                    {auth.isAdmin && (
                        <AButton outline color="warning" onClick={this.handleDelete}>
                            Delete post
                        </AButton>
                    )}
                </ARow>
            </MessageWell>
        )
    }
}

export default withAuthStatus(PostComponent)
