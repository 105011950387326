import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import {
    getAudiences,
    getTagsForVariable,
    getTriggers,
    getVariable,
    updateWeights
} from '../../data/categories/category.actions'
import { ITag, ITriggerPerVariable, IVariable } from '../../data/categories/category.interface'
import { IApiConsumerArray, IApiConsumerEntity } from '../../data/actions.interface'
import APageHeader from '../../components/atoms/APageHeader'
import APageRow from '../../components/atoms/APageRow'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { formatDateTime } from '../../utils/format'
import { sortTableOnStringValue } from '../../utils/sort'
import AField from '../../components/atoms/AField'
import MForm from '../../components/molecules/MForm'
import { ARow, AText } from '../../components/atoms'
import { IWeightsDto } from '../../data/categories/category.dto'
import styled from '../../styledComponents'
import AButton from '../../components/atoms/AButton'
import ACol from '../../components/atoms/ACol'

interface IVariableDetailNew extends IApiConsumer {
    id: string
    name: string
    onSuccess: () => void
    variable: IApiConsumerEntity<IVariable>
    triggers: IApiConsumerArray<ITriggerPerVariable>
    tags: IApiConsumerArray<ITag>
    audiences: IApiConsumerArray<IVariable>
    editRoute?: string
    back?: string | null
}
const tagColumns: Array<ITableColumn<ITag>> = [
    {
        key: 'key',
        title: 'Key',
        sort: sortTableOnStringValue('key'),
    },
    {
        key: 'value',
        title: 'Value',
        sort: sortTableOnStringValue('value'),
    },
    {
        key: 'edit',
        render: row => (
            <AButton color="primary" outline to={`/customer/tags/update/${row.variableId}/id/${row.id}/name/${row.name}`}>Edit</AButton>
        ),
    },
    {
        key: 'delete',
        render: row => (
            <AButton color="primary" outline to={`/customer/tags/delete/${row.variableId}/id/${row.id}/key/${row.key}/name/${row.name}`}>Delete</AButton>
        ),
    },
]

const triggerColumns: Array<ITableColumn<ITriggerPerVariable>> = [
    {
        key: 'userIdentifier',
        title: 'User',
    },
    {
        key: 'triggerCount',
        title: '# triggers',
    },
    {
        key: 'firstTrigger',
        title: 'First trigger',
        render: row => formatDateTime(row.firstTrigger),
    },
    {
        key: 'lastTrigger',
        title: 'Most recent trigger',
        render: row => formatDateTime(row.lastTrigger),
    },
]

const EditVariableForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }`
class VariableDetailNew extends React.Component<IVariableDetailNew> {
    state = {
        showTrigger: false,
        showTag: false,
    }
    toggleTrigger = () => {
        if (!this.state.showTrigger) {
            this.props.trigger('show_trigger')
        }
        this.setState({ showTrigger: !this.state.showTrigger })
    }

    toggleTag = () => {
        if (!this.state.showTag) {
            this.props.trigger('show_tag')
        }
        this.setState({ showTag: !this.state.showTag })
    }
    handleSubmit = (values: IWeightsDto) => {
        return this.props
            .dispatch(updateWeights(values))
            .then(res => {
                this.props.trigger('update_weights')
                return this.props.onSuccess()
            })
    }

    render() {
        const { variable, triggers, tags, audiences } = this.props
        if (variable.status !== 'success') {
            return 'loading'
        }
        if (audiences.status !== 'success') {
            return 'loading'
        }
        return (
            <React.Fragment>
                <APageHeader>
                    Edit Variable: {variable.response.name}
                </APageHeader>
                <ARow>
                <ACol md={3} />
                <ACol md={6}>
                        <EditVariableForm>
                            <MForm
                                key="update-audience"
                                initialValues={{
                                    name: variable.response.name,
                                }}
                                onSubmit={this.handleSubmit}
                            >
                                {audiences.response.map(category => (
                                    <APageRow>
                                        <AText>{category.category!.name}</AText>
                                        <AField
                                            key={category.id}
                                            name={category.category!.id}
                                            type="number"
                                            placeholder={category.weight}
                                            label={'category.name'}
                                        />

                                    </APageRow>

                                ))}
                                <AButton type="submit" block color="primary">
                                    Update Audience weights
                                </AButton>
                            </MForm>
                            </EditVariableForm>
                </ACol>
                </ARow>

                 <APageRow
                     title="Triggers"
                     buttons={[
                     <AButton
                         key="create"
                         color="primary"
                         outline
                         onClick={this.toggleTrigger}
                     >
                         {this.state.showTrigger ? 'Hide' : 'Show'}
                     </AButton>,
                 ]}
                 >
                     { this.state.showTrigger && <OTable
                         rowKey="userIdentifier"
                         data={triggers.response}
                         columns={triggerColumns}
                     />
                     }
                 </APageRow>
                <APageRow
                    title="Tags"
                    buttons={[
                        <AButton
                            key="create"
                            color="primary"
                            outline
                            to={`/customer/tags/create/${variable.response.id}/name/${variable.response.name}`}
                        >
                            New Tag
                        </AButton>,
                        <AButton
                            key="create"
                            color="primary"
                            outline
                            onClick={this.toggleTag}
                        >
                            {this.state.showTag ? 'Hide' : 'Show'}
                        </AButton>,
                    ]}
                >
                    {this.state.showTag && <OTable
                        rowKey="key"
                        data={tags.response.map(tag => ({
                            ...tag,
                            variableId: variable.response.id,
                            name: variable.response.name,
                        }))}
                        columns={tagColumns}
                    />
                    }
                </APageRow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    variable: props => getVariable(props.id),
    audiences: props => getAudiences(props.name),
    triggers: props => getTriggers(props.id),
    tags: props => getTagsForVariable(props.id),
})(VariableDetailNew)
