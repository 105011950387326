import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import APageHeader from '../../components/atoms/APageHeader'
import AButton from '../../components/atoms/AButton'
import PostOverview from '../../containers/admin/PostOverview'
import CustomerDetail from '../../containers/admin/CustomerDetail'

const baseUrl = (customerId?: string) =>
    `/admin/${customerId ? `customers/${customerId}/` : ''}posts`

class PPosts extends React.Component<RouteComponentProps<{ customerId?: string }>> {
    render() {
        const { customerId } = this.props.match.params

        return (
            <React.Fragment>
                <APageHeader
                    back={`/admin/customers/${customerId ? customerId : ''}`}
                    buttons={[
                        <AButton
                            key="create"
                            outline
                            color="primary"
                            to={`${baseUrl(customerId)}/create`}
                        >
                            Create post
                        </AButton>,
                    ]}
                >
                    {customerId ? 'Posts for customer' : 'Generic posts'}
                </APageHeader>

                <PostOverview customerId={customerId} />

                {customerId && <CustomerDetail id={customerId} />}
            </React.Fragment>
        )
    }
}

export default PPosts
