import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { createPost } from '../../data/admin/customer.actions'
import APageRow from '../../components/atoms/APageRow'
import MForm from '../../components/molecules/MForm'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'

interface ICreatePost extends IApiConsumer {
    customerId?: string
    onSuccess: () => void
}

class CreatePost extends React.Component<ICreatePost> {
    handleSubmit = (values: { text: string }) => {
        console.log(values)
        return this.props
            .dispatch(createPost({ customerId: this.props.customerId, ...values }))
            .then(this.props.onSuccess)
    }

    render() {
        return (
            <React.Fragment>
                <APageRow title="Create a new post">
                    <MForm onSubmit={this.handleSubmit} key="create-post">
                        <AField
                            key="text"
                            type="textarea"
                            name="text"
                            label="Content of the post (supports basic markdown)"
                        />
                        <AButton type="submit" color="primary">
                            Create new post
                        </AButton>
                    </MForm>
                </APageRow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({})(CreatePost)
