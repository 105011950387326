import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import { IApiConsumerArray } from '../../data/actions.interface'
import { IAdminModify } from '../../data/admin/customer.interface'
import { IModifyCustomerAdminDto } from '../../data/admin/customer.dto'
import MForm from '../../components/molecules/MForm'
import AField from '../../components/atoms/AField'
import { FormattedMessage } from 'react-intl'
import styled from '../../styledComponents'
import { ARow, ACol, AHeading, AText } from '../../components/atoms'
import { getCustomerAdmins, modifyCustomerAdmin } from '../../data/admin/customer.actions'
import APageHeader from '../../components/atoms/APageHeader'

interface ICustomerUserModify extends IApiConsumer {
    customerId: string
    id: string
    back: string
    onSuccess: () => void
    users: IApiConsumerArray<IAdminModify>
}
const ModifyCustomerAdminForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }
`
class CustomerAdminModify extends React.Component<ICustomerUserModify> {
    
    handleSubmit = (values: IModifyCustomerAdminDto) => {
        return this.props
            .dispatch(modifyCustomerAdmin(this.props.id, values))
            .then(this.props.onSuccess)
    }

    render() {
        const { back, id, users } = this.props
        const user =
            users.status === 'success' && users.response.find(u => Number(u.id) === Number(id))
        return (
            <div>
                 <APageHeader type= "h6" back={back}> Modify {user && user.email}</APageHeader>
            <ARow>
                <ACol md={3} />
                <ACol md={6}>
                    <ModifyCustomerAdminForm>
                    
                        <APageRow>
                            <MForm
                             key={`modify-admin-${id}`}
                            initialValues={{
                                email: user && user.email,
                                firstName: user && user.firstName,
                                lastName: user && user.lastName,
                                report: user && user.report,
                            }}
                             onSubmit={this.handleSubmit}>
                                <AField
                                    name="email"
                                    type="email"
                                    placeholder="Email address"
                                    label="Email address"
                                    value = {user && user.email}
                                />
                                <AField
                                    name="firstName"
                                    type="text"
                                    placeholder = {user && user.firstName}
                                />
                                <AField
                                    name="lastName"
                                    type="text"
                                    placeholder={user && user.lastName}
                                />
                                <AField
                                    name="report"
                                    type="text"
                                    mandatory= {false}
                                    placeholder={user && user.report}
                                />
                                <AButton type="submit" block color="primary">
                                    Modify
                                </AButton>
                            </MForm>
                        </APageRow>
                    </ModifyCustomerAdminForm>
                </ACol>
            </ARow>
            </div>
        )
    }
}

export default apiConsumer({
    users: props => getCustomerAdmins(props.customerId),
})(CustomerAdminModify)
