import * as React from 'react'
import { Field, ErrorMessage, FieldProps, FormikValues } from 'formik'
import classNames from 'classnames'
import styled from '../../styledComponents'
import AFormGroup from './AFormGroup'
import TextField from '@material-ui/core/TextField'

export interface IFieldProps {
    placeholder?: string
    type: string
    name: string
    noMargin?: boolean
    validate?: (value: any) => string | Promise<void> | undefined
    value?: string
    mandatory?: boolean
}

export const FormField = styled.input`
    display: block;
    width: 100%;
    height: 2.8rem;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
    outline: 0;
    color: ${props => props.theme.colorTextHighlighted};
    &.tab-form {
        height: 2.25rem;
        border: none;
        border-bottom: 1px solid ${props => props.theme.colorTextHighlighted};
        border-radius: 0;
    }
`

export const FormFieldTextArea = styled.textarea`
    display: block;
    width: 100%;
    height: 10rem;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
    outline: 0;
    color: ${props => props.theme.colorTextHighlighted};
`

const Label = styled.label`
    line-height: 2rem;
    transition: 1s;

    color: ${props => props.theme.colorTextHighlighted};

    &.no-emph {
        color: ${props => props.theme.colorTextStandard};
    }

    &.form-check-label {
        margin-bottom: 0;
        display: inline-block;
        background: color;
    }
`

export const Error = styled(ErrorMessage)`
    color: ${props => props.theme.colorActiveError};
    margin-top: 0.5rem;
`

const AField: React.FunctionComponent<IFieldProps> = ({
    name,
    type,
    noMargin,
    placeholder,
    value,
    mandatory = true
}) => {
    return (
        <Field
            type={type}
            render={({ field, form }: FieldProps<FormikValues>) => (
                <AFormGroup small noMargin={noMargin}>
                    <TextField
                        variant="outlined"
                        margin="small"
                        value={value}
                        required={mandatory}
                        fullWidth
                        id={name}
                        label={placeholder}
                        name={name}
                        type={type}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                    />
                    {form.touched && form.errors && form.errors[name] && (
                        <Error name={name} component="div" />
                    )}
                </AFormGroup>
            )}
        />
    )
}

export default AField
