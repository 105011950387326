import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import CreateCustomer from '../../containers/admin/CreateCustomer'
import { RouteComponentProps } from 'react-router'

class PCreateCustomer extends React.Component<RouteComponentProps> {
    render() {
        return (
            <React.Fragment>
                <APageHeader back={'/admin/customers'}>Create new customer</APageHeader>

                <CreateCustomer onSuccess={res => this.props.history.push('/admin/customers')} />
            </React.Fragment>
        )
    }
}

export default PCreateCustomer
