import * as React from 'react'
import styled, { css } from '../../styledComponents'

export interface IHeadingProps {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    className?: string
    style?: React.CSSProperties
    inverted?: boolean
    id?: string
    bold?: boolean
    align?: string
}

const baseStyles = css<{ inverted?: boolean; bold?: boolean; align?: string }>`
    color: ${props =>
        props.inverted ? props.theme.colorTextHighlighted : props.theme.colorTextHeader};
    margin-bottom: 1.5rem;
    font-family: 'montserrat', sans-serif;
    text-align: ${props => props.align};
    font-weight: ${props => (props.bold ? '900' : 'normal')};
`

const H1 = styled.h1`
    ${baseStyles}
    font-size: 3.5rem;
`

const H2 = styled.h2`
    ${baseStyles}
    font-size: 3rem;
`
const H3 = styled.h3`
    ${baseStyles}
    font-size: 2.5rem;
`
const H4 = styled.h4`
    ${baseStyles}
    font-size: 2rem;
`
const H5 = styled.h5`
    ${baseStyles}
    font-size: 1.5rem;
`
const H6 = styled.h6`
    ${baseStyles}
    font-size: 1.25rem;
`

const getComponentByType = (type: string) => {
    switch (type) {
        case 'h1':
            return H1
        case 'h2':
            return H2
        case 'h3':
            return H3
        case 'h4':
            return H4
        case 'h5':
            return H5
        default:
            return H6
    }
}

const AHeading: React.FunctionComponent<IHeadingProps> = props => {
    const { type, className, bold = true, align = 'left', ...rest } = props

    const Component = getComponentByType(type)

    return <Component className={className} bold={bold} align={align} {...rest} />
}

export default AHeading
