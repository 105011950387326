import * as React from 'react'
import ARow from './ARow'
import ACol from './ACol'
import APageHeader from './APageHeader'

interface IPageRow {
    title?: string
    buttons?: React.ReactNodeArray
    centered?: boolean
}

const APageRow: React.FunctionComponent<IPageRow> = ({ title, buttons, children, centered }) => (
    <React.Fragment>
        {(title || buttons) && (
            <APageHeader buttons={buttons} type="h4">
                {title}
            </APageHeader>
        )}
        <ARow>
            <ACol lg={centered ? { offset: 4, size: 4 } : 12} md={12}>
                {children}
            </ACol>
        </ARow>
    </React.Fragment>
)

export default APageRow
