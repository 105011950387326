import * as React from 'react'
import CategoryOverview from '../../containers/category/CategoryOverview'

interface IPTargetAudience {}

class PTargetAudience extends React.Component<IPTargetAudience> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <CategoryOverview />
            </React.Fragment>
        )
    }
}

export default PTargetAudience
