import * as React from 'react'
import { Link as RLink, LinkProps } from 'react-router-dom'
import classNames from 'classnames'

import styled from '../../styledComponents'

export interface ILinkProps extends LinkProps {
    disabled?: boolean
    className?: string
    activeField?: boolean
    size?: number
    weight?: 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'italic'
    inverted?: boolean
    children?: React.ReactNode
}

const Link = styled(RLink)<ILinkProps>`
    text-decoration: none;
    transition: 0.5s ease-in-out;
    color: #6e6e6e;
    font-family: 'montserrat', sans-serif;
    font-size: ${props => props.size}px;
    font-weight: ${props => props.weight};

    &:hover {
        color: #252525;
    }

    &.a-active-field {
        color: #252525;
    }

    &.a-active-field::after {
        content: '';
        display: block;
        position: absolute;
        right: -8px;
        top: 20%;
        height: 60%;
        width: 4px;
        background-color: ${props => props.theme.colorActiveSecondary};
        border-radius: 0 50px 50px 0;
    }
`

const ALink: React.FunctionComponent<ILinkProps> = ({
    className,
    disabled,
    activeField,
    to,
    size,
    weight = 400,
    ...rest
}) => {
    const classes = classNames(className, disabled && 'disabled', activeField && 'a-active-field')

    return <Link to={to} className={classes} size={size} weight={weight} {...rest} />
}

export default ALink
