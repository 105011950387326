import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerArray } from '../../data/actions.interface'
import APageRow from '../../components/atoms/APageRow'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { sortTableOnStringValue } from '../../utils/sort'
import { getTags } from '../../data/categories/category.actions'
import { ITag } from '../../data/categories/category.interface'

interface ITagsOverview extends IApiConsumer {
    tags: IApiConsumerArray<ITag>
    title?: string
}

const tagsColumns: Array<ITableColumn<ITag>> = [
    {
        key: 'key',
        title: 'Key',
        sort: sortTableOnStringValue('key'),
    },
    {
        key: 'value',
        title: 'Value',
        sort: sortTableOnStringValue('value'),
    },
]

class TagsOverview extends React.Component<ITagsOverview> {
    render() {
        const { tags, title } = this.props

        if (tags.status !== 'success') {
            return 'loading...'
        }

        return (
            <APageRow
                title={title || 'Tags'}
            >
                <OTable rowKey={'id'} data={tags.response} columns={tagsColumns} />
            </APageRow>
        )
    }
}

export default apiConsumer({
    tags: props => getTags(),
})(TagsOverview)
