import * as React from 'react'
import Report from 'react-powerbi'

import { models } from 'powerbi-client'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { IReport } from '../../data/categories/category.interface'
import { getReport } from '../../data/categories/category.actions'
import { getAdminReport } from '../../data/admin/customer.actions'

interface IPowerbiReport extends IApiConsumer {
    report: IApiConsumerEntity<IReport>
    id?: string
}

class PowerbiReport extends React.Component<IPowerbiReport> {
    render() {
        const { report } = this.props

        if (report.status !== 'success') {
            return <p>Loading report data...</p>
        }

        return (
            <Report
                id={report.response.id}
                embedUrl={report.response.embedUrl}
                accessToken={report.response.embedToken}
                tokenType={models.TokenType.Embed}
                width="100%"
                height={window.innerHeight - 250}
                filterPaneEnabled
                navContentPaneEnabled
            />
        )
    }
   }

export default apiConsumer({
    report: props => getReport(),
})(PowerbiReport)

export const AdminPowerbiReport = apiConsumer({
    report: props => getAdminReport(props.id),
})(PowerbiReport)
