import * as React from 'react'
import ARow from '../../components/atoms/ARow'
import ACol from '../../components/atoms/ACol'
import CustomerOverview from '../../containers/admin/CustomerOverview'
import APageHeader from '../../components/atoms/APageHeader'
import AButton from '../../components/atoms/AButton'

interface IPDashboard {}

const PCustomers: React.FunctionComponent<IPDashboard> = ({}) => (
    <React.Fragment>
        <APageHeader
            buttons={[
                <AButton key="post" outline color="primary" to={'/admin/posts'}>
                    Posts
                </AButton>,
                <AButton key="create" outline color="primary" to={'/admin/customers/create'}>
                    Create customer
                </AButton>,
            ]}
        >
            Centreblock customers
        </APageHeader>
        <ARow>
            <ACol md={12}>
                <CustomerOverview />
            </ACol>
        </ARow>
    </React.Fragment>
)

export default PCustomers
