import * as React from 'react'
import ARow from '../components/atoms/ARow'
import ACol from '../components/atoms/ACol'
import AField from '../components/atoms/AField'
import AHeading from '../components/atoms/AHeading'
import MForm from '../components/molecules/MForm'
import AButton from '../components/atoms/AButton'
import ALink from '../components/atoms/ALink'
import api from '../data/api'
import AText from '../components/atoms/AText'
import MCodeDisplay from '../components/molecules/MCodeDisplay'
import { IWithTrigger, withTrigger } from '../data/ApiProvider'
import styled from '../styledComponents'
import SecretKey from '../containers/auth/SecretKey'
import LatestTriggers from './LatestTriggers'

interface ITriggerTest extends IWithTrigger {}

const baseUrl = process.env.REACT_APP_GATEWAY_URL
    ? process.env.REACT_APP_GATEWAY_URL
    : `${window.location.protocol}//${window.location.host}`

const requestTokenCode = `
    // Code to get a token for a user, using fetch and Promises
    fetch('${baseUrl}/api/v1/consumer', {
        method: 'POST',
        body: JSON.stringify({
            uuid: 'yourownidentifierforthisuser',
            audiences: [
                // Add the audiences this user belongs in
                'audience-id-1',
                'audience-id-2',
            ],
            // Optional: add tags to consumer, will replace the existing tags if provided
            tags: {
                'tagKey1': 'tagValue1',
                'tagKey2': 'tagValue2',
                // etc..
            },
            // Optional: token time to live in days, if getting this token at the same time
            // you provide your own token to an authenticated user, please set this slightly
            // higher than that one. Defaults to 10 days
            tokenTimeToLive: 10
        }),
        headers: {
            'Content-Type': 'application/json',
            'x-centreblock-token': 'yourcustomersecret',
        }
    })
    .then(res => res.json())
    .then(json => {
        console.log(json)
        // json = {
        //     data: 'thetokenfortheuuidyouspecifiedintherequest',
        //     status: 'success',
        // }

        // Store the token so you can use it when firing triggers

        // N.B. Currently, the token is enabled for a limited amount of time (2 days)
        // so it is recommended to implement a way to refresh the token every so often.
    })
    .catch(err => {
        // Handle errors here,
    }
`

const triggerCode = `
    // Code to execute a trigger for a user
    fetch('${baseUrl}/api/v1/trigger/yourvariablename', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-centreblock-consumer-token': 'thetokenyoureceivedbefore',
        },
        body: JSON.stringify({
        // Optional: add tags to trigger
            tags: {
                'tagKey1': 'tagValue1',
                'tagKey2': 'tagValue2'       // etc..
            }	
        })
    })
    .catch(err => {
        // Handle errors here if necessary,
    })
`
const NewTriggerForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }
`

const ToggleButton = styled.div`
    display: block;
    width: 100%;
    background: #fff;
    padding: 15px 25px;
    margin: 15px 5px 0;
    border-radius: 6px;
    border: solid 1px #f4f4f4;

    & h6 {
        margin-bottom: 0;
    }
`

const CustomerSecret = styled.div`
    display: block;
    width: 100%;
    background: #fff;
    margin: 0 5px;
    border-radius: 6px;
    border: solid 1px #f4f4f4;

    & h6 {
        border-bottom: solid 1px #f4f4f4;
        padding: 15px 25px;
        margin-bottom: 0;
    }

    & .row {
        padding: 15px 25px;
    }

    & .desc {
        color: #b9b9b9;
        padding: 20px 0;
    }

    & .value {
        color: #5a5a5a;
    }
`

const url = (u: string) => `${baseUrl}${u}`

class TriggerTest extends React.Component<
    ITriggerTest,
    {
        token: string
        error: string | null
        lastTrigger: string
        showRequest: boolean
        showTrigger: boolean
        success: boolean
    }
> {
    state = {
        token: '',
        error: null,
        lastTrigger: '',
        showRequest: false,
        showTrigger: false,
        success: false,
    }

    handleSubmit = async ({ customerSecret, uuid, categories }: any) => {
        return api
            .call(
                url('/api/v1/consumer'),
                'POST',
                { uuid, audiences: categories.split(',') },
                null,
                {
                    'x-centreblock-token': customerSecret,
                },
            )
            .then(res => {
                this.props.trigger('get_unique_user_token')
                this.setState({
                    token: res.data,
                    error: null,
                })
            })
            .catch(err => {
                this.setState({
                    error: err.data.error || err.data || 'Something went wrong',
                })
            })
    }

    handleTrigger = async ({ trigger }: any) => {
        if (this.state.token) {
            return api
                .call(url(`/api/v1/trigger/test/${trigger}`), 'POST', {}, null, {
                    'x-centreblock-consumer-token': this.state.token,
                })
                .then(res => {
                    this.setState({
                        lastTrigger: res.data.triggeredAt,
                        error: null,
                        success: true,
                    })
                })
                .catch(err => {
                    this.setState({
                        error: err.data.error || err.data || 'Something went wrong',
                        lastTrigger: '',
                    })
                })
        }
    }

    handleCopyClipboard = () => {
        const el = document.createElement('textarea')
        el.value = document.getElementById('customerSecretKey').innerHTML
        el.setAttribute('readonly', '')
        el.style = { position: 'absolute', left: '-9999px' }
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    toggleRequest = () => {
        if (!this.state.showRequest) {
            this.props.trigger('show_example_code_for_requesting_token')
        }
        this.setState({ showRequest: !this.state.showRequest })
    }

    toggleTrigger = () => {
        if (!this.state.showTrigger) {
            this.props.trigger('show_example_code_for_trigger')
        }
        this.setState({ showTrigger: !this.state.showTrigger })
    }

    render() {
        const {} = this.props
        const { error, token, lastTrigger, success } = this.state

        return (
            <React.Fragment>
            <ARow>
                <ACol md={4}>
                    <NewTriggerForm>
                        <AHeading type="h6">Traffic test</AHeading>
                        <MForm key="get-token" onSubmit={this.handleSubmit}>
                            <AField
                                name="customerSecret"
                                type="text"
                                placeholder="Customer Secret"
                                label="Customer secret"
                            />
                            <AField name="uuid" type="text" placeholder="User" label="User" />
                            <AField
                                name="categories"
                                type="text"
                                placeholder="Target audience"
                                label="Target audiences (comma separated)"
                            />

                            <AButton type="submit" block color="primary">
                                Traffic-test
                            </AButton>
                        </MForm>
                        {token && (
                            <MForm key="trigger" onSubmit={this.handleTrigger}>
                                <AField
                                    name="trigger"
                                    type="text"
                                    placeholder="Variable name"
                                    label="Variable name"
                                />

                                <AButton type="submit" block color="primary">
                                    Trigger
                                </AButton>
                            </MForm>
                        )}
                    </NewTriggerForm>
                    {success && <span>Trigger successfully created</span>}
                    {error && <AText>Error: {error}</AText>}
                    {lastTrigger && <AText>Successful trigger: {lastTrigger}</AText>}
                </ACol>
                <ACol md={8}>
                    <CustomerSecret>
                        <AHeading type="h6">Customer Secret / API Key</AHeading>
                        <ARow>
                            <ACol md={3}>
                                <span className="desc">Customer Secret / API Key</span>
                            </ACol>
                            <ACol md={8} style={{ overflow: 'hidden' }}>
                                <SecretKey />
                            </ACol>
                            <ACol md={1}>
                                <ALink to="" onClick={this.handleCopyClipboard}>
                                    Copy
                                </ALink>
                            </ACol>
                        </ARow>
                    </CustomerSecret>
                    <ToggleButton onClick={this.toggleRequest}>
                        <AHeading type="h6">
                            {this.state.showRequest ? 'Hide' : 'Show'} example code for requesting
                            token
                        </AHeading>
                    </ToggleButton>
                    {this.state.showRequest && <MCodeDisplay code={requestTokenCode} />}
                    <ToggleButton onClick={this.toggleTrigger}>
                        <AHeading type="h6">
                            {this.state.showTrigger ? 'Hide' : 'Show'} example code for trigger
                        </AHeading>
                    </ToggleButton>
                    {this.state.showTrigger && <MCodeDisplay code={triggerCode} />}
                </ACol>
            </ARow>
                <LatestTriggers />
            </React.Fragment>
        )
    }
}

export default withTrigger(TriggerTest)
