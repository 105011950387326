import * as React from 'react'
import styled from 'styled-components'

interface IAUList {
    items: React.ReactNodeArray
}

const List = styled.ul`
    list-style: none;
`

const AUList: React.FunctionComponent<IAUList> = ({ items }) => (
    <List>
        {items.map((item, index) => (
            <li key={index}>{item}</li>
        ))}
    </List>
)

export default AUList
