export default interface IThemeInterface {
    name: string
    colorInactivePrimary: string
    colorInactiveSecondary: string
    colorInactiveSuccess: string
    colorInactiveWarning: string
    colorInactiveError: string

    colorActivePrimary: string
    colorActiveSecondary: string
    colorActiveSuccess: string
    colorActiveWarning: string
    colorActiveError: string

    colorTextHeader: string
    colorTextStandard: string
    colorTextSecondary: string
    colorTextHighlighted: string
    colorTextInverted: string

    colorBackground: string
    colorBorders: string
    colorPanelBackground: string
}

export const defaultTheme: IThemeInterface = {
    name: 'default',
    colorActivePrimary: '#37325B',
    colorInactivePrimary: '#8C7Fe7',
    colorActiveSecondary: '#4A6EA6',
    colorInactiveSecondary: '#6BA2A7',
    colorActiveSuccess: '#7C8C4B',
    colorInactiveSuccess: '#879952',
    colorActiveWarning: '#BFA87A',
    colorInactiveWarning: '#B99F49',
    colorActiveError: '#CA9B95',
    colorInactiveError: '#AC5D40',

    colorTextHeader: '#3E3E3E',
    colorTextStandard: '#3E3E3E',
    colorTextHighlighted: '#8C7FE7',
    colorTextInverted: '#FFFFFF',
    colorTextSecondary: '#5A5C5A',

    colorBackground: '#FFFFFF',
    colorBorders: '#D4D1F6',
    colorPanelBackground: '#D4D1F6',
}
