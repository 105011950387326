import * as React from 'react'
import classNames from 'classnames'

export interface IContainerProps {
    className?: string
    fluid?: boolean
    clearPaddings?: boolean
    children: React.ReactNode
}

const AContainer: React.FunctionComponent<IContainerProps> = props => {
    const { className, fluid, clearPaddings, ...attributes } = props

    const classes = classNames(
        className,
        fluid ? 'container-fluid' : 'container',
        clearPaddings ? 'no-paddings' : '',
    )

    return <div {...attributes} className={classes} />
}

export default AContainer
