import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { deleteTag } from '../../data/categories/category.actions'
import APageRow from '../../components/atoms/APageRow'
import AButtonGroup from '../../components/atoms/AButtonGroup'
import AButton from '../../components/atoms/AButton'

interface IDeleteTag extends IApiConsumer {
    variableId: number
    tagId: number
    tagKey: string
    back: string
    onSuccess: () => void
}

class DeleteTag extends React.Component<IDeleteTag> {
    handleDelete = () => {
        return this.props.dispatch(deleteTag(this.props.tagId, this.props.variableId)).then(res => {
            return this.props.onSuccess()
        })
    }

    render() {
        const { back } = this.props

        return (
            <APageRow
                title={`Are you sure you want to delete tag '${this.props.tagKey}'`}
            >
                <AButtonGroup>
                    <AButton color="primary" to={back}>
                        No, take me back to safety!
                    </AButton>
                    <AButton outline color="error" onClick={this.handleDelete}>
                        Yes I am sure! Delete this tag permanently from the system
                    </AButton>
                </AButtonGroup>
            </APageRow>
        )
    }
}

export default apiConsumer({
})(DeleteTag)
