import * as React from 'react'
import { ICustomer } from '../../data/admin/customer.interface'
import { IApiConsumerEntity } from '../../data/actions.interface'
import CustomerDetail from '../../containers/admin/CustomerDetail'
import { AHeading } from '../../components/atoms'
import PowerbiReport from '../../containers/category/PowerbiReport'

interface IPCustomerDashboard {
    customer: IApiConsumerEntity<ICustomer>
}

class PCustomerDashboard extends React.Component<IPCustomerDashboard> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <AHeading type="h4">Dashboard</AHeading>
                <CustomerDetail id="me" />
                <PowerbiReport />
            </React.Fragment>
        )
    }
}

export default PCustomerDashboard
