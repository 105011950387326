import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'

import './App.css'
import Routes from './pages/Routes'
import RouteChange from './containers/routes/RouteChange'
import PageWrapper from './pages/layout/PageWrapper'

const App = () => (
    <div className="App">
        <BrowserRouter>
            <React.Fragment>
                <RouteChange />
                <PageWrapper>
                    <Routes />
                </PageWrapper>
            </React.Fragment>
        </BrowserRouter>
    </div>
)

export default App
