import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import AText from '../../components/atoms/AText'

interface IPageNotFound {
    back: string
}

const PageNotFound: React.FunctionComponent<IPageNotFound> = ({ back }) => (
    <React.Fragment>
        <APageHeader back={back}>Something went wrong: page does not exist.</APageHeader>
        <AText>Please click the back button in the top right to go back to safety.</AText>
    </React.Fragment>
)

export default PageNotFound
