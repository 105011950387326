import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerArray } from '../../data/actions.interface'
import { getCustomers } from '../../data/admin/customer.actions'
import { ICustomer } from '../../data/admin/customer.interface'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { sortTableOnDate, sortTableOnValue } from '../../utils/sort'
import AButton from '../../components/atoms/AButton'
import { formatDate } from '../../utils/format'
import styled from '../../styledComponents'

interface ICustomerOverview extends IApiConsumer {
    customers: IApiConsumerArray<ICustomer>
}

const customerColumns: Array<ITableColumn<ICustomer>> = [
    {
        key: 'name',
        title: 'Customer name',
        weight: 2,
    },
    {
        key: 'status',
        title: 'Status',
    },
    {
        key: 'categoryCount',
        title: '# Target audiences',
        sort: sortTableOnValue('categoryCount'),
    },
    {
        key: 'userCount',
        title: '# Users',
        sort: sortTableOnValue('userCount'),
    },
    {
        key: 'variableCount',
        title: '# Variables',
        sort: sortTableOnValue('variableCount'),
    },
    {
        key: 'createdAt',
        title: 'Customer since:',
        sort: sortTableOnDate('createdAt'),
        render: row => formatDate(row.createdAt),
    },
    {
        key: 'view',
        render: row => (
            <AButton color="primary" to={`/admin/customers/${row.id}`}>
                View
            </AButton>
        ),
    },
]
const ShowHideActive = styled.div`
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    border: solid 1px #f4f4f4;

    & h6 {
        border-bottom: solid 1px #f4f4f4;
        padding: 15px 25px;
        margin-bottom: 0;
    }
    & label {
      padding: 15px 25px;
      font-size: medium;
      margin: 0 15px;
      display: block;
      text-align: right;
      color: black;
    }
    & input {
     width: 30px;
     height: 20px;
     margin: 0 15px;
   }
    & .row {
        padding: 15px 25px;
    }

    & .desc {
        color: #b9b9b9;
        padding: 20px 0;
    }

    & .value {
        color: #5a5a5a;
    }
`

class CustomerOverview extends React.Component<ICustomerOverview> {
    state = {
        active: true,
        checked: false,
        label: 'Show all users',
    }
    handleChange = () => {
        this.setState({ active: !this.state.active })
        this.setState({ checked: !this.state.checked })
        if (this.state.checked) {
            this.setState({ label: 'Show all users' })
        } else {
            this.setState({ label: 'Show active users' })
        }
    }

    render() {
        const { customers } = this.props

        return <ShowHideActive>
            <label>
                <input
                    type="checkbox"
                    checked={this.state.checked}
                    onClick={this.handleChange}
                />
                {this.state.label}
            </label>
                    <OTable
                        data={customers.response.filter(customer => { if (!this.state.checked) {
                            return customer.status === 'active'
                    } else {
                            return true
                    }})}
                        columns={customerColumns}
                        rowKey={'id'}
                    />
             </ShowHideActive>
    }
}

export default apiConsumer({
    customers: props => getCustomers(),
})(CustomerOverview)
