import * as React from 'react'
import CustomerDetail from '../../containers/admin/CustomerDetail'
import { RouteComponentProps } from 'react-router'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import CustomerUsers from '../../containers/admin/CustomerUsers'
import { IAdmin } from '../../data/admin/customer.interface'

class PCustomerDetail extends React.Component<RouteComponentProps<{ id: string }>> {
    onDelete = (row: IAdmin) =>
        `/admin/customers/${this.props.match.params.id}/users/${row.id}/delete`
    onModify = (row: IAdmin) =>
        `/admin/customers/${this.props.match.params.id}/users/${row.id}/modify`

    render() {
        const id = this.props.match.params.id

        return (
            <React.Fragment>
                <CustomerDetail
                    id={id}
                    back={'/admin/customers'}
                    buttons={[
                        <AButton
                            key="posts"
                            outline
                            to={`/admin/customers/${id}/posts`}
                            color="primary"
                        >
                            Posts
                        </AButton>,
                    ]}
                />

                <APageRow
                    title="Admin users"
                    buttons={[
                        <AButton
                            key="create"
                            outline
                            to={`/admin/customers/${id}/create-user`}
                            color="primary"
                        >
                            Create admin
                        </AButton>,
                    ]}
                >
                    <CustomerUsers onDelete={this.onDelete} onModify={this.onModify} id={id} />
                </APageRow>
            </React.Fragment>
        )
    }
}

export default PCustomerDetail
