import * as React from 'react'
import { ICreateCustomerDto } from '../../data/admin/customer.dto'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { createCustomer, getCustomers } from '../../data/admin/customer.actions'
import MForm from '../../components/molecules/MForm'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import APageRow from '../../components/atoms/APageRow'

interface ICreateCustomer extends IApiConsumer {
    onSuccess: (res: any) => void
}

class CreateCustomer extends React.Component<ICreateCustomer> {
    handleSubmit = (values: ICreateCustomerDto) =>
        this.props.dispatch(createCustomer(values.name)).then(this.props.onSuccess)

    render() {
        const {} = this.props

        return (
            <APageRow centered>
                <MForm key="create-customer" onSubmit={this.handleSubmit}>
                    <AField
                        name="name"
                        type="text"
                        placeholder="Customer name"
                        label={
                            <FormattedMessage id="customer.name" defaultMessage="Customer name" />
                        }
                    />
                    <AButton type="submit" block color="primary">
                        Create new customer
                    </AButton>
                </MForm>
            </APageRow>
        )
    }
}

export default apiConsumer({
    customers: props => getCustomers(),
})(CreateCustomer)
