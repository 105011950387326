import * as React from 'react'

import styled from '../../styledComponents'

interface ITableCellProps {
    children: React.ReactNode
    isHeader?: boolean
    width?: string
    className?: string
    colSpan?: number
    borderTop?: boolean
}

const THeadCell = styled.th`
    padding: 0;
    font-weight: normal;
    letter-spacing: 0.05rem;

    .inside-panel > & {
    }
`

const TCell = styled.td`
    height: 2rem;
    padding: 16px 22px;

    .inside-panel > & {
    }
`

const ATableCell = ({
    children,
    width,
    isHeader = false,
    colSpan,
    className = '',
}: ITableCellProps): React.ReactElement<{}> =>
    isHeader ? (
        <THeadCell className={className} style={{ width }}>
            {children}
        </THeadCell>
    ) : (
        <TCell className={className} colSpan={colSpan} style={{ width }}>
            {children}
        </TCell>
    )

export default ATableCell
