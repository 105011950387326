type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
type BearerToken = string | null

const defaultHeaders = {
    'Content-Type': 'application/json',
}

const api = async (
    url: string,
    method: RequestMethod,
    body: any,
    token: BearerToken,
    customHeaders: Record<string, string> = {},
) => {
    // Set default headers
    const headers: HeadersInit = {
        ...defaultHeaders,
        ...customHeaders,
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`
    }

    // Initialize request
    const req: RequestInit = {
        method,
        headers,
    }

    // If method is GET, add query params to URL, else add body to request
    if (method === 'GET' && body) {
        const params = new URLSearchParams(body)
        url = `${url}?${params.toString()}`
    } else if (method !== 'GET') {
        req.body = JSON.stringify(body)
    }

    // Fetch actual data

    const res = await fetch(url, req)

    const jsonResult = await res.json()
    if (res.status >= 400) {
        // TODO: CLean up!
        throw jsonResult
    }

    return jsonResult
}

export default {
    call: api,
}
