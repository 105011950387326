import * as React from 'react'

import { AButton } from '../../components/atoms'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { acceptEula } from '../../data/auth/auth.actions'
import { LandingTitleContent } from '../../pages/PLanding'
import AButtonGroup from '../../components/atoms/AButtonGroup'



export interface IEula extends IApiConsumer {
    eula: string,
    onSuccess?: () => void
}

class Eula extends React.Component<IEula> {
    eula = this.props.eula
    handleSubmit = () => {
        return this.props
            .dispatch(acceptEula())
            .then(res => {
                    this.setState({ isEulaAccepted: true })
                    window.location.reload()

                 })
    }
    handleCancel = () => {
        localStorage.removeItem('refresh-token')
        localStorage.removeItem('token')
        window.location.reload()

    }
    render() {
        return (
                <div>
                <LandingTitleContent>Please agree to the terms of service to continue</LandingTitleContent>
                <embed
    src={this.eula}
    type="application/pdf"
    height="520"
    width="100%"
></embed>
             <AButtonGroup>
                    <AButton style={{ height: '42px' }} onClick={this.handleSubmit}  block color="primary">
                        Yes
                    </AButton>
                    
                    <AButton style={{ height: '42px' }} onClick={this.handleCancel} block color="error">
                        No
                    </AButton>
            </AButtonGroup>
            </div>
        )
    }
}

export default apiConsumer({})(Eula)
