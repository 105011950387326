import * as React from 'react'
import { IUpdateVariableDto } from '../../data/categories/category.dto'
import { getVariable, updateVariable } from '../../data/categories/category.actions'
import MForm from '../../components/molecules/MForm'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import AText from '../../components/atoms/AText'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { IVariable } from '../../data/categories/category.interface'
import AHeading from '../../components/atoms/AHeading'
import ACol from '../../components/atoms/ACol'
import ARow from '../../components/atoms/ARow'
import styled from '../../styledComponents'

interface IEditVariable extends IApiConsumer {
    variable: IApiConsumerEntity<IVariable>
    onSuccess: () => void
    categoryId?: string
    id: string
    back: string
}

const EditVariableForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }
`

class EditVariable extends React.Component<IEditVariable> {
    handleSubmit = (values: IUpdateVariableDto) => {
        return this.props
            .dispatch(updateVariable(this.props.id, this.props.categoryId || null, values))
            .then(res => {
                this.props.trigger('update_variable')
                return this.props.onSuccess()
            })
    }

    render() {
        const { back, variable } = this.props

        if (variable.status !== 'success') {
            return null
        }

        return (
            <ARow>
                <ACol md={3} />
                <ACol md={6}>
                    <EditVariableForm>
                        <AHeading type="h6">Edit variable {variable.response.name}</AHeading>
                        <MForm
                            key="update-variable"
                            initialValues={{
                                name: variable.response.name,
                                weight: variable.response.weight,
                            }}
                            onSubmit={this.handleSubmit}
                        >
                            <AField
                                name="name"
                                type="text"
                                placeholder="Variable name"
                                label={
                                    <FormattedMessage
                                        id="variable.name"
                                        defaultMessage="* Variable name"
                                    />
                                }
                            />
                            <AText margined small>
                                * Variable name can only contain lowercase characters and '_'.
                            </AText>
                            <AField
                                name="weight"
                                type="number"
                                placeholder="Variable weight"
                                label={
                                    <FormattedMessage
                                        id="customer.name"
                                        defaultMessage="Variable weight"
                                    />
                                }
                            />
                            <AText emph small>
                                * Changing the weight of a variable will retroactively change the
                                value of all previous triggers. If you do not want that, please
                                create a new variable instead.
                            </AText>

                            <AButton type="submit" block color="primary">
                                Update variable
                            </AButton>
                        </MForm>
                    </EditVariableForm>
                </ACol>
            </ARow>
        )
    }
}

export default apiConsumer({
    variable: props => getVariable(props.id),
})(EditVariable)
