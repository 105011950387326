import * as React from 'react'
import { IFetchObject } from './actions.interface'

interface IDispatchComponentProps {
    fetchObjects: Array<IFetchObject>
    dispatch: (fetchObject: IFetchObject) => Promise<any>
}

/**
 * This component is used to call the dispatch function on mount and on update.
 */
class DispatchComponent extends React.Component<IDispatchComponentProps> {
    componentDidMount() {
        this.props.fetchObjects.forEach(obj =>
            this.props.dispatch(obj).catch(err => {
                // TODO: add toaster that unauthorized action is called?
                console.warn(err)
            }),
        )
    }

    componentDidUpdate() {
        this.props.fetchObjects.forEach(obj =>
            this.props.dispatch(obj).catch(err => {
                // TODO: add toaster that unauthorized action is called?
                console.warn(err)
            }),
        )
    }

    render() {
        return this.props.children
    }
}

export default DispatchComponent
