import * as React from 'react'
import AHeading from './AHeading'
import ARow from './ARow'
import ACol from './ACol'
import styled from 'styled-components'
import AButton from './AButton'
import AButtonGroup from './AButtonGroup'

interface IPageHeader {
    buttons?: React.ReactNodeArray
    style?: React.CSSProperties
    back?: string | null
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    background?: boolean
}

const ButtonHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    padding: 18px 24px;
    border-bottom: solid 1px #f7f7f7;

    > {
    }
`

const APageHeader: React.FunctionComponent<IPageHeader> = ({
    type,
    children,
    buttons = [],
    back,
}) => (
    <ARow marginTop>
        <ACol md={12}>
            <ButtonHeader>
                <AHeading type={type || 'h4'}>{children}</AHeading>
                <AButtonGroup>
                    {buttons}
                    {back && (
                        <AButton color="primary" outline to={back}>
                            Back
                        </AButton>
                    )}
                </AButtonGroup>
            </ButtonHeader>
        </ACol>
    </ARow>
)

export default APageHeader
