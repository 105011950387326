import * as React from 'react'
import { Route, Switch } from 'react-router'

import PCustomers from './PCustomers'
import PCreateCustomer from './PCreateCustomer'
import PCustomerDetail from './PCustomerDetail'
import PCreateCustomerAdmin from './PCreateCustomerAdmin'
import PageNotFound from '../layout/PageNotFound'
import { RouteConstants } from '../../containers/routes/route.constants'
import PPosts from './PPosts'
import PCreatePost from './PCreatePost'
import PDeleteCustomerAdmin from './PDeleteCustomerAdmin'
import PCustomerReportAdmin from './PCustomerReportAdmin'
import PModifyCustomerAdmin from './PModifyCustomerAdmin'

const route = (path: string) => `/admin/${path}`

const AdminRoutes = () => (
    <Switch>
        <Route path={route('posts/create')} component={PCreatePost} />
        <Route path={route('posts')} component={PPosts} />
        <Route path={route('customers/:id/create-user')} component={PCreateCustomerAdmin} />
        <Route path={route('customers/:customerId/reporting/:customerName')} component={PCustomerReportAdmin} />
        <Route
            path={route('customers/:customerId/users/:userId/delete')}
            component={PDeleteCustomerAdmin}
        />
        <Route
            path={route('customers/:customerId/users/:userId/modify')}
            component={PModifyCustomerAdmin}
        />
        <Route path={route('customers/:customerId/posts/create')} component={PCreatePost} />
        <Route path={route('customers/:customerId/posts')} component={PPosts} />
        <Route path={route('customers/create')} component={PCreateCustomer} />
        <Route path={route('customers/:id')} component={PCustomerDetail} />

        <Route path={route('customers')} component={PCustomers} />

        <Route component={() => <PageNotFound back={RouteConstants.ADMIN_DEFAULT} />} />
    </Switch>
)

export default AdminRoutes
