import * as React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/styles/hljs'

interface IMCodeDisplay {
    code: string
}

class MCodeDisplay extends React.Component<IMCodeDisplay> {
    render() {
        const { code } = this.props

        return (
            <SyntaxHighlighter language="javascript" style={dark}>
                {code}
            </SyntaxHighlighter>
        )
    }
}

export default MCodeDisplay
