import * as React from 'react'
import api from './api'
import { IApiConsumerArray, IApiConsumerEntity, IFetchObject } from './actions.interface'
import DispatchComponent from './DispatchComponent'

import io from 'socket.io-client'
import { loginWithToken } from './auth/auth.actions'
import { trigger } from './centreblock/centreblock.actions'

interface IAuthStatus {
    readonly loggedIn: boolean
    readonly logout: () => void
    readonly isAdmin: boolean
    readonly isEulaAccepted: boolean
    readonly eula: string
    readonly report?: string
}

type IData = Record<string, IApiConsumerArray<any> | IApiConsumerEntity<any>>

interface IContext {
    auth?: IAuthStatus
    data?: IData
    trigger?: (name: string) => void
    dispatch: (obj: IFetchObject) => Promise<any>
    joinRoom: (key: string) => void
    leaveRoom: (key: string) => void
}

interface IApiProviderProps {
    apiUrl: string
    socketUrl: string
}

interface IApiProviderState {
    token: string | null
    centreblock: string
    data: IData
    isAdmin: boolean,
    isEulaAccepted: boolean,
    eula: string,
    report?: string
}

const CentreblockContext = React.createContext<IContext>({
    dispatch: (obj: IFetchObject) =>
        Promise.reject(
            'ISXContext is not defined, it appears as though ISXProvider is not added as a toplevel component',
        ),
    joinRoom: (key: string) => console.error('context not initialized'),
    leaveRoom: (key: string) => console.error('context not initialized'),
})

const initialState: IApiProviderState = {
    token: null,
    centreblock: '',
    data: {},
    isAdmin: false,
    isEulaAccepted: false,
    eula: '',
    report: ''
}

const TOKEN_REFRESH_TIMEOUT = 60 * 8 * 1000

export default class ApiProvider extends React.Component<IApiProviderProps, IApiProviderState> {
    readonly state: IApiProviderState = initialState

    socket?: SocketIOClient.Socket

    refreshTimeout: number | null = null

    url = (url: string) => `${this.props.apiUrl}/${url}`

    /**
     * Dispatches a fetchObject. This function first calls the API according to the specifications in the fetch object, and on a successful
     * response, stores the data, and subscribes to the websocket for future updates.
     *
     * In the case of a POST request, it throws (rejects) on a failed submission, so that the response can be used to provide the user with
     * proper error messages.
     *
     * @param fetchObject
     */
    dispatch = (fetchObject: IFetchObject): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (fetchObject.method !== 'GET' || !this.state.data[fetchObject.key]) {
                this.saveData(fetchObject.key, {
                    status: 'fetching',
                    response:
                        (this.state.data[fetchObject.key] &&
                            this.state.data[fetchObject.key].response) ||
                        fetchObject.default,
                })
                this.api(fetchObject)
                    .then(res => {
                        if (fetchObject.key === 'auth') {
                            this.setAuthToken(
                                res.data.token,
                                res.data.refreshToken,
                                res.data.isAdmin,
                                res.data.centreblockToken,
                                res.data.isEulaAccepted,
                                res.data.eula,
                                res.data.report
                            )
                            if (!this.refreshTimeout) {
                                this.refreshTimeout = setTimeout(
                                    () => this.refreshToken(res.data.refreshToken),
                                    TOKEN_REFRESH_TIMEOUT,
                                )
                            }
                        }

                        if (fetchObject.socket) {
                            this.subscribeToSocket(fetchObject)
                        }

                        this.saveData(fetchObject.key, {
                            status: 'success',
                            response: fetchObject.transform
                                ? fetchObject.transform(
                                      res.data,
                                      this.state.data[fetchObject.key].response,
                                  )
                                : res.data,
                        })

                        resolve(res)
                    })
                    .catch(err => {
                        this.saveData(fetchObject.key, {
                            status: err.status,
                            error: err.data,
                        })

                        reject(err)
                    })
            }
        })
    }

    /**
     * Merges the data state on the provided key
     *
     * TODO: Figure out if this can be replaced by the transform function, and just completely replace the state for a certain key every
     * TODO: time something changes.
     *
     * @param key
     * @param data
     */
    saveData = (key: string, data: any) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [key]: {
                    ...this.state.data[key],
                    ...data,
                },
            },
        })
    }

    /**
     * Subscribes to the corresponding room of an API call using WebSockets.
     * @param fetchObject
     */
    subscribeToSocket = (fetchObject: IFetchObject) => {
        if (this.socket) {
            this.joinRoom(fetchObject.key, fetchObject.privateSocket)
            if (!this.socket.hasListeners(fetchObject.key)) {
                console.log(`Subscribed to socket for ${fetchObject.key}`)
                this.socket.on(fetchObject.key, (socketData: any) => {
                    const newData = fetchObject.socket
                        ? fetchObject.socket(socketData, this.state.data[fetchObject.key].response)
                        : socketData
                    this.saveData(fetchObject.key, {
                        status: 'success',
                        response: newData,
                    })
                })
            } else {
                console.log(`Already subscribed to socket ${fetchObject.key}`)
            }
        }
    }

    /**
     * Calls the API as specified in the fetchObject
     *
     * @param fetchObject
     */
    api = (fetchObject: IFetchObject) => {
       if (this.state.token === null || this.state.token === undefined) {
            const token = localStorage.getItem('token')
            this.setState({ token })
        }
       return api.call(
            this.url(fetchObject.url),
            fetchObject.method,
            fetchObject.body,
            this.state.token,
            fetchObject.customHeaders,
        )
    }

    setAuthToken = (
        token: string,
        refreshToken: string,
        isAdmin: boolean,
        centreblockToken: string,
        isEulaAccepted: boolean,
        eula: string,
        report?: string
    ) => {
        localStorage.setItem('refresh-token', refreshToken)
        localStorage.setItem('token', token)
        this.setState({ token, isAdmin, isEulaAccepted,eula,report,  centreblock: centreblockToken || ''  })
    }

    logout = () => {
        localStorage.removeItem('refresh-token')
        localStorage.removeItem('last-route')
        localStorage.removeItem('token')
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout)
            this.refreshTimeout = null
        }
        this.setState(initialState)
    }

    refreshToken = (refreshToken: string) => {
        this.refreshTimeout = null
        this.dispatch(loginWithToken(refreshToken))
            .then((res: any) => {
                this.setAuthToken(
                    res.data.token,
                    res.data.refreshToken,
                    res.data.isAdmin,
                    res.data.centreblockToken,
                    res.data.isEulaAccepted,
                    res.data.eula,
                    res.data.report
                )
            })
            .catch(err => {
                localStorage.removeItem('refresh-token')
                localStorage.removeItem('token')
            })
    }

    componentDidMount() {
        const storageToken = localStorage.getItem('refresh-token')
        if (storageToken) {
            this.refreshToken(storageToken)
        }

        if (!this.socket) {
            this.socket = io.connect(this.props.socketUrl)
        }
    }

    joinRoom = (key: string, isPrivate: boolean = false) => {
        if (this.socket) {
            if (isPrivate) {
                this.socket.emit('join-room-private', {
                    token: this.state.token,
                    roomKey: key,
                })
            } else {
                this.socket.emit('join-room', key)
            }
        }
    }

    leaveRoom = (key: string) => {
        if (this.socket) {
            this.socket.emit('leave-room', key)
        }
    }

    trigger = (name: string) => {
        if (this.state.centreblock) {
            trigger(this.props.apiUrl, this.state.centreblock, name)
        }
    }

    render() {
        return (
            <CentreblockContext.Provider
                value={{
                    dispatch: this.dispatch,
                    joinRoom: this.joinRoom,
                    leaveRoom: this.leaveRoom,
                    trigger: this.trigger,
                    auth: {
                        loggedIn: !!this.state.token,
                        logout: this.logout,
                        isAdmin: !!this.state.token && this.state.isAdmin,
                        isEulaAccepted: this.state.isEulaAccepted,
                        eula: this.state.eula,
                        report: this.state.report
                    },
                    data: this.state.data,
                }}
            >
                {this.props.children}
            </CentreblockContext.Provider>
        )
    }
}

export interface IApiConsumer {
    dispatch: (fetchObject: IFetchObject) => Promise<any>
    trigger: (name: string) => void
}

type WithoutInjected<P, I> = Pick<P, Exclude<keyof P, keyof I>>

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// MAGIC DO NOT TOUCH!!!!!!!!!!!!!!!!
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const apiConsumer = <R extends Record<string, (props: any) => IFetchObject>>(data: R) => <
    P extends IApiConsumer
>(
    WrappedComponent: React.ComponentType<P>,
): React.FunctionComponent<WithoutInjected<P, R & IApiConsumer>> => (
    props: WithoutInjected<P, R & IApiConsumer>,
) => (
    <CentreblockContext.Consumer>
        {(context: IContext) => {
            if (Object.keys(data).length === 0) {
                return <WrappedComponent dispatch={context.dispatch} {...(props as P)} />
            }

            const dataProps = Object.keys(data).reduce((agg, key) => {
                // Get fetch key as determined in fetch object
                const fetchObject = data[key](props)

                return {
                    ...agg,
                    [key]: (context.data && context.data[fetchObject.key]) || {
                        status: 'default',
                        response: fetchObject.default,
                    },
                }
            }, {})
            // TODO: Consider adding generic failure handling here (something like a 'something went wrong, please refresh the page') or decide
            // TODO: whether it should be handled by every wrapped component depending on the severity of the failure.
            return (
                <DispatchComponent
                    dispatch={context.dispatch}
                    fetchObjects={Object.values(data).map(func => func(props))}
                >
                    <WrappedComponent
                        dispatch={context.dispatch}
                        {...dataProps}
                        trigger={context.trigger}
                        {...(props as P)}
                    />
                </DispatchComponent>
            )
        }}
    </CentreblockContext.Consumer>
)

export interface IWithAuthStatus {
    auth: {
        loggedIn: boolean
        logout: () => void
        isAdmin: boolean
        isEulaAccepted: boolean,
        eula: string,
        report?: string
    }
}

export const withAuthStatus = <P extends IWithAuthStatus>(
    WrappedComponent: React.ComponentType<P>,
): React.FunctionComponent<WithoutInjected<P, IWithAuthStatus>> => (
    props: WithoutInjected<P, IWithAuthStatus>,
) => (
    <CentreblockContext.Consumer>
        {(context: IContext) => <WrappedComponent {...(props as P)} auth={context.auth} />}
    </CentreblockContext.Consumer>
)

export interface IWithTrigger {
    trigger: (name: string) => void
}

export const withTrigger = <P extends IWithTrigger>(
    WrappedComponent: React.ComponentType<P>,
): React.FunctionComponent<WithoutInjected<P, IWithTrigger>> => (
    props: WithoutInjected<P, IWithTrigger>,
) => (
    <CentreblockContext.Consumer>
        {(context: IContext) => <WrappedComponent {...(props as P)} trigger={context.trigger} />}
    </CentreblockContext.Consumer>
)
