import * as React from 'react'
import classNames from 'classnames'

import styled from '../../styledComponents'
import AIcon from './AIcon'
import { SortDirection } from '../organisms/OTable'

export interface ISortableHeader {
    toggleSort?: () => void
    sortable?: boolean
    sortDirection?: SortDirection
}

const ColumnHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    line-height: 1.5rem;

    th:first-child > & {
    }

    th:last-child > & {
        margin-right: 0;
    }

    &.sortable:hover {
        color: ${props => props.theme.colorTextHighlighted};
        cursor: pointer;
    }
`
const ColumnText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    th:last-child > .is-draggable > & {
        // Align the edit button on a draggable table to the right
        text-align: right;
        margin-right: -1rem;
    }
`

class AColumnHeader extends React.Component<ISortableHeader> {
    onClick = () => {
        const { sortable, toggleSort } = this.props
        if (sortable && toggleSort) {
            toggleSort()
        }
    }

    render() {
        const { children, sortable = false, sortDirection } = this.props

        return (
            <ColumnHeader className={classNames([sortable && 'sortable'])} onClick={this.onClick}>
                <ColumnText>{children}</ColumnText>
                {sortDirection &&
                    (sortDirection === 'ascending' ? <AIcon type="up" /> : <AIcon type="down" />)}
            </ColumnHeader>
        )
    }
}

export default AColumnHeader
