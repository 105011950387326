import { IFetchObject } from '../actions.interface'
import { ICreateCategoryDto, ICreateVariableDto, IUpdateVariableDto, IWeightsDto } from './category.dto'
import { ICategory, IVariable } from './category.interface'
import { combineCategories } from './category.utils'

export const getCategories = (): IFetchObject => ({
    key: `categories`,
    url: 'category',
    method: 'GET',
    default: [],
})

export const getCategory = (id: string): IFetchObject => ({
    key: `category-${id}`,
    url: `category/${id}`,
    method: 'GET',
    default: {},
})

export const createCategory = (createCategoryDto: ICreateCategoryDto): IFetchObject => ({
    key: `categories`,
    url: 'category',
    method: 'POST',
    default: [],
    body: createCategoryDto,
    transform: (res, previousState) => [...previousState, res],
})

export const deleteCategory = (id: string): IFetchObject => ({
    key: `categories`,
    url: `category/${id}`,
    method: 'DELETE',
    default: [],
    transform: (res, previousState) =>
        previousState.filter((c: ICategory) => Number(c.id) !== Number(id)),
})

export const deleteTag = (id: number, variableId: number): IFetchObject => ({
    key: `tags-delete`,
    url: `tags/${id}/variableId/${variableId}`,
    method: 'DELETE',
    default: [],
})

export const updateTag = (id: number, key: string, value: string): IFetchObject => ({
    key: `tags-update`,
    url: `tags/id/${id}/key/${key}/value/${value}`,
    method: 'PUT',
    default: [],
})

export const createTag = (variableId: string | number, key: string, value: string): IFetchObject => ({
    key: `tags-update`,
    url: `tags/variableId/${variableId}/key/${key}/value/${value}`,
    method: 'POST',
    default: [],
})

export const getVariables = (categoryId: string | number | null): IFetchObject => ({
    key: `variables-${categoryId || 'generic'}`,
    url: `variable?categoryId=${categoryId}`,
    method: 'GET',
    default: [],
    transform: res =>
        combineCategories(res.map((v: IVariable) => ({ ...v, value: v.triggerCount * v.weight }))),
})

export const getTags = (): IFetchObject => ({
    key: `tags`,
    url: `tags`,
    method: 'GET',
    default: [],
})
export const getTagsForVariable = (variableId: string | number): IFetchObject => ({
    key: `tags-${variableId}`,
    url: `tags/${variableId}`,
    method: 'GET',
    default: [],
})
export const getVariable = (id: string | number): IFetchObject => ({
    key: `variable-${id}`,
    url: `variable/${id}`,
    method: 'GET',
    default: {},
})

export const createVariable = (createVariableDto: ICreateVariableDto): IFetchObject => ({
    key: `variables-generic`,
    url: `variable`,
    method: 'POST',
    default: [],
    body: createVariableDto,
    transform: (res, previousState) => [...previousState, ...combineCategories(res)],
})

export const updateVariable = (
    variableId: string,
    categoryId: string | null,
    updateVariableDto: IUpdateVariableDto,
): IFetchObject => ({
    key: `variables-${categoryId || 'generic'}`,
    url: `variable/${variableId}`,
    method: 'PUT',
    default: [],
    body: updateVariableDto,
    transform: (res, previousState) =>
        previousState.map((v: any) => (v.id === variableId ? { ...v, ...updateVariableDto } : v)),
})

export const updateWeights = (
    updateWeightsDto: IWeightsDto,
): IFetchObject => ({
    key: `update-weights'`,
    url: `variable/weights/update`,
    method: 'PUT',
    default: [],
    body: updateWeightsDto,
})
export const getTriggers = (variableId: string | number): IFetchObject => ({
    key: `triggers-${variableId}`,
    url: `trigger?variableId=${variableId}`,
    method: 'GET',
    default: [],
})

export const getAudiences = (name: string | number): IFetchObject => ({
    key: `audience-${name}`,
    url: `variable/${name}/audiences`,
    method: 'GET',
    default: [],
})
export const getTriggersForConsumer = (uuid: string): IFetchObject => ({
    key: `triggers-consumer-${uuid}`,
    url: `trigger?uuid=${uuid}`,
    method: 'GET',
    default: [],
})

export const getConsumers = (): IFetchObject => ({
    key: `consumers`,
    url: `consumer`,
    method: 'GET',
    default: [],
    transform: res =>
        res.map((r: any) => ({
            ...r,
            uuid: r.uuid,
        })),
})

export const getConsumer = (id: string): IFetchObject => ({
    key: `consumer-${id}`,
    url: `consumer/${id}`,
    method: 'GET',
    default: {},
})

export const deleteConsumer = (uuid: string): IFetchObject => ({
    key: `consumers`,
    url: `consumer/${uuid}`,
    method: 'DELETE',
    default: [],
    transform: (res, previousState) => previousState.filter((c: any) => c.uuid !== uuid),
})

export const getPosts = (): IFetchObject => ({
    key: 'posts',
    url: 'post',
    method: 'GET',
    default: {
        generic: [],
        specific: [],
    },
})

export const getReport = (): IFetchObject => ({
    key: 'report',
    url: 'customer/report',
    method: 'GET',
    default: {},
})

export const getLatestTriggers = (): IFetchObject => ({
    key: `latestTriggers`,
    url: 'trigger/hour',
    method: 'GET',
    default: [],
})

export const getSavedSearch = (): IFetchObject => ({
    key: `search`,
    url: `search`,
    method: 'GET',
    default: [],
})