import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getVariables } from '../../data/categories/category.actions'
import { ICombinedVariable } from '../../data/categories/category.interface'
import { IApiConsumerArray } from '../../data/actions.interface'
import APageRow from '../../components/atoms/APageRow'
import { AButton } from '../../components/atoms/index'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import AUList from '../../components/atoms/AUList'
import { sortTableOnStringValue, sortTableOnValue } from '../../utils/sort'

interface ICombinedVariableOverview extends IApiConsumer {
    categoryId: string | number | null
    variables: IApiConsumerArray<ICombinedVariable>
    onCreate: string
    title?: string
}

const combinedVariableColumns: Array<ITableColumn<ICombinedVariable>> = [
    {
        key: 'name',
        title: 'Name',
        sort: sortTableOnStringValue('name'),
    },
    {
        key: 'audiences',
        title: 'Target audience',
        render: row => <AUList items={row.categories.map(category => category.category)} />,
    },
    {
        key: 'weight',
        title: 'Weight',
        render: row => <AUList items={row.categories.map(category => category.weight)} />,
        sort: sortTableOnValue('sumWeight'),
    },
    {
        key: 'triggerCount',
        title: 'Traffic',
        render: row => <AUList items={row.categories.map(category => category.triggerCount)} />,
        sort: sortTableOnValue('sumTriggerCount'),
    },
    {
        key: 'value',
        title: 'Impact',
        render: row => <AUList items={row.categories.map(category => category.value)} />,
        sort: sortTableOnValue('sumValue'),
    },
    {
        key: 'edit',
        render: row => (
            <AButton color="primary" outline to={`variable/${row.id}/newEditVariable/${row.name}`}>Edit</AButton>
        ),
    },
]

class CombinedVariableOverview extends React.Component<ICombinedVariableOverview> {
    render() {
        const { variables, onCreate, title } = this.props

        if (variables.status !== 'success') {
            return 'loading...'
        }

        return (
            <APageRow
                title={title || 'Variables for target audience'}
                buttons={[
                    <AButton key={'create'} outline color="primary" to={onCreate}>
                        {' '}
                        Create new variable{' '}
                    </AButton>,
                ]}
            >
                <OTable rowKey={'id'} data={variables.response} columns={combinedVariableColumns} />
            </APageRow>
        )
    }
}

export default apiConsumer({
    variables: props => getVariables(props.categoryId),
})(CombinedVariableOverview)
