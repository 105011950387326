import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { AHeading, APanel, AField, AButton, ARow, ACol } from '../../components/atoms'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import MForm from '../../components/molecules/MForm'
import { reset } from '../../data/auth/auth.actions'

export interface IRegisterValues {
    password: string
}

export interface IConfirm extends IApiConsumer {
    token: string
    onSuccess?: () => void
}

class Reset extends React.Component<IConfirm> {
    handleSubmit = ({ password }: IRegisterValues) => {
        return this.props
            .dispatch(reset({ token: this.props.token, password }))
            .then(this.props.onSuccess)
    }
    render() {
        return (
            <APanel style={{ backgroundColor: 'white', maxWidth: '424px', margin: 'auto' }}>
                <ARow>
                    <ACol xs={12}>
                        <AHeading style={{ color: '#252525' }} type="h4" align="center">
                            Set a new password
                        </AHeading>
                    </ACol>
                </ARow>
                <ARow>
                    <ACol xs={12}>
                        <MForm
                            onSubmit={this.handleSubmit}
                            initialValues={{ password: '', repeatPassword: '' }}
                        >
                            <ARow>
                                <ACol xs={6}>
                                    <AField
                                        name="password"
                                        type="password"
                                        placeholder="New password"
                                        label={
                                            <FormattedMessage
                                                id="label.password"
                                                defaultMessage="Password"
                                            />
                                        }
                                    />
                                </ACol>
                                <ACol xs={6}>
                                    <AField
                                        name="repeatPassword"
                                        type="password"
                                        placeholder="Repeat password"
                                        label={
                                            <FormattedMessage
                                                id="label.repeatPassword"
                                                defaultMessage="Repeat Password"
                                            />
                                        }
                                    />
                                </ACol>
                            </ARow>
                            <AButton style={{ height: '42px' }} type="submit" block color="primary">
                                Set your new password
                            </AButton>
                        </MForm>
                    </ACol>
                </ARow>
            </APanel>
        )
    }
}

export default apiConsumer({})(Reset)
