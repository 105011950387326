import { IFetchObject } from '../actions.interface'
import { IChangePasswordDTO, ICreateUserDTO } from './auth.dto'

export const loginWithToken = (token: string): IFetchObject => ({
    key: 'auth',
    url: 'auth/token/refresh',
    method: 'POST',
    default: {},
    body: {
        refreshToken: token,
    },
})

export const login = (email: string, password: string): IFetchObject => ({
    key: 'auth',
    url: 'auth/login',
    method: 'POST',
    default: {},
    body: {
        email,
        password,
    },
})

export const confirm = (values: ICreateUserDTO): IFetchObject => ({
    key: 'auth',
    url: 'auth/confirm',
    method: 'POST',
    default: {},
    body: {
        ...values,
    },
})

export const reset = (values: ICreateUserDTO): IFetchObject => ({
    key: 'auth',
    url: 'auth/reset',
    method: 'POST',
    default: {},
    body: {
        ...values,
    },
})

export const forgotPassword = (email: string): IFetchObject => ({
    key: 'password-reset',
    url: 'auth/forgot',
    method: 'POST',
    default: {},
    body: {
        email,
    },
})

export const changePassword = (values: IChangePasswordDTO): IFetchObject => ({
    key: `password-change`,
    url: 'auth/change-password',
    method: 'POST',
    default: {},
    body: {
        ...values,
    },
})

export const getAuthUser = (): IFetchObject => ({
    key: `me`,
    url: `users/me`,
    method: 'GET',
    default: {},
})

export const acceptEula = (): IFetchObject => ({
    key: 'accept-eula',
    url: 'users/eula',
    method: 'GET',
    default: {}
})