import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import { deleteConsumer, getConsumers } from '../../data/categories/category.actions'
import AButtonGroup from '../../components/atoms/AButtonGroup'

interface IConsumerDelete extends IApiConsumer {
    uuid: string
    back: string
    onSuccess: () => void
}

class ConsumerDelete extends React.Component<IConsumerDelete> {
    handleDelete = () => {
        this.props.dispatch(deleteConsumer(this.props.uuid)).then(res => {
            this.props.trigger('delete_user')
            return this.props.onSuccess()
        })
    }

    render() {
        const { back } = this.props

        return (
            <APageRow
                title={
                    'Are you sure you want to delete this user and all accompanying triggers from the system?'
                }
            >
                <AButtonGroup>
                    <AButton color="primary" to={back}>
                        No, take me back to safety!
                    </AButton>
                    <AButton outline color="error" onClick={this.handleDelete}>
                        Yes I am sure! Delete this user permanently from the system
                    </AButton>
                </AButtonGroup>
            </APageRow>
        )
    }
}

export default apiConsumer({
    consumers: props => getConsumers(),
})(ConsumerDelete)
