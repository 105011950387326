import * as React from 'react'
import classNames from 'classnames'

import styled from '../../styledComponents'

interface IHtml {
    __html: string
}

export interface ITextProps {
    className?: string
    emph?: boolean
    align?: 'left' | 'center' | 'right'
    margined?: boolean
    small?: boolean
    light?: boolean
    inverted?: boolean
    dangerouslySetInnerHTML?: IHtml
    color?: 'danger' | 'success' | 'warning' | 'default'
}

const Text = styled('p')<{
    emph?: boolean
    inverted?: boolean
    align: 'left' | 'center' | 'right'
}>`
    color: ${props =>
        props.emph
            ? props.theme.colorTextHighlighted
            : props.inverted
            ? props.theme.colorTextInverted
            : props.theme.colorTextStandard};
    text-align: ${props => props.align};

    &.margined {
        margin-bottom: 1rem;
    }
    &.small {
        font-size: 0.875rem;
    }
    &.text-danger {
        color: ${props => props.theme.colorActiveError};
    }
    &.text-success {
        color: ${props => props.theme.colorActiveSuccess};
    }
    ul,
    ol {
        padding-left: 1rem;
        margin: 1rem 0;
    }
`

const AText: React.FunctionComponent<ITextProps> = props => {
    const { className, margined, small, align = 'left', color = 'default', ...attributes } = props

    const classes = classNames(
        className,
        margined && 'margined',
        small && 'small',
        color && `text-${color}`,
    )

    return <Text {...attributes} align={align} className={classes} />
}

export default AText
