import * as React from 'react'

import styled from '../../styledComponents'
import ATableCell from '../atoms/ATableCell'
import { ITableColumn } from '../organisms/OTable'

interface ITableRowProps<T extends any> {
    columns: Array<ITableColumn<T>>
    row: T
}

const TRow = styled.tr`
    font-family: 'montserrat', sans-serif;
    color: #5a5a5a;
    border-bottom: solid 1px #f7f7f7;

    &:hover {
        background-color: #eeeeee;
    }
`

class MTableRow<T extends any> extends React.Component<ITableRowProps<T>, any> {
    render() {
        const { row, columns, ...rest } = this.props

        const totalColumns = columns.reduce((sum, col) => sum + (col.weight || 1), 0)

        const getTableCell = (col: ITableColumn<T>) => (
            <ATableCell width={`${(100 / totalColumns) * (col.weight || 1)}%`} key={col.key}>
                {col.render ? col.render(row) : row[col.key]}
            </ATableCell>
        )

        return (
            <React.Fragment>
                <TRow {...rest}>{columns.map(getTableCell)}</TRow>
            </React.Fragment>
        )
    }
}

export default MTableRow
