import * as React from 'react'
import AHeading from '../components/atoms/AHeading'
import ChangePassword from '../containers/auth/ChangePassword'
import { ARow, ACol } from '../components/atoms'
import CustomerInfo from '../containers/auth/CustomerInfo'
import UserInfo from '../containers/auth/UserInfo'

interface IPSettings {}

const PSettings: React.FunctionComponent<IPSettings> = ({}) => (
    <React.Fragment>
        <AHeading type="h4">Account Settings</AHeading>
        <ARow>
            <ACol md={6}>
                <UserInfo />
            </ACol>
            <ACol md={6}>
                <CustomerInfo />
            </ACol>
        </ARow>
    </React.Fragment>
)

export default PSettings
