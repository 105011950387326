import * as React from 'react'
import ConsumerOverview from '../../containers/category/ConsumerOverview'

interface IPCustomerOverview {}

class PCustomerOverview extends React.Component<IPCustomerOverview> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <ConsumerOverview />
            </React.Fragment>
        )
    }
}

export default PCustomerOverview
