import { IFetchObject } from '../actions.interface'
import { ICreateCustomerAdminDto, ICreatePostDto, IModifyCustomerAdminDto } from './customer.dto'
import { IAdmin, IPost } from './customer.interface'

export const getCustomers = (): IFetchObject => ({
    key: `admin-customers`,
    url: `customer`,
    default: [],
    method: 'GET',
})

export const getCustomerDetail = (id: number | 'me'): IFetchObject => ({
    key: `admin-customer-${id}`,
    url: `customer/${id}`,
    default: {},
    method: 'GET',
})

export const createCustomer = (name: string): IFetchObject => ({
    key: `admin-customers`,
    url: `customer`,
    method: 'POST',
    default: [],
    body: {
        name,
        status: 'active',
    },
    transform: (res, previousState) => [...previousState, res],
})

export const getCustomerAdmins = (id: number): IFetchObject => ({
    key: `admin-customer-${id}-users`,
    url: `users?customerId=${id}`,
    default: [],
    method: 'GET',
})


export const createCustomerAdmin = (id: string, values: ICreateCustomerAdminDto): IFetchObject => ({
    key: `admin-customer-${id}-users`,
    url: `users`,
    method: 'POST',
    default: [],
    body: {
        customerId: id,
        ...values,
    },
    transform: (res, previousState) => [...previousState, res],
})

export const deleteCustomerAdmin = (id: string, customerId: string): IFetchObject => ({
    key: `admin-customer-${customerId}-users`,
    url: `users/${id}`,
    method: 'DELETE',
    default: [],
    transform: (res, previousState) =>
        previousState.filter((u: IAdmin) => Number(u.id) !== Number(id)),
})

export const modifyCustomerAdmin = (id: string, values: IModifyCustomerAdminDto): IFetchObject => ({
    key: `admin-customer-${id}-users`,
    url: `users/modify`,
    method: 'POST',
    default: [],
    body: {
        id: id,
        firstName: values.firstName ,
        lastName: values.lastName ,
        report: values.report 
    },
    transform: (res, previousState) => [...previousState, res],
})
export const setCustomerStatus = (id: string, status: 'active' | 'disabled'): IFetchObject => ({
    key: `admin-customer-${id}`,
    url: `customer/${id}`,
    method: 'PUT',
    default: {},
    body: {
        status,
    },
    transform: (res, previousState) => ({
        ...previousState,
        status,
    }),
})

export const getPosts = (id?: string): IFetchObject => ({
    key: `admin-posts-${id || 'generic'}`,
    url: `post/${id || 'generic'}`,
    method: 'GET',
    default: [],
})

export const createPost = (createPostDto: ICreatePostDto): IFetchObject => ({
    key: `admin-posts-${createPostDto.customerId || 'generic'}`,
    url: `post`,
    method: 'POST',
    default: [],
    body: createPostDto,
    transform: (res, prevState) => [res, ...prevState],
})

export const deletePost = (id: number, customerId?: string): IFetchObject => ({
    key: `admin-posts-${customerId || 'generic'}`,
    url: `post/${id}`,
    method: 'DELETE',
    default: [],
    transform: (res, prevState) => prevState.filter((p: IPost) => p.id !== id),
})

export const getAdminReport = (customerId: string): IFetchObject => ({
    key: `report-${customerId}`,
    url: `customer/${customerId}/report`,
    method: 'GET',
    default: {},
})

export const setEmbedUrl = (id: string, { embedUrl }: { embedUrl: string }): IFetchObject => ({
    key: `admin-customer-${id}`,
    url: `customer/${id}/report`,
    default: {},
    method: 'POST',
    body: {
        embedUrl,
    },
})

export const deleteEmbedUrl = (id: string): IFetchObject => ({
    key: `admin-customer-${id}`,
    url: `customer/${id}/report`,
    default: {},
    method: 'DELETE',
})
