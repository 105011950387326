import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getVariables } from '../../data/categories/category.actions'
import { ICombinedVariable } from '../../data/categories/category.interface'
import { IApiConsumerArray } from '../../data/actions.interface'
import APageRow from '../../components/atoms/APageRow'
import { AButton } from '../../components/atoms/index'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { sortTableOnStringValue, sortTableOnValue } from '../../utils/sort'

interface IVariableOverview extends IApiConsumer {
    categoryId: string | number | null
    variables: IApiConsumerArray<ICombinedVariable>
    onCreate: string
    title?: string
}

const variableColumns: (
    categoryId: string | number | null,
) => Array<ITableColumn<ICombinedVariable>> = categoryId => [
    {
        key: 'name',
        title: 'Name',
        sort: sortTableOnStringValue('name'),
    },
    {
        key: 'sumWeight',
        title: 'Weight',
        sort: sortTableOnValue('sumWeight'),
    },
    {
        key: 'sumTriggerCount',
        title: 'No. of triggers',
        sort: sortTableOnValue('sumTriggerCount'),
    },
    {
        key: 'sumValue',
        title: 'Value',
        sort: sortTableOnValue('sumValue'),
    },
    {
        key: 'view',
        render: row => (
            <AButton
                outline
                color="primary"
                to={`/customer/${categoryId ? `category/${categoryId}/` : ''}variable/${row.id}`}
            >
                View
            </AButton>
        ),
    },
]

class VariableOverview extends React.Component<IVariableOverview> {
    render() {
        const { variables, onCreate, title } = this.props

        if (variables.status !== 'success') {
            return 'loading...'
        }

        return (
            <APageRow
                title={title || 'Variables for target audience'}
                buttons={[
                    <AButton key={'create'} outline color="primary" to={onCreate}>
                        {' '}
                        Create new variable{' '}
                    </AButton>,
                ]}
            >
                <OTable
                    rowKey={'id'}
                    data={variables.response}
                    columns={variableColumns(this.props.categoryId)}
                />
            </APageRow>
        )
    }
}

export default apiConsumer({
    variables: props => getVariables(props.categoryId),
})(VariableOverview)
