import * as React from 'react'
import MForm from '../../components/molecules/MForm'
import APanel from '../../components/atoms/APanel'
import ARow from '../../components/atoms/ARow'
import ACol from '../../components/atoms/ACol'
import AHeading from '../../components/atoms/AHeading'
import AField from '../../components/atoms/AField'
import ALink from '../../components/atoms/ALink'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { forgotPassword } from '../../data/auth/auth.actions'
import AText from '../../components/atoms/AText'
import styled from '../../styledComponents'

interface IForgotPassword extends IApiConsumer {}

const LogoContainer = styled.div`
    text-align: center;
    width: 100%;
    height: 40px;
    minwidth: 200px;
    flex: none;
`

const HeaderBox = styled.div`
    text-align: center;
    margin-bottom: 25px;

    & h4 {
        color: #252525;
        margin-bottom: 5px;
    }

    & span {
        color: #5a5a5a;
        text-align: center;
        font-weight: 100;
        font-family: 'montserrat', sans-serif;
    }
`

class ForgotPassword extends React.Component<IForgotPassword, { success: boolean }> {
    state = {
        success: false,
    }

    handleSubmit = ({ email }: { email: string }) => {
        return this.props.dispatch(forgotPassword(email)).then(res => {
            this.setState({ success: true })
        })
    }

    render() {
        const {} = this.props

        return (
            <APanel style={{ backgroundColor: 'transparent', border: 'none' }}>
                <LogoContainer>
                    <img
                        src={require('../../static/images/centreblock-logo-1x.png')}
                        alt="centreblock"
                    />
                </LogoContainer>
                <APanel style={{ backgroundColor: 'white', maxWidth: '424px', margin: 'auto' }}>
                    <ARow>
                        <ACol xs={12}>
                            <HeaderBox>
                                <AHeading type="h4" align="center">
                                    Recover password
                                </AHeading>
                                <span>Don't worry, happens to the best of us.</span>
                            </HeaderBox>
                        </ACol>
                    </ARow>
                    <ARow>
                        <ACol xs={12}>
                            <MForm onSubmit={this.handleSubmit} initialValues={{ email: '' }}>
                                <AField
                                    name="email"
                                    type="email"
                                    placeholder="E-mail address"
                                    label={
                                        <FormattedMessage id="label.email" defaultMessage="Email" />
                                    }
                                />
                                <AButton
                                    style={{ height: '50px' }}
                                    type="submit"
                                    block
                                    color="primary"
                                >
                                    Reset password
                                </AButton>
                            </MForm>
                            {this.state.success && (
                                <AText emph>
                                    If an account exists for this email address, we will send a
                                    reset link shortly
                                </AText>
                            )}
                        </ACol>
                    </ARow>
                </APanel>
                <ARow>
                    <ACol xs={12} style={{ textAlign: 'center', marginTop: '12px' }}>
                        <ALink
                            style={{ color: '#B9B9B9', fontFamily: "'Segoe UI', sans-serif" }}
                            to="/login"
                        >
                            Back to login page
                        </ALink>
                    </ACol>
                </ARow>
            </APanel>
        )
    }
}

export default apiConsumer({})(ForgotPassword)
