import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter, RouteComponentProps } from 'react-router'

import { IWithAuthStatus, IWithTrigger, withAuthStatus, withTrigger } from '../../data/ApiProvider'
import styled from '../../styledComponents'
import { ACol } from '../../components/atoms/index'
import OTopBar, { IMenuItem } from '../../components/organisms/OTopBar'
import OLeftMenu, { ILeftMenuItem } from '../../components/organisms/OLeftMenu'
import { IFooterLink } from '../../components/organisms/OFooter'
import Eula from '../../containers/auth/Eula'

const Background = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.colorBackground};
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;
    display: flex;
    flex-direction: column;
`

const ContentLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex: auto;
    min-height: 0;
`

const PageContent = styled.div`
    flex: auto;
    min-height: 0;
    padding: 2rem;
    overflow-x: hidden;
    margin-top: 60px;
`

const getFooterLinks = (trigger: (name: string) => void): Array<IFooterLink> => [
    {
        url: '/content/about',
        name: <FormattedMessage id="common.about" defaultMessage="About" />,
        type: 'company',
    },
    {
        url: '/content/careers',
        name: <FormattedMessage id="common.careers" defaultMessage="Careers" />,
        type: 'company',
    },
    {
        url: 'mailto:info@centreblock.org',
        name: <FormattedMessage id="common.contact" defaultMessage="Contact" />,
        type: 'company',
        onClick: () => trigger('contact'),
    },
    {
        url: 'mailto:support@centreblock.org',
        name: <FormattedMessage id="common.supportCenter" defaultMessage="Support Center" />,
        type: 'support',
        onClick: () => trigger('support_center'),
    },
    {
        url: 'mailto:support@centreblock.org',
        name: <FormattedMessage id="common.helpDesk" defaultMessage="Helpdesk" />,
        type: 'support',
        onClick: () => trigger('helpdesk'),
    },
    {
        url: '/content/faq',
        name: <FormattedMessage id="common.faq" defaultMessage="FAQ" />,
        type: 'support',
    },
]

const getActiveStatus = (url: string, currentPath: string): boolean => currentPath.includes(url)
const customerAdminMenuLinks = (): Array<ILeftMenuItem> => 
    [
        {
            link: `/customer/reporting`,
            icon: 'reports',
            message: <FormattedMessage id="menu.reporting" defaultMessage="Reports" />,
            active: true
        }
    ]

const getRoleMenuLinks = (currentPath: string, isAdmin: boolean): Array<ILeftMenuItem> =>
    isAdmin
        ? [
              {
                  link: `/admin/customers`,
                  icon: 'user2',
                  message: <FormattedMessage id="menu.customers" defaultMessage="Customers" />,
              },
          ]
        : [
              {
                  link: `/customer/dashboard`,
                  icon: 'dashboard',
                  message: <FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" />,
              },
              {
                  link: `/customer/reporting`,
                  icon: 'reports',
                  message: <FormattedMessage id="menu.reporting" defaultMessage="Reports" />,
              },
              {
                  link: `/customer/targetAudience`,
                  icon: 'target-audience',
                  message: (
                      <FormattedMessage
                          id="menu.target-audience"
                          defaultMessage="Target audience"
                      />
                  ),
              },
              {
                  link: `/customer/variable`,
                  icon: 'variables',
                  message: <FormattedMessage id="menu.variables" defaultMessage="Variables" />,
              },
              {
                  link: `/customer/tags`,
                  icon: 'reports',
                  message: <FormattedMessage id="menu.tags" defaultMessage="Tags" />,
              },
              {
                  link: `/customer/customer`,
                  icon: 'user2',
                  message: <FormattedMessage id="menu.users" defaultMessage="Users" />,
              },
              {
                  link: `/test/trigger`,
                  icon: 'developer',
                  message: <FormattedMessage id="menu.developer" defaultMessage="Developer" />,
              },
              {
                link: `/customer/search`,
                icon: 'developer',
                message: <FormattedMessage id="menu.developer" defaultMessage="List Generator" />,
            },
          ]

const getMenuLinks = (currentPath: string, isAdmin: boolean): Array<IMenuItem> =>
    [].map(item => ({
        ...item,
        active: getActiveStatus(item.link, currentPath),
    }))

const getLeftMenuLinks = (currentPath: string, isAdmin: boolean): Array<ILeftMenuItem> =>
    [...getRoleMenuLinks(currentPath, isAdmin)].map(item => ({
        ...item,
        active: getActiveStatus(item.link, currentPath),
    }))

const getCustomerAdminLeftMenuLinks = (currentPath: string): Array<ILeftMenuItem> =>
    [...customerAdminMenuLinks()].map(item => ({
        ...item,
        active: true,
    }))
class PageWrapper extends React.Component<IWithAuthStatus & IWithTrigger & RouteComponentProps> {
   
    render() {
        const { location } = this.props
        const { loggedIn, isAdmin, isEulaAccepted , eula, report} = this.props.auth
        const menuLinks = getMenuLinks(location.pathname, isAdmin)
        const leftMenuLinks = getLeftMenuLinks(location.pathname, isAdmin)
        const customerAdminMenuLinks = getCustomerAdminLeftMenuLinks(location.pathname)
        // const footerLinks = getFooterLinks(this.props.trigger)
        // if not logged in
        if (!loggedIn) {
            return (
                <Background>
                    <ContentLayout style={{ backgroundColor: '#F7F7F7' }}>
                        <PageContent>{this.props.children}</PageContent>
                    </ContentLayout>
                </Background>
            )
        } else if (loggedIn && !isEulaAccepted) {
            return (
                <Background>
                    <Eula eula={eula}></Eula>
                </Background>
            )
        } else if (loggedIn && (report !==null && report !== undefined && report !== '') ) {
            return (
                <Background>
                <OTopBar menuItems={loggedIn ? menuLinks : []} />
                <ContentLayout style={{ backgroundColor: '#F7F7F7' }}>
                    <ACol sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <OLeftMenu menuItems={customerAdminMenuLinks} />
                    </ACol>
                    <ACol sm={10}>
                        <PageContent>{this.props.children}</PageContent>
                    </ACol>
                </ContentLayout>
            </Background>
            )
        }
         else {
            // if logged in
            return (
                <Background>
                    <OTopBar menuItems={loggedIn ? menuLinks : []} />
                    <ContentLayout style={{ backgroundColor: '#F7F7F7' }}>
                        <ACol sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <OLeftMenu menuItems={leftMenuLinks} />
                        </ACol>
                        <ACol sm={10}>
                            <PageContent>{this.props.children}</PageContent>
                        </ACol>
                    </ContentLayout>
                </Background>
            )
        }
    }
}

export default withTrigger(withAuthStatus(withRouter(PageWrapper)))
