import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerArray } from '../../data/actions.interface'
import { IAdmin } from '../../data/admin/customer.interface'
import { getCustomerAdmins } from '../../data/admin/customer.actions'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import AButton from '../../components/atoms/AButton'

interface ICustomerUsers extends IApiConsumer {
    users: IApiConsumerArray<IAdmin>
    onDelete: (row: IAdmin) => string,
    onModify: (row: IAdmin) => string
    id: string
}

const customerUserColumns = (onDelete: (row: IAdmin) => string, onModify: (row: IAdmin) => string): Array<ITableColumn<IAdmin>> => [
    {
        key: 'name',
        title: 'Name',
        render: row => `${row.firstName} ${row.lastName}`,
    },
    {
        key: 'email',
        title: 'Email',
    },
    {
        key: 'modify',
        render: row => (
            <AButton color="warning" outline to={onModify(row)}>
                Modify admin
            </AButton>
        ),
    },
    {
        key: 'delete',
        render: row => (
            <AButton color="warning" outline to={onDelete(row)}>
                Delete admin
            </AButton>
        ),
    },
]

class CustomerUsers extends React.Component<ICustomerUsers> {
    render() {
        const { users, onDelete, onModify } = this.props

        return <OTable rowKey="id" data={users.response} columns={customerUserColumns(onDelete, onModify)} />
    }
}

export default apiConsumer({
    users: props => getCustomerAdmins(props.id),
})(CustomerUsers)
