import * as React from 'react'
import APageHeader from './APageHeader'

interface IAFormGroupHeader {
    buttons?: React.ReactNodeArray
}

const AFormGroupHeader: React.FunctionComponent<IAFormGroupHeader> = ({ buttons, children }) => (
    <APageHeader type={'h6'} buttons={buttons}>
        {children}
    </APageHeader>
)

export default AFormGroupHeader
