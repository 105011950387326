import * as React from 'react'

import styled from '../../styledComponents'
import ALink from '../atoms/ALink'
import AIcon from '../atoms/AIcon'
import AText from '../atoms/AText'
import { ACol, AContainer, ARow } from '../atoms/index'

export interface ILeftMenuItem {
    link: string
    icon?: string
    active?: boolean
    message?: string | React.ReactNode
    isSeparator?: boolean
    disabled?: boolean
}

export interface ILeftMenuProps {
    menuItems: Array<ILeftMenuItem>
}

const LeftMenu = styled(AContainer)`
    background-color: #fff;
    padding-top: 54px;
    height: 100vh;
    width: 300px;
    min-width: 200px;
    top: 54px;
    position: fixed;
    z-index: 9;
    border-right: solid 1px #f4f4f4;

    @media (max-width: 1600px) {
        width: 250px;
    }

    @media (max-width: 1300px) {
        width: 210px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`
const MenuList = styled.ul`
    list-style: none;
    width: 100%;
`
const MenuListItem = styled.li`
    min-width: 8rem;
    text-align: left;
    width: 100%;
    display: inline-block;
    position: relative;

    & a {
        display: block;
        padding: 20px 0 23px 25px;
    }

    & a span {
        vertical-align: 15%;
        margin-left: 15px;
    }

    &:last-of-type {
        position: absolute;
        left: 0;
        bottom: 54px;
        padding: 25px 0;
        border-top: 1px solid #eee;
    }
`

const copyright = {
    color: '#aaa',
    fontFamily: "'montserrat', sans-serif",
}

const OLeftMenu = ({ menuItems }: ILeftMenuProps) => (
    <LeftMenu>
        <ARow>
            <ACol style={{width:"100%"}}>
                <MenuList>
                    {menuItems.map((item, index) =>
                        item.isSeparator ? (
                            <MenuListItem key={`separator-${index}-link`}>|</MenuListItem>
                        ) : (
                            <MenuListItem key={`${item.link}-${index}-link`}>
                                <ALink
                                    to={item.link}
                                    disabled={item.disabled}
                                    activeField={item.active}
                                    size={18}
                                    weight={500}
                                >
                                    <AIcon
                                        type={item.active ? item.icon + '-active' : item.icon}
                                        size="md"
                                    />
                                    {item.message}
                                </ALink>
                            </MenuListItem>
                        ),
                    )}
                    <MenuListItem>
                        <AText align="center" style={copyright}>
                            <b>CentreBlock</b> &ensp; &copy; 2023
                        </AText>
                    </MenuListItem>
                </MenuList>
            </ACol>
        </ARow>
    </LeftMenu>
)

export default OLeftMenu
