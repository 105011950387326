import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CreateCustomerAdmin from '../../containers/admin/CreateCustomerAdmin'

class PCreateCustomerAdmin extends React.Component<RouteComponentProps<{ id: string }>> {
    render() {
        const id = this.props.match.params.id

        return (
            <CreateCustomerAdmin
                id={id}
                onSuccess={res => this.props.history.push(`/admin/customers/${id}`)}
            />
        )
    }
}

export default PCreateCustomerAdmin
