import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import { IRouteProps } from './route.interface'
import { RouteConstants } from './route.constants'

class PublicRoute extends React.Component<IRouteProps & IWithAuthStatus> {
    static defaultProps = { forceLogout: false }

    componentDidMount() {
        if (this.props.forceLogout && this.props.auth.loggedIn) {
            this.props.auth.logout()
        }
    }

    render() {
        const { component: Component, forceLogout, auth, ...rest } = this.props
        return (
            <Route
                {...rest}
                render={props => {
                    if (!forceLogout && auth.loggedIn) {
                        return (
                            <Redirect
                                to={
                                    localStorage.getItem('last-route') || auth.isAdmin
                                        ? RouteConstants.ADMIN_DEFAULT: (auth.report !== undefined && auth.report !== '' && auth.report !== null)? 
                                            RouteConstants.REPORTS
                                        : RouteConstants.CUSTOMER_DEFAULT
                                }
                            />
                        )
                    }

                    return <Component {...props} />
                }}
            />
        )
    }
}

export default withAuthStatus(PublicRoute)
