import * as React from 'react'
import classNames from 'classnames'

import styled from '../../styledComponents'

interface IBorderRadius {
    tl?: boolean
    tr?: boolean
    bl?: boolean
    br?: boolean
}

interface IPanelProps {
    children: React.ReactNode
    inverted?: boolean
    className?: string
    br?: IBorderRadius
    fullHeight?: boolean
    marginTop?: boolean
    style?: React.CSSProperties
}

const Panel = styled.div<{ inverted?: boolean; br: IBorderRadius }>`
    padding: 1.5rem;
    background-color: ${props =>
        props.inverted ? props.theme.colorBackground : props.theme.colorPanelBackground};
    width: 100%;

    border: solid 1px #f4f4f4;
    border-bottom-left-radius: ${props => (props.br.bl ? '0.25rem' : 0)};
    border-bottom-right-radius: ${props => (props.br.br ? '0.25rem' : 0)};
    border-top-left-radius: ${props => (props.br.tl ? '0.25rem' : 0)};
    border-top-right-radius: ${props => (props.br.tr ? '0.25rem' : 0)};

    &.full-height {
        height: 100%;
    }

    &.with-margin-top {
        margin-top: 1.5rem;
    }
`

const APanel = ({
    children,
    className,
    inverted,
    br = {
        tl: true,
        tr: true,
        bl: true,
        br: true,
    },
    fullHeight = false,
    marginTop = false,
    style,
}: IPanelProps) => (
    <Panel
        className={classNames(
            className,
            fullHeight && 'full-height',
            marginTop && 'with-margin-top',
        )}
        br={br}
        style={style}
        inverted={inverted}
    >
        {children}
    </Panel>
)

export default APanel
