import * as React from 'react'
import PostComponent from './PostComponent'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getPosts } from '../../data/categories/category.actions'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { IPost } from '../../data/admin/customer.interface'
import AHeading from '../../components/atoms/AHeading'
import APageRow from '../../components/atoms/APageRow'
import styled from 'styled-components'

interface ICustomerPosts extends IApiConsumer {
    posts: IApiConsumerEntity<{ generic: Array<IPost>; specific: Array<IPost> }>
}

const headingStyle = {
    padding: '20px 35px',
    borderBottom: 'solid 1px #EEE',
}

const Posts = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    background-color: #ffffff;
    height: auto;
    border-radius: 6px;
    padding-bottom: 1px;
    border: solid 1px #f4f4f4;
`

class CustomerPosts extends React.Component<ICustomerPosts> {
    render() {
        const { posts } = this.props

        if (posts.status !== 'success') {
            return null
        }

        return (
            <Posts>
                <AHeading style={headingStyle} type="h6">
                    Latest messages
                </AHeading>
                {posts.response.specific.map(post => (
                    <PostComponent key={post.id} post={post} />
                ))}
                {posts.response.generic.map(post => (
                    <PostComponent key={post.id} post={post} />
                ))}
            </Posts>
        )
    }
}

export default apiConsumer({
    posts: props => getPosts(),
})(CustomerPosts)
