import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CategoryDetail from '../../containers/category/CategoryDetail'
import EditVariable from '../../containers/category/EditVariable'

class PEditVariable extends React.Component<
    RouteComponentProps<{ variableId: string; categoryId?: string }>
> {
    render() {
        const { categoryId, variableId } = this.props.match.params

        return (
            <React.Fragment>
                <EditVariable
                    back={`/customer${
                        categoryId ? `/category/${categoryId}` : ''
                    }/variable/${variableId}`}
                    categoryId={categoryId}
                    id={variableId}
                    onSuccess={() =>
                        this.props.history.push(
                            `/customer${
                                categoryId ? `/category/${categoryId}` : ''
                            }/variable/${variableId}`,
                        )
                    }
                />
                {categoryId && (
                    <CategoryDetail id={categoryId} back={`/customer/category/${categoryId}`} />
                )}
            </React.Fragment>
        )
    }
}

export default PEditVariable
