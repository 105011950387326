import * as React from 'react'
import classNames from 'classnames'
import styled from '../../styledComponents'

export interface IRowProps {
    className?: string
    children: React.ReactNode
    sameHeight?: boolean
    marginTop?: boolean
}

const Row = styled.div`
    &.margin-top {
        padding-top: 1.5rem;
    }

    &.row-same-height > div {
        flex-grow: 1;
    }
`

const ARow: React.FunctionComponent<IRowProps> = props => {
    const { className, sameHeight = false, marginTop = false, ...attributes } = props

    const classes = classNames(
        'row',
        className,
        sameHeight && 'row-same-height',
        marginTop && 'margin-top',
    )

    return <Row {...attributes} className={classes} />
}

export default ARow
