import * as React from 'react'
import classNames from 'classnames'
import { SortEndHandler } from 'react-sortable-hoc'

import styled from '../../styledComponents'
import MTableRow from '../molecules/MTableRow'
import MTableHeader from '../molecules/MTableHeader'

export type SortDirection = 'ascending' | 'descending' | null

export interface ITableColumn<T extends any> {
    key: string
    title?: string | React.ReactNode
    render?: (row: T) => React.ReactNode
    weight?: number
    sort?: (sortDirection: SortDirection) => (a: T, b: T) => number
}

export interface IGenericTableProps<T extends any> {
    data: Array<T>
    renderHeader?: boolean
    emptyText?: string | React.ReactNode
    rowKey: string
    toggleSort?: (columnKey: string) => void
    sortColumnKey?: string
    sortDirection?: SortDirection
}

export interface ITableProps<T extends any> extends IGenericTableProps<T> {
    columns: Array<ITableColumn<T>>
    onSortEnd?: SortEndHandler
}

export const TableWrapper = styled.div`
    background: #fff;
    border-radius: 0 0 8px 8px;

    .empty-table-text {
        text-align: center;
        padding: 0.5rem 1rem;
        color: ${props => props.theme.colorTextHighlighted};

        &.inside-panel {
            border-bottom: 1px solid ${props => props.theme.colorTextStandard};
        }
    }
    .with-margin-top {
        margin-top: 1rem;
    }
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`

class OTable<T extends any> extends React.Component<
    ITableProps<T>,
    { sortKey: string | null; sortDirection: SortDirection }
> {
    state = {
        sortDirection: null,
        sortKey: null,
    }

    onSort = (key: string) => {
        if (key === this.state.sortKey) {
            this.setState({
                sortDirection: !this.state.sortDirection
                    ? 'ascending'
                    : this.state.sortDirection === 'ascending'
                    ? 'descending'
                    : null,
            })
        } else {
            this.setState({
                sortKey: key,
                sortDirection: 'ascending',
            })
        }
    }

    render() {
        const {
            data,
            columns,
            emptyText = 'No data to display',
            renderHeader = true,
            rowKey,
        } = this.props
        const classes = classNames(data.length === 0 && 'empty-table')

        const { sortKey, sortDirection } = this.state

        if (sortDirection) {
            const sortCol = columns.find(col => col.key === sortKey)
            if (sortCol && sortCol.sort) {
                data.sort(sortCol.sort(sortDirection))
            }
        }

        return (
            <TableWrapper>
                <Table className={classes}>
                    {renderHeader && (
                        <thead>
                            {/*row is data[0] is a hacky way to get around the fact that
                    the header does not require row data but it is obligated by a Table row*/}
                            <MTableHeader
                                columns={columns}
                                onSort={this.onSort}
                                sortKey={sortKey}
                                sortDirection={sortDirection}
                            />
                        </thead>
                    )}
                    {data.length > 0 && (
                        <tbody>
                            {data &&
                                data.map((row, index) => (
                                    <MTableRow
                                        key={row[rowKey] || index}
                                        row={row}
                                        columns={columns}
                                    />
                                ))}
                        </tbody>
                    )}
                </Table>
                {data.length === 0 && <div className={`empty-table-text`}>{emptyText}</div>}
            </TableWrapper>
        )
    }
}

export default OTable
