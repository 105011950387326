import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getCustomerDetail } from '../../data/admin/customer.actions'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { ICustomer } from '../../data/admin/customer.interface'

interface ISecretKey extends IApiConsumer {
    customer: IApiConsumerEntity<ICustomer>
}

const SecretKey: React.FC<ISecretKey> = ({ customer }) => {
    if (customer.status === 'fetching') {
        return <p>Loading..</p>
    }

    if (customer.status !== 'success') {
        return <p>Error</p>
    }

    return (
        <p id="customerSecretKey" style={{ color: '#5A5A5A' }} className="customerSecret">
            {customer.response.secret}
        </p>
    )
}

export default apiConsumer({
    customer: _ => getCustomerDetail('me'),
})(SecretKey)
