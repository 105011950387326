import * as React from 'react'
import { RouteConstants } from '../../containers/routes/route.constants'
import UserDetail from '../../containers/consumer/ConsumerDetail'
import { RouteComponentProps } from 'react-router'

class PConsumerDetail extends React.Component<RouteComponentProps<{ consumerId: string }>> {
    render() {
        const { consumerId } = this.props.match.params

        return (
            <React.Fragment>
                <UserDetail
                    id={consumerId}
                    back={RouteConstants.CUSTOMER_DEFAULT}
                    deleteRoute={`/customer/consumer/${consumerId}/delete`}
                />
            </React.Fragment>
        )
    }
}

export default PConsumerDetail
