import * as React from 'react'
import TagsOverview from '../../containers/category/TagsOverview'

interface IPTagOverview {}

class PTagsOverview extends React.Component<IPTagOverview> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <TagsOverview
                    title="Tags"
                />
            </React.Fragment>
        )
    }
}

export default PTagsOverview
