import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { AHeading, APanel, AField, AButton, ARow, ACol } from '../../components/atoms'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import MForm from '../../components/molecules/MForm'
import { confirm } from '../../data/auth/auth.actions'
import AText from '../../components/atoms/AText'

export interface IRegisterValues {
    password: string
}

export interface IConfirm extends IApiConsumer {
    token: string
    onSuccess?: () => void
}

class Confirm extends React.Component<IConfirm> {
    handleSubmit = ({ password }: IRegisterValues) => {
        return this.props
            .dispatch(confirm({ token: this.props.token, password }))
            .then(this.props.onSuccess)
    }

    render() {
        return (
            <APanel style={{ backgroundColor: 'transparent', border: 'none' }} >
                <ARow>
                    <ACol xs={12}>
                        <AHeading type="h4" inverted>
                            Complete your registration
                        </AHeading>
                        <AText>
                            Welcome to CentreBlock.
                        </AText>
                        <AText>
                            Please create a password below to get started.
                        </AText>
                        <AText>
                            Enter your desired password in both boxes.
                        </AText>
                    </ACol>
                </ARow>
                <ARow>
                    <ACol xs={12}>
                        <MForm
                            onSubmit={this.handleSubmit}
                            initialValues={{ password: '', repeatPassword: '' }}
                        >
                            <ARow>
                                <ACol xs={6}>
                                    <AField
                                        name="password"
                                        type="password"
                                        placeholder="New password"
                                        label={
                                            <FormattedMessage
                                                id="label.password"
                                                defaultMessage="Password"
                                            />
                                        }
                                    />
                                </ACol>
                                <ACol xs={6}>
                                    <AField
                                        name="repeatPassword"
                                        type="password"
                                        placeholder="Repeat password"
                                        label={
                                            <FormattedMessage
                                                id="label.repeatPassword"
                                                defaultMessage="Repeat Password"
                                            />
                                        }
                                    />
                                </ACol>
                            </ARow>
                            <AButton type="submit" block color="primary">
                                Complete your registration
                            </AButton>
                        </MForm>
                    </ACol>
                </ARow>
            </APanel>
        )
    }
}

export default apiConsumer({})(Confirm)
