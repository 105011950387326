import * as React from 'react'
import { Landing, LandingRow, LandingTitleContent } from '../PLanding'
import { RouteComponentProps } from 'react-router'
import ACol from '../../components/atoms/ACol'
import AHeading from '../../components/atoms/AHeading'

const pages: any = {
    about: {
        header: 'About CentreBlock',
        content: [
            'Welcome to CentreBlock, the world\'s best incentive & loyalty mechanism ever',
            'CentreBlock is a revolutionary different approach to loyalty Programs, Conversion Rate Optimization and even Customer Journey Mapping for online and mobile platforms, initiatives and companies.',
            'CentreBlock leverages an ingenious algorithm (patents pending) of user variables providing the opportunity to monitor and optimize conversion rates, improve the customer journey and ultimately even allocate a portion of the property and shareholding to the users/customers.',
        ],
    },
    careers: {
        header: 'CentreBlock Careers',
        content: [
            'Currently there are no open positions. If you are interested, please send a mail with your motivation and CV to careers@centreblock.org',
            'If you or your company is interested to represent CentreBlock in a specific market, please send a mail to channel@centreblock.org',
            'CentreBlock will not respond to headhunters or (executive) search organizations',
        ],
    },
}

class PContent extends React.Component<RouteComponentProps<{ page: string }>> {
    render() {
        const { page } = this.props.match.params

        return (
            <Landing fluid className="main">
                <LandingRow>
                    <ACol md={{ offset: 1, size: 6 }}>
                        <AHeading type="h1" inverted>
                            {pages[page].header}
                        </AHeading>
                        {pages[page].content.map((c: string, idx: number) => (
                            <LandingTitleContent key={idx}>{c}</LandingTitleContent>
                        ))}
                    </ACol>
                </LandingRow>
            </Landing>
        )
    }
}

export default PContent
