import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router'

import PublicRoute from '../containers/routes/PublicRoute'
import PLogin from './PLogin'
import PConfirm from './PConfirm'
import CustomerRoute from '../containers/routes/CustomerRoute'
import AdminRoutes from './admin/AdminRoutes'
import AdminRoute from '../containers/routes/AdminRoute'
import CustomerRoutes from './customer/CustomerRoutes'
import { RouteConstants } from '../containers/routes/route.constants'
import PageNotFound from './layout/PageNotFound'
import TestRoutes from './test/TestRoutes'
import PForgotPassword from './PForgotPassword'
import PReset from './PReset'
import PSettings from './PSettings'
import PContent from './layout/PContent'

const Routes = () => (
    <Switch>
        /* Public routes */
        <Redirect exact to="/login" path="/" />
        <PublicRoute path="/login" component={PLogin} />
        <PublicRoute path="/confirm/:token" forceLogout component={PConfirm} />
        <PublicRoute path="/reset/:token" forceLogout component={PReset} />
        <PublicRoute path="/forgot-password" component={PForgotPassword} />
        <Route path="/content/:page" component={PContent} />
        <Route path="/settings" component={PSettings} />
        <AdminRoute path="/admin" component={AdminRoutes} />
        <CustomerRoute path="/customer" component={CustomerRoutes} />
        <Route path="/test" component={TestRoutes} />
        <Route component={() => <PageNotFound back={RouteConstants.UNAUTH_DEFAULT} />} />
    </Switch>
)

export default Routes
