import * as React from 'react'

import styled from '../../styledComponents'
import AHeading from '../atoms/AHeading'
import AIcon from '../atoms/AIcon'
import { ARow, ACol, AText, AButton, ALink } from '../atoms'

export interface IDTItemProps {
    name: string | React.ReactNode | number
    icon: string
    to: string
    value: string | React.ReactNode | number
}

export interface IDTListProps {
    items: Array<IDTItemProps>
    title: string
    button?: {
        text: string
        to: string
    }
}

const iconStyle = {
    backgroundColor: '#F9F8FF',
    color: 'red',
    display: 'block',
    margin: '0 auto',
    padding: 8,
    height: 50,
    width: 50,
    marginLeft: 20,
    borderRadius: 10,
}

const rowStyle = {
    margin: '20px 0 10px',
    borderBottom: 'solid 1px #EEE',
}

const headingStyle = {
    padding: '20px 35px',
    borderBottom: 'solid 1px #EEE',
}

const buttonStyle = {
    width: 'calc(100% - 70px)',
    margin: '25px auto',
}

const SList = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    background-color: #ffffff;
    height: auto;
    border-radius: 6px;
    padding-bottom: 1px;
    border: solid 1px #f4f4f4;

    & .icon-col {
        @media (max-width: 1300px) {
            display: none;
        }
    }

    & .icon-col + div {
        @media (max-width: 1300px) {
            margin-left: 15px;
        }
    }
`

const MStatisticsList = (props: IDTListProps) => (
    <SList>
        <AHeading style={headingStyle} type="h6">
            {props.title}
        </AHeading>
        {props.items.map((item, index) => (
            <React.Fragment key={index}>
                <ARow style={rowStyle}>
                    <ACol
                        md={2}
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                        className="icon-col"
                    >
                        <AIcon className="green" size="md" style={iconStyle} type={item.icon} />
                    </ACol>
                    <ACol md={8}>
                        <AText style={{ color: '#6E6E6E' }}>{item.name}</AText>
                        <AHeading type="h5">{item.value}</AHeading>
                    </ACol>
                    <ACol md={2}>
                        <ALink to={item.to} style={{ position: 'relative', top: 12 }}>
                            <AIcon size="md" type="right" />
                        </ALink>
                    </ACol>
                </ARow>
            </React.Fragment>
        ))}
        {props.button && (
            <AButton to={props.button.to} outline color="primary" style={buttonStyle}>
                {props.button.text}
            </AButton>
        )}
    </SList>
)

export default MStatisticsList
