import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import DeleteTag from '../../containers/category/DeleteTag'

class PTagDelete extends React.Component<RouteComponentProps<{ variableId: string, name: string, id: string , key: string}>> {
    render() {
        const { variableId, id, name, key } = this.props.match.params

        return (
            <React.Fragment>
                <DeleteTag
                    variableId={+variableId}
                    tagId={+id}
                    tagKey={key}
                    back={`/customer/variable/${variableId}/newEditVariable/${name}`}
                    onSuccess={() => this.props.history.push(`/customer/variable/${variableId}/newEditVariable/${name}`)}
                />
            </React.Fragment>
        )
    }
}

export default PTagDelete
