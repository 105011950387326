import * as React from 'react'
import { ITrigger } from '../data/categories/category.interface'
import { IApiConsumerArray } from '../data/actions.interface'
import OTable, { ITableColumn } from '../components/organisms/OTable'
import { apiConsumer, IApiConsumer } from '../data/ApiProvider'
import { getLatestTriggers } from '../data/categories/category.actions'
import { sortTableOnDateDescending } from '../utils/sort'
import AButton from '../components/atoms/AButton'
import APageRow from '../components/atoms/APageRow'

interface ILatestTriggers extends IApiConsumer {
    triggers: IApiConsumerArray<ITrigger>
}

const triggerColumns: Array<ITableColumn<ITrigger>> = [
    {
        key: 'timestamp',
        title: 'Timestamp',
        sort: sortTableOnDateDescending('timestamp'),
    },
    {
        key: 'user',
        title: 'User',
    },
    {
        key: 'audience',
        title: 'Audience',
    },
    {
        key: 'variable',
        title: 'Variable',
    },
    {
        key: 'tags',
        title: 'Tags',
    },
]

class LatestTriggers extends React.Component<ILatestTriggers> {
    refresh = () => {
        history.go(0)
    }
    render() {
        const { triggers } = this.props
        return (
            <APageRow
                title="Triggers in last hour"
                buttons={[
                    <AButton
                        key="create"
                        color="primary"
                        outline
                        onClick={this.refresh}
                    >
                        Refresh
                    </AButton>,
                ]}
            >
                <OTable rowKey="id" data={triggers.response} columns={triggerColumns} />
            </APageRow>
        )
    }
}

export default apiConsumer({
    triggers: props => getLatestTriggers(),
})(LatestTriggers)
