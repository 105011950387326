import * as React from 'react'

import { ARow, ACol } from '../components/atoms/index'
import Register from '../containers/auth/Confirm'
import { RouteComponentProps } from 'react-router'

class PConfirm extends React.Component<RouteComponentProps<{ token: string }>> {
    onSuccess = () => {
        this.props.history.push('/customer/dashboard')
    }

    render() {
        const { token } = this.props.match.params

        const decodedToken = decodeURIComponent(token)

        return (
            <ARow>
                <ACol md={{ size: 4, offset: 4 }}>
                    <Register token={decodedToken} onSuccess={this.onSuccess} />
                </ACol>
            </ARow>
        )
    }
}

export default PConfirm
