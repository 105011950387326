import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CategoryDetail from '../../containers/category/CategoryDetail'
import DeleteCategory from '../../containers/category/DeleteCategory'
import { RouteConstants } from '../../containers/routes/route.constants'

class PCategoryDelete extends React.Component<RouteComponentProps<{ categoryId: string }>> {
    render() {
        const { categoryId } = this.props.match.params

        return (
            <React.Fragment>
                <CategoryDetail id={categoryId} back={`/customer/category/${categoryId}`} />
                <DeleteCategory
                    id={categoryId}
                    back={`/customer/category/${categoryId}`}
                    onSuccess={() => this.props.history.push(RouteConstants.CUSTOMER_DEFAULT)}
                />
            </React.Fragment>
        )
    }
}

export default PCategoryDelete
