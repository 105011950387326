import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getTriggers, getVariable } from '../../data/categories/category.actions'
import { ITriggerPerVariable, IVariable } from '../../data/categories/category.interface'
import { IApiConsumerArray, IApiConsumerEntity } from '../../data/actions.interface'
import APageHeader from '../../components/atoms/APageHeader'
import APageRow from '../../components/atoms/APageRow'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { formatDateTime } from '../../utils/format'
import AButton from '../../components/atoms/AButton'

interface IVariableDetail extends IApiConsumer {
    id: string
    variable: IApiConsumerEntity<IVariable>
    triggers: IApiConsumerArray<ITriggerPerVariable>
    editRoute?: string
    back?: string | null
}

const triggerColumns: Array<ITableColumn<ITriggerPerVariable>> = [
    {
        key: 'userIdentifier',
        title: 'User',
    },
    {
        key: 'triggerCount',
        title: '# triggers',
    },
    {
        key: 'firstTrigger',
        title: 'First trigger',
        render: row => formatDateTime(row.firstTrigger),
    },
    {
        key: 'lastTrigger',
        title: 'Most recent trigger',
        render: row => formatDateTime(row.lastTrigger),
    },
]

class VariableDetail extends React.Component<IVariableDetail> {
    render() {
        const { variable, back, editRoute, triggers } = this.props
        if (variable.status !== 'success') {
            return 'loading'
        }

        return (
            <React.Fragment>
                <APageHeader
                    back={back}
                    buttons={[
                        editRoute && (
                            <AButton outline color="secondary" to={editRoute}>
                                Edit variable
                            </AButton>
                        ),
                    ]}
                >
                    Variable: {variable.response.name}
                </APageHeader>
                <APageRow title="Triggers">
                    <OTable
                        rowKey="userIdentifier"
                        data={triggers.response}
                        columns={triggerColumns}
                    />
                </APageRow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    variable: props => getVariable(props.id),
    triggers: props => getTriggers(props.id),
})(VariableDetail)
