import * as React from 'react'
import { apiConsumer, IApiConsumer, IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import { getCustomerDetail, setCustomerStatus } from '../../data/admin/customer.actions'
import { ICustomer } from '../../data/admin/customer.interface'
import { IApiConsumerEntity } from '../../data/actions.interface'
import ARow from '../../components/atoms/ARow'
import ACol from '../../components/atoms/ACol'
import MDescriptiveList from '../../components/molecules/MDescriptiveList'
import MStatisticsList from '../../components/molecules/MStatisticsList'
import CustomerPosts from '../../containers/post/CustomerPosts'
import { formatDate } from '../../utils/format'
import { AButton } from '../../components/atoms'
import APageRow from '../../components/atoms/APageRow'
import AHeading from '../../components/atoms/AHeading'
import axios from 'axios'
import AText from '../../components/atoms/AText'
import APageHeader from '../../components/atoms/APageHeader'

interface ICustomerDetail extends IApiConsumer, IWithAuthStatus {
    customer: IApiConsumerEntity<ICustomer>
    id: string
    back?: string
    buttons?: Array<React.ReactNode>
}
const headingStyle = {
    padding: '20px 35px',
    borderBottom: 'solid 1px #EEE',
    backgroundColor: '#ffffff',
}
const customfileupload = {
    border:  'none',
    fontSize: '1rem',
    display:  'block',
    padding:  '10px 5px',
    cursor:  'pointer',
    backgroundColor: '#ffffff',
}
/*
<APageHeader back={back} buttons={[
                auth.isAdmin
                && (customer.response.status === 'active'
                    ? <AButton outline color="warning" onClick={this.setCustomerStatus('disabled')}>
                        Disable customer
                    </AButton>
                    : <AButton outline color="success" onClick={this.setCustomerStatus('active')}>
                        Enable customer
                    </AButton>),
                ...buttons,
            ]}>
                {customer.response.status === 'disabled' && 'DISABLED: '} {customer.response.name}
            </APageHeader>

            <MDescriptiveList
                    items={[
                        {
                            name: 'secret',
                            value: customer.response.secret,
                        },
                    ]}
                />
*/
const baseUrl = process.env.REACT_APP_GATEWAY_URL
    ? process.env.REACT_APP_GATEWAY_URL
    : `${window.location.protocol}//${window.location.host}`
class CustomerDetail extends React.Component<ICustomerDetail> {
    state = {
        selectedFile: null,
        disabled: false,
        message: '',
    }
    setCustomerStatus = (status: 'active' | 'disabled') => () => {
        this.props.dispatch(setCustomerStatus(this.props.id, status)).catch(console.warn)
    }

    changeHandler = event => {
        this.setState({ selectedFile: event.target.files[0] })
        this.setState({ message: '' })

    }

    handleSubmission = () => {
        if (this.state.disabled) {
            return
        }
        this.setState({ disabled: true })
        this.setState({ message: '' })
        if (this.props.customer.response.secret != null) {
            const formData = new FormData()
            formData.append(
                'csvFile',
                this.state.selectedFile,
                this.state.selectedFile.name
            )
            const headers = {
                'x-centreblock-token': this.props.customer.response.secret,
            }
            axios.post(`${baseUrl}/api/v1/csv/variables`, formData, {
                headers,
            }).then(response => {
                this.setState({
                    disabled: false,
                    message: 'variables uploaded successfully'})
            }).catch(error => {
                const data = error.response.data
                this.setState({
                    disabled: false,
                    message: JSON.stringify(data) })
            })
        }
    }
    render() {
        const { customer, back, auth, buttons = [], id } = this.props
        if (customer.status !== 'success') {
            return 'loading'
        }

        return (
            <React.Fragment>
                <APageHeader
                    back={back}
                    buttons={[
                    auth.isAdmin
                    && (customer.response.status === 'active'
                        ? <AButton outline color="warning" onClick={this.setCustomerStatus('disabled')}>
                            Disable customer
                        </AButton>
                        : <AButton outline color="success" onClick={this.setCustomerStatus('active')}>
                            Enable customer
                        </AButton>),
                    ...buttons,
                ]}
                >
                    {customer.response.status === 'disabled' && 'DISABLED: '} {customer.response.name}
                </APageHeader>
                <ARow>
                    <ACol md={4}>
                        <MStatisticsList
                            title="Statistics"
                            items={[
                                {
                                    name: 'Active users',
                                    icon: 'user2',
                                    to: 'customer',
                                    value: customer.response.userCount,
                                },
                                {
                                    name: 'Total triggers',
                                    icon: 'trigger',
                                    to: 'reporting',
                                    value: customer.response.triggerCount,
                                },
                                {
                                    name: 'Variables',
                                    icon: 'variables',
                                    to: 'variable',
                                    value: customer.response.variableCount,
                                },
                                {
                                    name: 'Target audiences',
                                    icon: 'target-audience',
                                    to: 'targetAudience',
                                    value: customer.response.categoryCount,
                                },
                            ]}
                            button={
                                auth.isAdmin
                                    ? {
                                          to: `/admin/customers/${id}/reporting/${customer.response.name}`,
                                          text: 'View report',
                                      }
                                    : {
                                          to: 'reporting',
                                          text: 'View report',
                                      }
                            }
                        />
                    </ACol>
                    <ACol md={4}>
                        <CustomerPosts />
                    </ACol>
                    <ACol md={4}>
                        <MDescriptiveList
                            title={customer.response.name}
                            items={[
                                {
                                    name: 'License',
                                    value: customer.response.status,
                                },
                                {
                                    name: 'Active since',
                                    value: formatDate(customer.response.createdAt),
                                },
                                {
                                    name: 'Admin users',
                                    value: customer.response.adminCount,
                                },
                            ]}
                        />
                    </ACol>
                </ARow>

                <ACol md={6} style={customfileupload} >
                    <APageRow >
                        <AHeading style={headingStyle} type="h6" hidden={!auth.isAdmin}>
                            Upload variables from csv for {customer.response.name}
                        </AHeading>
                        <div  key="upload-csv" hidden={!auth.isAdmin}>
                            <input
                                style={customfileupload}
                                type="file"
                                onChange={this.changeHandler}
                            />
                            <AText >{this.state.message}</AText>
                            <AButton type="submit" color="primary" onClick={this.handleSubmission} disabled={this.state.disabled} >
                                {this.state.disabled ? 'Uploading...' : 'Upload'}
                            </AButton>
                        </div>
                    </APageRow>
                </ACol>
            </React.Fragment>
        )
    }
}

export default withAuthStatus(
    apiConsumer({
        customer: props => getCustomerDetail(props.id),
    })(CustomerDetail),
)
