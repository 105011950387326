import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { AdminPowerbiReport } from '../../containers/category/PowerbiReport'
import APageHeader from '../../components/atoms/APageHeader'
import AButton from '../../components/atoms/AButton'
import SetReportEmbedUrl from '../../containers/admin/SetReportEmbedUrl'
import APageRow from '../../components/atoms/APageRow'

class PCustomerReportAdmin extends React.Component<
    RouteComponentProps<{ customerId: string, customerName: string }>,
    { edit: boolean }
> {
    state = {
        edit: false,
    }

    setEdit = (edit: boolean) => {
        this.setState({ edit })
    }

    render() {
        const customerId = this.props.match.params.customerId
        const customerName = this.props.match.params.customerName

        return (
            <React.Fragment>
                <APageHeader
                    buttons={[
                        <AButton
                            key="edit"
                            outline
                            onClick={() => this.setEdit(!this.state.edit)}
                            color="primary"
                        >
                            {this.state.edit ? 'Cancel' : 'Set custom report'}
                        </AButton>,
                    ]}
                >
                    Report as seen by customer {customerName}
                </APageHeader>

                {this.state.edit && (
                    <APageRow>
                        <SetReportEmbedUrl
                            customerId={customerId}
                            onSuccess={() => this.setEdit(false)}
                        />
                    </APageRow>
                )}
                <AdminPowerbiReport id={customerId} />
            </React.Fragment>
        )
    }
}

export default PCustomerReportAdmin
