import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { deleteCategory, getCategory } from '../../data/categories/category.actions'
import APageRow from '../../components/atoms/APageRow'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { ICategory } from '../../data/categories/category.interface'
import AText from '../../components/atoms/AText'
import AButtonGroup from '../../components/atoms/AButtonGroup'
import AButton from '../../components/atoms/AButton'

interface IDeleteCategory extends IApiConsumer {
    id: string
    category: IApiConsumerEntity<ICategory>
    back: string
    onSuccess: () => void
}

class DeleteCategory extends React.Component<IDeleteCategory> {
    handleDelete = () => {
        return this.props.dispatch(deleteCategory(this.props.id)).then(res => {
            this.props.trigger('delete_target_audience')
            return this.props.onSuccess()
        })
    }

    render() {
        const { category, back } = this.props
        if (category.status !== 'success') {
            return null
        }

        return (
            <APageRow
                title={`Are you sure you want to delete target audience '${category.response.name}'`}
            >
                <AText margined>
                    By deleting this audience, anyone in your organization will be unable to view
                    the triggers, create new variables or trigger variables connected to this target
                    audience anymore.
                </AText>
                <AText margined emph>
                    ARE YOU SURE?
                </AText>
                <AButtonGroup>
                    <AButton color="primary" to={back}>
                        No, take me back to safety!
                    </AButton>
                    <AButton outline color="error" onClick={this.handleDelete}>
                        Yes I am sure! Delete this target audience permanently from the system
                    </AButton>
                </AButtonGroup>
            </APageRow>
        )
    }
}

export default apiConsumer({
    category: props => getCategory(props.id),
})(DeleteCategory)
