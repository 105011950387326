import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider, addLocaleData } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'

import './index.css'
import './bootstrap.css'

import * as serviceWorker from './serviceWorker'
import App from './App'
import ApiProvider from './data/ApiProvider'
import { ThemeProvider } from './styledComponents'
import { defaultTheme } from './theme'

addLocaleData(enLocaleData)

const baseUrl = process.env.REACT_APP_GATEWAY_URL
    ? process.env.REACT_APP_GATEWAY_URL
    : `${window.location.protocol}//${window.location.host}`

const render = () => {
    ReactDOM.render(
        <IntlProvider locale="en">
            <ApiProvider socketUrl={baseUrl} apiUrl={`${baseUrl}/api/v1`}>
                <ThemeProvider theme={defaultTheme}>
                    <App />
                </ThemeProvider>
            </ApiProvider>
        </IntlProvider>,
        document.getElementById('root'),
    )
}

serviceWorker.register()

render()

if (module.hot) {
    module.hot.accept('./App', () => {
        render()
    })
}
