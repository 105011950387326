import * as React from 'react'
import { Formik, FormikProps, Form, FormikValues } from 'formik'
import classNames from 'classnames'

import styled from '../../styledComponents'

export interface IFormProps {
    initialValues?: object
    onSubmit: (values: any) => any
    children?: React.ReactNode
    validationSchema?: any
    className?: string
}

const StyledForm = styled(Form)`
    &.with-margin-top {
        margin-top: 1rem;
    }
`
const Error = styled.p`
    color: ${props => props.theme.colorActiveError};
`
const transformValidateErrors = (errors: any) =>
    errors.reduce(
        (agg: any, err: any) => ({
            ...agg,
            [err.property]: Object.values(err.constraints),
        }),
        {},
    )

const MForm: React.FunctionComponent<IFormProps> = ({
    initialValues = {},
    onSubmit,
    validationSchema,
    className,
    children,
}) => {
    const classes = classNames([className])
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) =>
                onSubmit(values).catch((res: any) => {
                    // console.log('onSubmit error handling is called', res)
                    // TODO: Fix correct handling validation errors.
                    if (res.status === 'validationError') {
                        // console.log('actions.setErrors is called')
                        actions.setErrors(transformValidateErrors(res.data.message))
                    }
                    if (res.status === 'submitError') {
                        actions.setStatus(res.data)
                    }
                })
            }
            render={(props: FormikProps<FormikValues>) => (
                <StyledForm className={classes}>
                    {React.Children.map(children, child =>
                        React.cloneElement(child as React.ReactElement<any>, props),
                    )}
                    {props.status && <Error>{props.status}</Error>}
                </StyledForm>
            )}
        />
    )
}

export default MForm
