import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import { RouteComponentProps } from 'react-router'
import CustomerAdminDelete from '../../containers/admin/CustomerAdminDelete'

class PDeleteCustomerAdmin extends React.Component<
    RouteComponentProps<{ customerId: string; userId: string }>
> {
    render() {
        const { customerId, userId } = this.props.match.params
        const back = `/admin/customers/${customerId}`

        return (
            <React.Fragment>
                <APageHeader back={back}>Confirm deletion of admin: {userId}</APageHeader>

                <CustomerAdminDelete
                    customerId={customerId}
                    id={userId}
                    back={back}
                    onSuccess={() => this.props.history.push(back)}
                />
            </React.Fragment>
        )
    }
}

export default PDeleteCustomerAdmin
