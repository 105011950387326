import * as React from 'react'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getAuthUser } from '../../data/auth/auth.actions'
import MDescriptiveList from '../../components/molecules/MDescriptiveList'

interface IUserInfo extends IApiConsumer {
    user: IApiConsumerEntity<any>
}

const UserInfo: React.FC<IUserInfo> = ({ user }) => {
    if (user.status !== 'success') {
        return <p>Loading..</p>
    }

    // TODO: Check this log, and create the component
    console.log(user.response)
    return (
        <MDescriptiveList
            title="Your account"
            items={[
                {
                    name: 'Full name',
                    value: user.response.firstName + ' ' + user.response.lastName,
                },
                {
                    name: 'Company',
                    value: user.response.customer.name,
                },
                {
                    name: 'E-mail address',
                    value: user.response.email,
                },
                {
                    name: 'Password',
                    value: '*********',
                },
            ]}
        />
    )
}

export default apiConsumer({
    user: _ => getAuthUser(),
})(UserInfo)
