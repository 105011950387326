import * as React from 'react'
import { Route, Switch } from 'react-router'
import PageNotFound from '../layout/PageNotFound'
import PTriggerTest from './PTriggerTest'

const route = (path: string) => `/test/${path}`

const TestRoutes = () => (
    <Switch>
        <Route path={route('trigger')} component={PTriggerTest} />

        <Route component={() => <PageNotFound back={route('trigger')} />} />
    </Switch>
)

export default TestRoutes
