import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import { IRouteProps } from './route.interface'
import { RouteConstants } from './route.constants'
import { lastRoute } from './route.utils'

const CustomerRoute: React.FunctionComponent<IWithAuthStatus & IRouteProps> = ({
    component: Component,
    auth,
    path,
    ...rest
}) => (
    <Route
        {...rest}
        render={props =>
            !auth.loggedIn ? (
                <Redirect to={RouteConstants.UNAUTH_DEFAULT} />
            ) : auth.isAdmin ? (
                <Redirect
                    to={lastRoute(RouteConstants.ADMIN_DEFAULT, location && location.pathname)}
                />
            ): (auth.report !== undefined && auth.report !== '' && auth.report !== null && !location.pathname.includes("reporting"))? (
                <Redirect
                to= {RouteConstants.REPORTS}
            />
            ): (
                <Component {...props} />
            )
        }
    />
)

export default withAuthStatus(CustomerRoute)
