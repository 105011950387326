import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import SearchOverview from '../../containers/search/SearchOverview'
interface IPSearch {}


class PSearch extends React.Component<RouteComponentProps<IPSearch>> {
    render() {
        return (
            <React.Fragment>
                <SearchOverview />
            </React.Fragment>
        )
    }
}

export default PSearch
