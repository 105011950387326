export const lastRoute = (defaultRoute: string, currentPath: string) => {
    const last = localStorage.getItem('last-route')

    if (!last || last === currentPath) {
        localStorage.removeItem('last-route')
        return defaultRoute
    }

    return last
}
