import * as React from 'react'
import classNames from 'classnames'
import styled from '../../styledComponents'

export interface IFormGroupProps {
    className?: string
    disabled?: boolean
    check?: boolean
    small?: boolean
    noMargin?: boolean
    children: React.ReactNode
}

const FG = styled.div`
    margin-bottom: 2rem;
    &.no-margin {
        margin-bottom: 0;
    }
    &.small {
        margin-bottom: 1rem;
    }
`

const AFormGroup = (props: IFormGroupProps) => {
    const { className, disabled, check, small, noMargin, ...attributes } = props

    const classes = classNames(
        className,
        'position-relative',
        check ? 'form-check' : 'form-group',
        noMargin && 'no-margin',
        small && 'small',
        check && disabled && 'disabled',
    )

    return <FG {...attributes} className={classes} />
}

export default AFormGroup
