import * as React from 'react'
import APageRow from '../../components/atoms/APageRow'
import MForm from '../../components/molecules/MForm'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import { getTagsForVariable, updateTag } from '../../data/categories/category.actions'
import AButtonGroup from '../../components/atoms/AButtonGroup'
import { IApiConsumerArray } from '../../data/actions.interface'
import { ITag } from '../../data/categories/category.interface'

interface IUpdateTag extends IApiConsumer {
    variableId: string
    tagId: number
    onSuccess: (res: any) => void
    centered?: boolean,
    back: string,
    tags: IApiConsumerArray<ITag>
}

class UpdateTag extends React.Component<IUpdateTag> {
    handleSubmit = (values: any) => {
        return this.props.dispatch(updateTag(this.props.tagId, values.key, values.value)).then(res => {
            this.props.trigger('update_tag')
            return this.props.onSuccess(res)
        })
    }

    render() {
        const { centered , tags } = this.props
        if (tags.status !== 'success') {
            return 'loading'
        }
        const tag = tags.response.find(t => t.id === this.props.tagId)
        return (
            <APageRow centered={centered}>
                <MForm
                    key="update_tag"
                    onSubmit={this.handleSubmit}
                >
                    <AField
                        name="key"
                        type="text"
                        placeholder={tag!.key}
                        label={
                            <FormattedMessage
                                id="key"
                                defaultMessage="Tag Key"
                            />
                        }
                    />
                    <AField
                        name="value"
                        type="text"
                        placeholder={tag!.value}
                        label={
                            <FormattedMessage
                                id="value"
                                defaultMessage="Tag Value"
                            />
                        }
                    />
                    <AButtonGroup>
                    <AButton outline color="primary" to={this.props.back}>
                        Cancel
                    </AButton>
                    <AButton outline color="error" type="submit">
                        Update Tag
                    </AButton>
                    </AButtonGroup>
                </MForm>
            </APageRow>
        )
    }
}

export default apiConsumer({
    tags: props => getTagsForVariable(props.variableId),

})(UpdateTag)
