import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteConstants } from '../../containers/routes/route.constants'
import CategoryDetail from '../../containers/category/CategoryDetail'
import VariableOverview from '../../containers/category/VariableOverview'

class PCategoryDetail extends React.Component<RouteComponentProps<{ categoryId: string }>> {
    render() {
        const { categoryId } = this.props.match.params

        return (
            <React.Fragment>
                <CategoryDetail
                    id={categoryId}
                    back={RouteConstants.CUSTOMER_DEFAULT}
                    onDelete={`/customer/category/${categoryId}/delete`}
                />
                <VariableOverview
                    categoryId={categoryId}
                    onCreate={`/customer/category/${categoryId}/variable/create`}
                />
            </React.Fragment>
        )
    }
}

export default PCategoryDetail
