import * as React from 'react'

import { ARow, ACol } from '../components/atoms/index'
import Login from '../containers/auth/Login'

const PLogin = () => (
    <ARow>
        <ACol md={{ size: 4, offset: 4 }}>
            <Login />
        </ACol>
    </ARow>
)

export default PLogin
