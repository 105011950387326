import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CustomerAdminModify from '../../containers/admin/CustomerAdminModify';

class PModifyCustomerAdmin extends React.Component<
    RouteComponentProps<{ customerId: string; userId: string }>
> {
    render() {
        const { customerId, userId } = this.props.match.params
        const back = `/admin/customers/${customerId}`

        return (
            <React.Fragment>
                
                <CustomerAdminModify
                    customerId={customerId}
                    id={userId}
                    back={back}
                    onSuccess={() => this.props.history.push(back)}
                />
            </React.Fragment>
        )
    }
}

export default PModifyCustomerAdmin
