import * as React from 'react'

import { ARow, ACol } from '../components/atoms/index'
import ForgotPassword from '../containers/auth/ForgotPassword'

const PForgotPassword = () => (
    <ARow>
        <ACol md={{ size: 4, offset: 4 }}>
            <ForgotPassword />
        </ACol>
    </ARow>
)

export default PForgotPassword
