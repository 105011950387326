import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CreateTag from '../../containers/category/CreateTag'

class PTagCreate extends React.Component<RouteComponentProps<{ variableId: string, name: string}>> {
    render() {
        const { variableId, name } = this.props.match.params
        return (
            <React.Fragment>
                <CreateTag
                    variableId={variableId}
                    back={`/customer/variable/${variableId}/newEditVariable/${name}`}
                    onSuccess={() => this.props.history.push(`/customer/variable/${variableId}/newEditVariable/${name}`)}
                />
            </React.Fragment>
        )
    }
}

export default PTagCreate
