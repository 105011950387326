import * as React from 'react'
import APageRow from '../../components/atoms/APageRow'
import MForm from '../../components/molecules/MForm'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import { createTag } from '../../data/categories/category.actions'
import AButtonGroup from '../../components/atoms/AButtonGroup'

interface ICreateTag extends IApiConsumer {
    variableId: string
    onSuccess: (res: any) => void
    centered?: boolean,
    back: string,
}

class CreateTag extends React.Component<ICreateTag> {
    handleSubmit = (values: any) => {
        return this.props.dispatch(createTag(this.props.variableId, values.key, values.value)).then(res => {
            return this.props.onSuccess(res)
        })
    }

    render() {
        const { centered } = this.props
        return (
            <APageRow centered={centered}>
                <MForm key="update_tag" onSubmit={this.handleSubmit}>
                    <AField
                        name="key"
                        type="text"
                        label={
                            <FormattedMessage
                                id="key"
                                defaultMessage="Tag Key"
                            />
                        }
                    />
                    <AField
                        name="value"
                        type="text"
                        label={
                            <FormattedMessage
                                id="value"
                                defaultMessage="Tag Value"
                            />
                        }
                    />
                    <AButtonGroup>
                    <AButton outline color="primary" to={this.props.back}>
                        Cancel
                    </AButton>
                    <AButton outline color="error" type="submit">
                        Create
                    </AButton>
                    </AButtonGroup>
                </MForm>
            </APageRow>
        )
    }
}

export default apiConsumer({
})(CreateTag)
