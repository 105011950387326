import * as React from 'react'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { getAuthUser } from '../../data/auth/auth.actions'

interface IUserInfoTopBar extends IApiConsumer {
    user: IApiConsumerEntity<any>
}

const UserInfoTopBar: React.FC<IUserInfoTopBar> = ({ user }) => {
    if (user.status !== 'success') {
        return <p>Loading..</p>
    }

    return (
        <div className="txt">
            <span className="user">
                {user.response.firstName} {user.response.lastName}
            </span>
            <span className="customer">{user.response.customer.name}</span>
        </div>
    )
}

export default apiConsumer({
    user: _ => getAuthUser(),
})(UserInfoTopBar)
