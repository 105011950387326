import * as React from 'react'
import classNames from 'classnames'

export interface IColShape {
    size: number | string
    order?: number | string
    offset?: number | string
}

type IColProps = number | IColShape

export interface IColumnProps extends React.HTMLAttributes<HTMLDivElement> {
    xs?: IColProps
    sm?: IColProps
    md?: IColProps
    lg?: IColProps
    xl?: IColProps
    className?: string
    children: React.ReactNode
    separatorRight?: boolean
}

class ACol extends React.Component<IColumnProps, {}> {
    sizes = ['xs', 'sm', 'md', 'lg', 'xl']

    getClassKey = (type: string): [string, IColProps] => [
        type === 'xs' ? '-' : `-${type}-`,
        this.props[type],
    ]

    forceIColShape = ([key, prop]: [string, IColProps]): [string, IColShape] => [
        key,
        typeof prop === 'number' ? { size: prop } : prop,
    ]

    getClassNamesForSize = ([key, prop]: [string, IColShape]): string =>
        classNames([
            prop.size && `col${key}${prop.size}`,
            prop.order && `order${key}${prop.order}`,
            prop.offset && `offset${key}${prop.offset}`,
        ])

    render() {
        const { className, separatorRight, ...attributes } = this.props

        const classes: Array<string> = this.sizes
            .filter(type => !!this.props[type])
            .map(this.getClassKey)
            .map(this.forceIColShape)
            .map(this.getClassNamesForSize)

        const finalClass: string = classNames(classes, className, separatorRight && 'sep-right')

        return <div {...attributes} className={finalClass} />
    }
}

export default ACol
