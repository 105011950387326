import React, { useCallback, useMemo, useRef, useState } from "react"
import APageRow from "../../components/atoms/APageRow"
import { TagResult } from "./TagResult"
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import styled from "styled-components";
interface ITagGeneratorResults {
    data: Array<TagResult>
}

const Button = styled.button`
  background-color: #6da1de;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: #37516f;
  }
 
`;




class TagGeneratorResults extends React.Component<ITagGeneratorResults> {
    gridRef = React.createRef();
    containerStyle = { width: '100%', height: '100%' };
    gridStyle = { height: '100%', width: '100%' };

    defaultColDef =
        {
            editable: true,
            resizable: true,
            minWidth: 100,
            flex: 1,
        };


    onBtnExport = () => {
        this.gridRef.current.api.exportDataAsCsv();
    }

    state = {
        columnDefs: [
            { headerName: "Consumer", field: "uuid" },
            { headerName: "Activiteitencode", field: "activiteitencode1" },
            { headerName: "Handelsnaam", field: "handelsnaam" },
            { headerName: "Directeur Achternaam", field: "directeur1Achternaam" },
            { headerName: "Vestiging Plaatsnaam", field: "vestigingPlaatsnaam" },
            { headerName: "Vestiging Postcode", field: "vestigingPostcode" },
            { headerName: "Telefoonnummer", field: "telefoon1Telefoonnummer" },
            { headerName: "InternetHostname", field: "internetHostname" },
            { headerName: "Aantalwerkzamepersonen", field: "aantalwerkzamepersonen" },
            { headerName: "Consumer Id", field: "consumerId" },
            { headerName: "Name", field: "name" },
            { headerName: "Address", field: "address" },
            { headerName: "City", field: "city" },
            { headerName: "Country", field: "countryCode" },
            { headerName: "Email", field: "email" },
            { headerName: "Employees", field: "employees" },
            { headerName: "Instagram", field: "instagram" },
            { headerName: "Linkedin", field: "linkedin" },
            { headerName: "Orgtype", field: "orgtype" },
            { headerName: "Phone", field: "phone" },
            { headerName: "Twitter", field: "twitter" },
            { headerName: "sbi", field: "sbi" },
            { headerName: "urls", field: "urls" },
            { headerName: "url", field: "url" },
            { headerName: "Facebook", field: "facebook" },

        ]
    }

    render() {

       

        return (
            <div style={this.containerStyle} className="ag-theme-balham">
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: '10px 0' }}>
                        <Button onClick={this.onBtnExport} >Export to CSV</Button>
                    </div>
                    <div
                        className="ag-theme-balham"
                        style={{
                            height: '500px',
                            width: '1200px'
                        }}
                    >
                        <AgGridReact
                            ref={this.gridRef}
                            columnDefs={this.state.columnDefs}
                            rowData={this.props.data}>
                        </AgGridReact>
                    </div>

                </div>
            </div>
        )
    }
}

export default TagGeneratorResults