import * as React from 'react'
import { Route, Switch } from 'react-router'

import PCustomerDashboard from './PCustomerDashboard'
import PCreateCategory from './PCreateCategory'
import PVariableOverview from './PVariableOverview'
import PCustomerOverview from './PCustomerOverview'
import PTargetAudience from './PTargetAudience'
import PCategoryDetail from './PCategoryDetail'
import PCreateVariable from './PCreateVariable'
import PageNotFound from '../layout/PageNotFound'
import { RouteConstants } from '../../containers/routes/route.constants'
import PVariableDetail from './PVariableDetail'
import PConsumerDetail from './PConsumerDetail'
import PConsumerDelete from './PConsumerDelete'
import PEditVariable from './PEditVariable'
import PCategoryDelete from './PCategoryDelete'
import PReporting from './PReporting'
import PTagsOverview from './PTagsOverview'
import PVariableDetailNew from './PVariableDetailNew'
import PEditVariableV2 from './PEditVariableV2'
import PTagUpdate from './PTagUpdate'
import PTagDelete from './PTagDelete'
import PTagCreate from './PTagCreate'
import PSearch from './PSearch'


const route = (path: string) => `/customer/${path}`

const           CustomerRoutes = () => (
    <Switch>
        <Route path={route('dashboard')} component={PCustomerDashboard} />
        <Route path={route('reporting')} component={PReporting} />
        <Route path={route('customer')} component={PCustomerOverview} />
        <Route path={route('targetAudience')} component={PTargetAudience} />
        <Route path={route('category/create')} component={PCreateCategory} />
        <Route path={route('category/:categoryId/delete')} component={PCategoryDelete} />
        <Route path={route('category/:categoryId/variable/create')} component={PCreateVariable} />
        <Route
            path={route('category/:categoryId/variable/:variableId/edit')}
            component={PEditVariable}
        />
        <Route
            path={route('category/:categoryId/variable/:variableId')}
            component={PVariableDetail}
        />
        <Route path={route('category/:categoryId')} component={PCategoryDetail} />
        <Route path={route('variable/create')} component={PCreateVariable} />
        <Route path={route('variable/:variableId/edit')} component={PEditVariable} />
        <Route path={route('variable/:variableId/editV2')} component={PEditVariableV2} />
        <Route path={route('variable/:variableId/newEditVariable/:name')} component={PVariableDetailNew} />
        <Route path={route('variable/:variableId')} component={PVariableDetail} />
        <Route path={route('variable')} component={PVariableOverview} />
        <Route path={route('consumer/:consumerId/delete')} component={PConsumerDelete} />
        <Route path={route('consumer/:consumerId')} component={PConsumerDetail} />
        <Route path={route('tags/update/:variableId/id/:id/name/:name')} component={PTagUpdate} />
        <Route path={route('tags/delete/:variableId/id/:id/key/:key/name/:name')} component={PTagDelete} />
        <Route path={route('tags/create/:variableId/name/:name')} component={PTagCreate} />
        <Route path={route('tags')} component={PTagsOverview} />
        <Route path={route('search')} component={PSearch} />

        <Route component={() => <PageNotFound back={RouteConstants.CUSTOMER_DEFAULT} />} />
    </Switch>
)

export default CustomerRoutes
