import * as React from 'react'

import { apiConsumer, IApiConsumer, IWithAuthStatus } from '../../data/ApiProvider'
import { IPost } from '../../data/admin/customer.interface'
import { IApiConsumerArray } from '../../data/actions.interface'
import { deletePost, getPosts } from '../../data/admin/customer.actions'
import PostComponent from '../post/PostComponent'

interface IPostOverview extends IApiConsumer, IWithAuthStatus {
    posts: IApiConsumerArray<IPost>
    customerId?: string
}

class PostOverview extends React.Component<IPostOverview> {
    handleDelete = (id: number) => {
        return this.props.dispatch(deletePost(id, this.props.customerId))
    }

    render() {
        const { posts } = this.props

        return (
            <React.Fragment>
                {posts.status === 'success' &&
                    posts.response.map(post => (
                        <PostComponent post={post} onDelete={this.handleDelete} />
                    ))}
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    posts: props => getPosts(props.customerId),
})(PostOverview)
