import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import VariableDetailNew from '../../containers/category/VariableDetailNew'

class PVariableDetailNew extends React.Component<
    RouteComponentProps<{ categoryId?: string; variableId: string, name: string }>
> {
    render() {
        const { variableId , name } = this.props.match.params

        return (
                <VariableDetailNew
                    id={variableId}
                    editRoute={`/customer/variable/${variableId}/editV2`}
                    back={'/customer/variable'}
                    name={name}
                />
        )
    }
}

export default PVariableDetailNew
