import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerArray } from '../../data/actions.interface'
import { ICategory } from '../../data/categories/category.interface'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { getCategories } from '../../data/categories/category.actions'

interface ICategoryOverview extends IApiConsumer {
    categories: IApiConsumerArray<ICategory>
}

const categoryColumns: Array<ITableColumn<ICategory>> = [
    {
        key: 'name',
        title: 'Name',
    },
    {
        key: 'identifier',
        title: 'Identifier',
    },
    {
        key: 'variableCount',
        title: '# variables',
    },
    {
        key: 'view',
        render: row => (
            <AButton color="primary" outline to={`/customer/category/${row.id}`}>
                View
            </AButton>
        ),
    },
]

class CategoryOverview extends React.Component<ICategoryOverview> {
    render() {
        const { categories } = this.props

        return (
            <React.Fragment>
                <APageRow
                    title="Target audiences"
                    buttons={[
                        <AButton
                            key="create"
                            color="primary"
                            outline
                            to={'/customer/category/create'}
                        >
                            New target audience
                        </AButton>,
                    ]}
                >
                    <OTable rowKey="id" data={categories.response} columns={categoryColumns} />
                </APageRow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    categories: props => getCategories(),
})(CategoryOverview)
