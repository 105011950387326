import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CreatePost from '../../containers/admin/CreatePost'
import APageHeader from '../../components/atoms/APageHeader'
import CustomerDetail from '../../containers/admin/CustomerDetail'

const baseUrl = (customerId?: string) =>
    `/admin/${customerId ? `customers/${customerId}/` : 'posts'}`

class PCreatePost extends React.Component<RouteComponentProps<{ customerId?: string }>> {
    render() {
        const { customerId } = this.props.match.params

        return (
            <React.Fragment>
                <APageHeader back={baseUrl(customerId)}>
                    {customerId
                        ? 'Create a new post for specific customer'
                        : 'Create a new generic post'}
                </APageHeader>
                <CreatePost
                    customerId={customerId}
                    onSuccess={() => this.props.history.push(baseUrl(customerId))}
                />
                {customerId && <CustomerDetail id={customerId} />}
            </React.Fragment>
        )
    }
}

export default PCreatePost
