import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { AField } from '../../components/atoms'
import AButton from '../../components/atoms/AButton'
import MForm from '../../components/molecules/MForm'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { ICustomer } from '../../data/admin/customer.interface'
import {
    deleteEmbedUrl,
    getAdminReport,
    getCustomerDetail,
    setEmbedUrl,
} from '../../data/admin/customer.actions'

interface ISetReportEmbedUrl extends IApiConsumer {
    customer: IApiConsumerEntity<ICustomer>
    customerId: string
    onSuccess?: () => void
}

class SetReportEmbedUrl extends React.Component<ISetReportEmbedUrl> {
    handleSubmit = (values: any) => {
        return this.props.dispatch(setEmbedUrl(this.props.customerId, values)).then(res => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            return this.props.dispatch(getAdminReport(this.props.customerId))
        })
    }

    handleReset = () => {
        return this.props.dispatch(deleteEmbedUrl(this.props.customerId)).then(res => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            return this.props.dispatch(getAdminReport(this.props.customerId))
        })
    }

    render() {
        const { customer } = this.props

        if (customer.status !== 'success') {
            return 'loading..'
        }

        return (
            <div>
                <MForm
                    onSubmit={this.handleSubmit}
                    initialValues={{ embedUrl: customer.response.embedUrl }}
                    key="create-embed-url"
                >
                    <AField
                        key="embedUrl"
                        type="text"
                        name="embedUrl"
                        placeholder="Embed URL as provided by powerbi"
                    />
                    <AButton type="submit" color="primary">
                        Save embed URL
                    </AButton>
                </MForm>
                <AButton color="error" outline onClick={this.handleReset}>
                    Reset to default report
                </AButton>
            </div>
        )
    }
}

export default apiConsumer({
    customer: props => getCustomerDetail(props.customerId),
})(SetReportEmbedUrl)
