import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import APageRow from '../../components/atoms/APageRow'
import AButton from '../../components/atoms/AButton'
import AButtonGroup from '../../components/atoms/AButtonGroup'
import { deleteCustomerAdmin, getCustomerAdmins } from '../../data/admin/customer.actions'
import { IApiConsumerArray } from '../../data/actions.interface'
import { IAdmin } from '../../data/admin/customer.interface'

interface ICustomerUserDelete extends IApiConsumer {
    customerId: string
    id: string
    back: string
    onSuccess: () => void
    users: IApiConsumerArray<IAdmin>
}

class CustomerAdminDelete extends React.Component<ICustomerUserDelete> {
    handleDelete = () => {
        this.props
            .dispatch(deleteCustomerAdmin(this.props.id, this.props.customerId))
            .then(this.props.onSuccess)
    }

    render() {
        const { back, id, users } = this.props
        const user =
            users.status === 'success' && users.response.find(u => Number(u.id) === Number(id))

        return (
            <APageRow
                title={`Are you sure you want to delete user ${user &&
                    user.email} permanently from the system?`}
            >
                <AButtonGroup>
                    <AButton color="primary" to={back}>
                        No, take me back to safety!
                    </AButton>
                    <AButton outline color="error" onClick={this.handleDelete}>
                        Yes I am sure! Delete {user && user.email} permanently from the system
                    </AButton>
                </AButtonGroup>
            </APageRow>
        )
    }
}

export default apiConsumer({
    users: props => getCustomerAdmins(props.customerId),
})(CustomerAdminDelete)
