import { ICombinedVariable, IVariable } from './category.interface'

export const combineCategories = (variables: Array<IVariable>): Array<ICombinedVariable> => {
    // TODO: Clean up, but this connects the categories to the variables, and merges the rest.
    const combinedObject = variables.reduce((res, v) => {
        if (res[v.name]) {
            return {
                ...res,
                [v.name]: {
                    ...res[v.name],
                    categories: [
                        ...res[v.name].categories,
                        {
                            category: v.category && v.category.name,
                            weight: v.weight,
                            value: v.value,
                            triggerCount: v.triggerCount,
                        },
                    ],
                    sumWeight: res[v.name].sumWeight + v.weight,
                    sumValue: res[v.name].sumValue + v.value,
                    sumTriggerCount: res[v.name].sumTriggerCount + v.triggerCount,
                },
            }
        }

        return {
            ...res,
            [v.name]: {
                id: v.id,
                name: v.name,
                categories: [
                    {
                        category: v.category && v.category.name,
                        weight: v.weight,
                        value: v.value,
                        triggerCount: v.triggerCount,
                    },
                ],
                sumWeight: v.weight,
                sumValue: v.value,
                sumTriggerCount: v.triggerCount,
            },
        }
    }, {})

    // Map to array again.
    return Object.keys(combinedObject).map(key => combinedObject[key])
}
