import * as React from 'react'
import { Redirect, Route } from 'react-router'

import { IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import { IRouteProps } from './route.interface'
import { RouteConstants } from './route.constants'
import { lastRoute } from './route.utils'

const AdminRoute: React.FunctionComponent<IRouteProps & IWithAuthStatus> = ({
    component: Component,
    auth,
    ...rest
}) => (
    <Route
        {...rest}
        render={props =>
            !auth.loggedIn ? (
                <Redirect to={RouteConstants.UNAUTH_DEFAULT} />
            ) : auth.isAdmin ? (
                <Component {...props} />
            ) : (auth.report !== undefined && auth.report !== '' && auth.report !== null)? (
                <Redirect
                to={lastRoute(RouteConstants.REPORTS, location && location.pathname)}
            />
            ):
            (
                <Redirect
                    to={lastRoute(RouteConstants.CUSTOMER_DEFAULT, location && location.pathname)}
                />
            )
        }
    />
)

export default withAuthStatus(AdminRoute)
