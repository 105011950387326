import React from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import styled from 'styled-components';
import { apiConsumer, IApiConsumer, IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider';
import { ICustomer } from '../../data/admin/customer.interface';
import { IApiConsumerEntity } from '../../data/actions.interface';
import { getCustomerDetail } from '../../data/admin/customer.actions';
import axios from 'axios'
import TagGeneratorResults from './TagGeneratorResults';
import { TagResult } from './TagResult';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
interface ISearchOverview extends IApiConsumer, IWithAuthStatus {
    customer: IApiConsumerEntity<ICustomer>
}
interface IResult {
    message: string,
    results: Array<TagResult>
  }
const initialValues = {
  variables: [
    {
      times: 0,
      op: '>',
      variable: '',
    },
  ],
  pages: [
    {
      times: 0,
      op: '>',
      page: '',
    },
],
  ipdetails: [
    {
      key: 'city',
      value: '',
    },
],
sbicode: [
  {
    op: '',
    value: '',
   }
],
  impactScore: [
    {
     op: '!=',
     value: 0,
    },
]
};



const SearchForm = styled.div`
    background: #ffffff;
    border-radius: 1px;
    border: 3px solid #6da1de;
    padding: 2px;
    margin: 2px;
    
    & form {
        padding: 5px 5px 5px;
    }

    & label {
        color:#17202A;
        font-size:16px;
    }

    & button {
        background-color: #6da1de;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        font-size: 10px;
        margin: 4px 2px;
        cursor: pointer;

`
const baseUrl = process.env.REACT_APP_GATEWAY_URL
    ? process.env.REACT_APP_GATEWAY_URL
    : `${window.location.protocol}//${window.location.host}`

class SearchOverview extends React.Component<ISearchOverview, IResult> {
    state = {
        message: '',
        results: []
    }
    render() {
        if (this.props.customer.status !== 'success') {
            return 'loading'
        }
        return  (
            <div>
            <SearchForm>
                
  <div className="ag-theme-balham">
 
    <h1>List Generator</h1>
    <br></br>
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        this.setState({
            message: ''})
        await new Promise((r) => setTimeout(r, 500));
        if (this.props.customer.response.secret != null) {
           
            const headers = {
                'x-centreblock-token': this.props.customer.response.secret,
            }
            axios.post(`${baseUrl}/api/v1/generate-tag`, values, {
                headers,
            }).then((response) => {
              const filtered = response.data.data
              .filter( result =>  result.name !== null || result.handelsnaam !== null)
              .filter((thing, i, arr) => {
                return arr.indexOf(arr.find(t => t.id === thing.id)) === i;
              })
              console.log(filtered)
                this.setState({results: filtered })
                this.setState({
                    message: 'search tag success'})
            }).catch(error => {
                const data = error.response.data
                this.setState({
                    message: JSON.stringify(data) })
            })
        }
      }}
    >
      {({ values }) => (
        
        <Form>
         
          <FieldArray name="variables">
            {({ insert, remove, push }) => (
              <div>
                  <button
                  type="button"
                  className="secondary"
                  onClick={() => push({ times: 0 , op: '', variable: '' })}
                > Add variable condition                 
                </button>
                {values.variables.length > 0 &&
                  values.variables.map((variable, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <label>Filter on users that clicked   </label>
                        <Field component="select"
                          name={`variables.${index}.op`}
                          placeholder=">,< or ="
                       >
                         <option value=">">&gt;</option>
                         <option value="<">&lt;</option>
                         <option value="=">=</option>
                        </Field>
                       
                      </div>
                      <div className="col">
                        
                        <Field
                          name={`variables.${index}.times`}
                          placeholder="0"
                          type="number"
                        />
                        <label>  times on </label>
                        
                      </div>
                      <div className="col">
                      <label>variable  </label>
                        <Field
                          name={`variables.${index}.variable`}
                          placeholder=""
                          type="text"
                        />
                                           
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
              
              </div>
            )}
          </FieldArray>
          <FieldArray name="pages">
            {({ insert, remove, push }) => (
              <div>
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push({ times: 0 , op: '', page: '' })}
                > Add User filters             
                </button>
                {values.pages.length > 0 &&
                  values.pages.map((page, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <label>Filter on users that clicked  </label>
                        <Field component="select"
                          name={`pages.${index}.op`}
                          placeholder=">,< or ="
                       >
                         <option value=">" >&gt;</option>
                         <option value="<">&lt;</option>
                         <option value="=">=</option>
                        </Field>
                       
                      </div>
                      <div className="col">
                        
                        <Field
                          name={`pages.${index}.times`}
                          placeholder="0"
                          type="number"
                        />
                        <label>  times on</label>
                        
                      </div>
                      <div className="col">
                      <label>page  </label>
                        <Field
                          name={`pages.${index}.page`}
                          placeholder=""
                          type="text"
                        />
                                           
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                
              </div>
            )}
          </FieldArray>
          <FieldArray name="ipdetails">
            {({ insert, remove, push }) => (
              <div>
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push({  key: '', value: '' })}
                > Add location filters            
                </button>
                {values.ipdetails.length > 0 &&
                  values.ipdetails.map((ipdetail, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <label>Filter on users where  </label>
                        <Field component="select"
                          name={`ipdetails.${index}.key`}
                          placeholder=""
                        >
                         <option value="city">City</option>
                         <option value="postcode">Post Code</option>
                         <option value="name">Name</option>
                         <option value="countryCode">2 Letter Country Code</option>
                        </Field>
                      </div>
                      <div className="col">
                      <label>equals  </label>
                        <Field
                          name={`ipdetails.${index}.value`}
                          placeholder=""
                          type="text"
                        />
                                           
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                
              </div>
            )}
          </FieldArray>
          <FieldArray name="sbicode">
            {({ insert, remove, push }) => (
              <div>
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push({  op: '', value: '' })}
                > Add SBI Filters           
                </button>
                {values.sbicode.length > 0 &&
                  (
                    <div className="row" key={0}>
                     <div className="col">
                      <label>SBI  Code </label>
                        <Field component="select"
                          name={`sbicode.${0}.op`}
                          placeholder=">,< or ="
                       >
                        <option value="" selected disabled hidden>Choose here</option>
                         <option value="!=" >not</option>
                         <option value="=">=</option>
                        </Field>
                      </div>
                      <div>
                      <Field
                          name={`sbicode.${0}.value`}
                          placeholder="0"
                          type="number"
                        />
                      </div>
                     
                      <div className="col">
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(0)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  )}
                
              </div>
            )}
          </FieldArray>
          <FieldArray name="impactScore">
            {({ insert, remove, push }) => (
                
              <div>
                <button
                  type="button"
                  className="secondary"
                  onClick={() => push({  op: '', value: '' })}
                > Add impact score  filters            
                </button>
                {values.impactScore.length > 0 &&
                   (
                    <div className="row" key={0}>
                      <div className="col">
                        <label>Filter on users where impact score  </label>
                        <Field component="select"
                          name={`impactScore.${0}.op`}
                          placeholder=">,< or ="
                       >
                         <option value="!=" >not</option>
                         <option value=">">&gt;</option>
                         <option value="<">&lt;</option>
                         <option value="=">=</option>
                        </Field>
                      </div>
                      <div className="col">
                      <label>equals  </label>
                        <Field
                          name={`impactScore.${0}.value`}
                          placeholder="0"
                          type="number"
                        />
                                           
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => remove(0)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  )}
                
              </div>
            )}
          </FieldArray>
          <button type="submit">Submit</button>
        </Form>
       
      )}
    </Formik>
  </div>
  </SearchForm>
  <TagGeneratorResults data= {this.state.results}></TagGeneratorResults>
  </div>
        )
                  
                  }
                }
export default withAuthStatus(
    apiConsumer({
        customer: props => getCustomerDetail('me'),
    })(SearchOverview),
)