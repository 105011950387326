import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerArray } from '../../data/actions.interface'
import APageRow from '../../components/atoms/APageRow'
import { IConsumer } from '../../data/categories/category.interface'
import { getConsumers } from '../../data/categories/category.actions'
import OTable, { ITableColumn } from '../../components/organisms/OTable'
import { formatDate } from '../../utils/format'
import { sortTableOnDate, sortTableOnStringValue, sortTableOnValue } from '../../utils/sort'
import AButton from '../../components/atoms/AButton'

interface IConsumerOverview extends IApiConsumer {
    consumers: IApiConsumerArray<IConsumer>
}

const consumerColumns: Array<ITableColumn<IConsumer>> = [
    {
        key: 'uuid',
        title: 'User',
        sort: sortTableOnStringValue('uuid'),
    },
    {
        key: 'triggerCount',
        title: 'Traffic',
        sort: sortTableOnValue('triggerCount'),
    },
    {
        key: 'value',
        title: 'Impact',
        sort: sortTableOnValue('value'),
    },
    {
        key: 'firstActivity',
        title: 'Active since',
        render: row => formatDate(row.firstActivity),
        sort: sortTableOnDate('firstActivity'),
    },
    {
        key: 'lastActivity',
        title: 'Latest activity',
        render: row => formatDate(row.lastActivity),
        sort: sortTableOnDate('lastActivity'),
    },
    {
        key: 'view',
        render: row => (
            <AButton outline color="primary" to={`/customer/consumer/${row.uuid}`}>
                View
            </AButton>
        ),
    },
]

class ConsumerOverview extends React.Component<IConsumerOverview> {
    render() {
        const { consumers } = this.props

        return (
            <APageRow title="Users">
                <OTable rowKey={'uuid'} data={consumers.response} columns={consumerColumns} />
            </APageRow>
        )
    }
}

export default apiConsumer({
    consumers: props => getConsumers(),
})(ConsumerOverview)
