import moment from 'moment'

export const formatDateTime = (date: Date): string => moment(date).format('D-M-Y HH:mm')

export const formatDate = (date: Date): string => moment(date).format('D-M-Y')

export const formatNumber = (value: number | string, decimals: number = 0): string => {
    const val = Number(value)
    return isNaN(val) || !isFinite(val) ? '-' : val.toFixed(decimals)
}
