import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import styled from '../../styledComponents'
import ALink from '../../components/atoms/ALink'
import { IWithAuthStatus, withAuthStatus } from '../../data/ApiProvider'
import AIcon from '../../components/atoms/AIcon'

interface ILoginButtonProps {
    className?: string
}

const StyledLoginButton = styled.span`
    display: flex;
    align-items: center;
    min-width: 120px;

    a {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`

const LoginButton: React.FunctionComponent<ILoginButtonProps & IWithAuthStatus> = props => {
    return (
        <StyledLoginButton className={props.className}>
            {props.auth.loggedIn ? (
                <ALink to="/login" onClick={props.auth.logout} inverted>
                    <FormattedMessage id="common.logout" defaultMessage="Log out" />
                </ALink>
            ) : (
                <ALink to="/login" inverted>
                    <FormattedMessage id="common.login" defaultMessage="Log in" />
                    <AIcon type="login" style={{ marginLeft: 16 }} size="lg" />
                </ALink>
            )}
        </StyledLoginButton>
    )
}

export default withAuthStatus(LoginButton)
