import * as React from 'react'
import APageHeader from '../../components/atoms/APageHeader'
import { RouteConstants } from '../../containers/routes/route.constants'
import CreateCategory from '../../containers/category/CreateCategory'
import { RouteComponentProps } from 'react-router'
import { AHeading, ACol, ARow } from '../../components/atoms'
import styled from '../../styledComponents'

const CreateCategoryBox = styled.div`
    background-color: #fff;
    padding: 25px 15px;
    border-radius: 6px;
    margin: 0 auto;
`

class PCreateCategory extends React.Component<RouteComponentProps> {
    /**
     * <APageHeader back={RouteConstants.CUSTOMER_DEFAULT}>
                Create new target audience
            </APageHeader>
     */

    render() {
        return (
            <ARow>
                <CreateCategoryBox>
                    <AHeading type="h4">Create new target audience</AHeading>
                    <CreateCategory
                        onSuccess={() => this.props.history.push(RouteConstants.CUSTOMER_DEFAULT)}
                    />
                </CreateCategoryBox>
            </ARow>
        )
    }
}

export default PCreateCategory
