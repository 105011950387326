import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { IApiConsumerEntity } from '../../data/actions.interface'
import { ICategory } from '../../data/categories/category.interface'
import { getCategory } from '../../data/categories/category.actions'
import APageHeader from '../../components/atoms/APageHeader'
import MDescriptiveList from '../../components/molecules/MDescriptiveList'
import { formatDate } from '../../utils/format'
import ARow from '../../components/atoms/ARow'
import ACol from '../../components/atoms/ACol'
import AButton from '../../components/atoms/AButton'
import styled from '../../styledComponents'
import { AHeading } from '../../components/atoms'

interface ICategoryDetail extends IApiConsumer {
    id: string
    category: IApiConsumerEntity<ICategory>
    back?: string
    onDelete?: string
    title?: string
}

const TargetAudienceDetails = styled.div`
    width: 100%;
    background: #fff;
    margin: 0 15px;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }

    & > div.row {
        padding: 0 25px 15px;
    }

    & > div.row div.row {
        padding: 10px 15px;
        border-bottom: solid 1px #f4f4f4;
    }

    & .desc {
        color: #b9b9b9;
    }

    & .value {
        color: #5a5a5a;
    }
`

class CategoryDetail extends React.Component<ICategoryDetail> {
    componentDidMount() {
        this.props.trigger('view_target_audience')
    }

    render() {
        const { title, back, category, onDelete } = this.props

        return (
            <React.Fragment>
                <APageHeader
                    style={{ background: 'none' }}
                    back={back}
                    buttons={[
                        onDelete && (
                            <AButton outline color="error" to={onDelete}>
                                Delete target audience
                            </AButton>
                        ),
                    ]}
                >
                    {title || 'Target audience:'} {category.response.name}
                </APageHeader>
                <ARow>
                    <TargetAudienceDetails>
                        <AHeading type="h6">Target audience details</AHeading>
                        <ARow>
                            <ACol md={6}>
                                <ARow>
                                    <ACol md={6}>
                                        <span className="desc">Target audience ID</span>
                                    </ACol>
                                    <ACol md={6}>
                                        <span className="value">
                                            {category.response.identifier}
                                        </span>
                                    </ACol>
                                </ARow>
                                <ARow>
                                    <ACol md={6}>
                                        <span className="desc">Created at</span>
                                    </ACol>
                                    <ACol md={6}>
                                        <span className="value">
                                            {formatDate(category.response.createdAt)}
                                        </span>
                                    </ACol>
                                </ARow>
                            </ACol>
                            <ACol md={6}>
                                <ARow>
                                    <ACol md={6}>
                                        <span className="desc">No. of variables</span>
                                    </ACol>
                                    <ACol md={6}>
                                        <span className="value">
                                            {category.response.variableCount}
                                        </span>
                                    </ACol>
                                </ARow>
                                <ARow>
                                    <ACol md={6}>
                                        <span className="desc">No. of triggers</span>
                                    </ACol>
                                    <ACol md={6}>
                                        <span className="value">
                                            {category.response.triggerCount}
                                        </span>
                                    </ACol>
                                </ARow>
                            </ACol>
                        </ARow>
                    </TargetAudienceDetails>
                </ARow>
            </React.Fragment>
        )
    }
}

export default apiConsumer({
    category: props => getCategory(props.id),
})(CategoryDetail)
