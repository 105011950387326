import * as React from 'react'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { ICreateVariableDto } from '../../data/categories/category.dto'
import { createVariable, getCategories, getVariables } from '../../data/categories/category.actions'
import MForm from '../../components/molecules/MForm'
import AField, { Error } from '../../components/atoms/AField'
import { FormattedMessage } from 'react-intl'
import AText from '../../components/atoms/AText'
import AButton from '../../components/atoms/AButton'
import { ICategory } from '../../data/categories/category.interface'
import { IApiConsumerArray } from '../../data/actions.interface'
import AFormGroupHeader from '../../components/atoms/AFormGroupHeader'
import CreateCategory from './CreateCategory'
import styled from '../../styledComponents'
import { ACol, ARow } from '../../components/atoms'

interface ICreateVariable extends IApiConsumer {
    id?: string
    onSuccess: (res: any) => void
    categories: IApiConsumerArray<ICategory>
}

interface ICreateVariableState {
    newAudience: boolean
}

const CreateVariableForm = styled.div`
    background: #ffffff;
    border-radius: 6px;

    & h6 {
        padding: 15px 25px;
        border-bottom: solid 1px #f4f4f4;
    }
    & form {
        padding: 0 25px 15px;
    }
`

class CreateVariable extends React.Component<ICreateVariable, ICreateVariableState> {
    state = {
        newAudience: false,
    }

    handleSubmit = ({ tagString, ...values }: ICreateVariableDto) => {
        const dto = values
        if (tagString && tagString.length > 0) {
            dto.tags = tagString
                .split(',')
                .map(keyValue => keyValue.split(':'))
                .reduce((agg, [key, value]) => ({ ...agg, [key]: value }), {})
        }

        return this.props.dispatch(createVariable(dto)).then(res => {
            this.props.trigger('create_new_variable')
            return this.props.onSuccess(res)
        })
    }

    toggleNewAudience = (enable?: boolean) => {
        if (this.canSubmit()) {
            this.setState(prevState => ({
                newAudience: typeof enable === 'undefined' ? !prevState.newAudience : enable,
            }))
        }
    }

    showNewAudience = () => {
        return !this.canSubmit() || this.state.newAudience
    }

    newCategoryButton = () => {
        if (this.props.categories.response.length === 0) {
            return null
        }

        return (
            <AButton outline color="primary" onClick={() => this.toggleNewAudience()}>
                {this.state.newAudience ? 'Cancel' : 'Add new target audience'}
            </AButton>
        )
    }

    canSubmit = () => {
        const { categories } = this.props
        return categories.status === 'success' && categories.response.length > 0
    }

    render() {
        const { categories } = this.props

        return (
            <ARow>
                <ACol md={3} />
                <ACol md={6}>
                    <CreateVariableForm>
                        <MForm key="create-variable" onSubmit={this.handleSubmit}>
                            <AFormGroupHeader>Variable information</AFormGroupHeader>
                            <AField
                                name="name"
                                type="text"
                                placeholder="Variable name"
                                label={
                                    <FormattedMessage
                                        id="variable.name"
                                        defaultMessage="* Variable name"
                                    />
                                }
                            />
                            <AText margined small>
                                * Variable name can only contain lowercase characters and '_'.
                            </AText>
                            <AField
                                name="tagString"
                                type="text"
                                placeholder="Tags"
                                label={
                                    <FormattedMessage id="variable.name" defaultMessage="* Tags" />
                                }
                            />
                            <AText margined small>
                                * Add tags in the following format:
                            </AText>
                            <AText emph margined small>
                                tagKey1:tagValue1,tagKey2:tagValue2
                            </AText>
                            {categories.response.map(category => (
                                <AField
                                    key={category.id}
                                    name={`categories.${category.identifier}`}
                                    type="number"
                                    placeholder={`Weight for: '${category.name}'`}
                                    label={`Weight for: '${category.name}'`}
                                />
                            ))}
                            <Error name="categories" component="div" />
                            <AButton
                                disabled={!this.canSubmit()}
                                type="submit"
                                block
                                color="primary"
                                hidden={this.showNewAudience()}
                            >
                                {this.canSubmit()
                                    ? 'Create new variable'
                                    : 'Please select or add at least 1 target audience'}
                            </AButton>
                        </MForm>
                        {this.showNewAudience() && (
                            <React.Fragment>
                                <AFormGroupHeader>Create new target audience</AFormGroupHeader>
                                <CreateCategory
                                    centered={false}
                                    onSuccess={() => this.toggleNewAudience(false)}
                                />
                            </React.Fragment>
                        )}
                    </CreateVariableForm>
                </ACol>
            </ARow>
        )
    }
}

export default apiConsumer({
    variables: props => getVariables(props.id),
    categories: props => getCategories(),
})(CreateVariable)
