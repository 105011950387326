import * as React from 'react'
import TriggerTest from '../../test/TriggerTest'
import AHeading from '../../components/atoms/AHeading'

interface IPTriggerTest {}

class PTriggerTest extends React.Component<IPTriggerTest> {
    render() {
        const {} = this.props

        return (
            <React.Fragment>
                <AHeading type="h4">Developer</AHeading>
                <TriggerTest />
            </React.Fragment>
        )
    }
}

export default PTriggerTest
