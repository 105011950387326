import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import styled from '../styledComponents'
import { ARow, ACol, AButton, AHeading, AContainer } from '../components/atoms/index'

export const Landing = styled(AContainer)`
    padding: 7rem 0;
    top: 0;
    background-color: ${props => props.theme.colorBackground};
    &.main {
        padding: 3rem 0 0;
    }
    &.defaultBg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 100%;
    }
`

export const LandingRow = styled(ARow)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;
`

export const LandingTitleContent = styled('p')`
    margin: 2rem 0;
    color: ${props => props.theme.colorActiveSecondary};
    line-height: 2.2rem;
    font-size: 1.2rem;
`

const ButtonRow = styled(ARow)`
    margin-left: 0.05rem;
`

const LandingHeaderRow = () => (
    <LandingRow>
        <ACol md={{ offset: 1, size: 6 }}>
            <AHeading type="h1" inverted>
                <FormattedMessage id="landing.title" defaultMessage="Welcome to Centreblock" />
            </AHeading>
            <LandingTitleContent>
                <FormattedMessage
                    id="landing.title.content"
                    defaultMessage="The world's best incentive & loyalty mechanism ever"
                />
            </LandingTitleContent>
            <LandingTitleContent>
                <FormattedMessage
                    id="landing.title.content2"
                    defaultMessage="CentreBlock is a revolutionary different approach to loyalty Programs, Conversion Rate Optimization and even Customer Journey Mapping for online and mobile platforms, initiatives and companies."
                />
            </LandingTitleContent>
            <LandingTitleContent>
                <FormattedMessage
                    id="landing.title.content3"
                    defaultMessage="CentreBlock leverages an ingenious algorithm (patents pending) of user variables providing the opportunity to monitor and optimize conversion rates, improve the customer journey and ultimately even allocate a portion of the property and shareholding to the users/customers."
                />
            </LandingTitleContent>
            <ButtonRow>
                <AButton to="login" color="primary" style={{ marginRight: '2rem', width: '40%' }}>
                    <FormattedMessage id="auth.login" defaultMessage="Click here to login" />
                </AButton>
            </ButtonRow>
        </ACol>
    </LandingRow>
)

const PLanding = () => {
    return (
        <Landing fluid className="main">
            <LandingHeaderRow />
        </Landing>
    )
}

export default PLanding
