import * as React from 'react'
import APageRow from '../../components/atoms/APageRow'
import MForm from '../../components/molecules/MForm'
import { createCategory, getCategories } from '../../data/categories/category.actions'
import { apiConsumer, IApiConsumer } from '../../data/ApiProvider'
import { ICreateCategoryDto } from '../../data/categories/category.dto'
import AField from '../../components/atoms/AField'
import AButton from '../../components/atoms/AButton'
import { FormattedMessage } from 'react-intl'
import AText from '../../components/atoms/AText'

interface ICreateCategory extends IApiConsumer {
    onSuccess: (res: any) => void
    centered?: boolean
}

class CreateCategory extends React.Component<ICreateCategory> {
    handleSubmit = (values: ICreateCategoryDto) => {
        return this.props.dispatch(createCategory(values)).then(res => {
            this.props.trigger('create_new_target_audience')
            return this.props.onSuccess(res)
        })
    }

    render() {
        const { centered } = this.props

        return (
            <APageRow centered={centered}>
                <MForm key="create-category" onSubmit={this.handleSubmit}>
                    <AField
                        name="name"
                        type="text"
                        placeholder="Target audience name"
                        label={
                            <FormattedMessage
                                id="category.name"
                                defaultMessage="Target audience name"
                            />
                        }
                    />
                    <AField
                        name="identifier"
                        type="text"
                        placeholder="Target audience identifier"
                        label={
                            <FormattedMessage
                                id="customer.name"
                                defaultMessage="*Target audience identifier"
                            />
                        }
                    />
                    <AText margined small>
                        * Identifier can only contain lowercase characters and '_'.
                    </AText>
                    <AButton type="submit" block color="primary">
                        Create new target audience
                    </AButton>
                </MForm>
            </APageRow>
        )
    }
}

export default apiConsumer({
    categories: props => getCategories(),
})(CreateCategory)
